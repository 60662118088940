import React from 'react'
import styled from '@emotion/styled'

import { color } from '../styles/colors'
import { size } from '../styles/sizes'

export const ToggleSwitchWrapper = styled.label`
  /* The switch - the box around the slider */
  position: relative;
  display: inline-block;
  width: ${size(5)};
  height: ${size(3)};

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${color.xxLightGrey};
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    top: 2px;
    background-color: white;
    -webkit-transition: 0.2s;
    transition: all 0.2s ease-out;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  }

  input:checked + .slider {
    background-color: ${color.green};
  }

  input:focus + .slider,
  input:hover + .slider {
    box-shadow: ${color.white} 0px 0px 0px 2px, ${color.black} 0px 0px 0px 4px;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(${size(2)});
    -ms-transform: translateX(${size(2)});
    transform: translateX(${size(2)});
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`

export interface ToggleSwitchOwnProps {
  size?: string
  label?: React.ReactNode
  trailingAction?: boolean
  checked?: boolean
  value?: string | number
}

interface ToggleSwitchProps
  extends ToggleSwitchOwnProps,
    Omit<
      React.HTMLAttributes<HTMLInputElement>,
      'label' | 'as' | 'size' | 'type' | 'value'
    > {}

const ToggleSwitch = ({
  size,
  label,
  trailingAction,
  onChange,
  checked,
  ...rest
}: ToggleSwitchProps) => {
  return (
    <ToggleSwitchWrapper>
      <input
        type="checkbox"
        onClick={(e) => e.stopPropagation()}
        checked={checked}
        onChange={onChange}
        {...rest}
      />
      <span className="slider round" />
    </ToggleSwitchWrapper>
  )
}

export default ToggleSwitch
