import { TypedDocumentNode, useMutation, useQuery } from '@apollo/client'
import {
  ErrorReporting,
  getErrors,
  PRIVACY_POLICY_LINE,
  useAuth,
  useFlowFormStep,
} from '@propps/client'
import { Input, ListItem, ListWrapper } from '@propps/ui'
import gql from 'graphql-tag'
import isMobile from 'is-mobile'
import React, { Fragment } from 'react'
import * as Yup from 'yup'

import { BuyerAuthFlowValues } from '../buyer-auth-flow-values'
import { SubmissionError } from '../submission-error'
import {
  CreateBuyerMutation,
  CreateBuyerMutationVariables,
} from './__generated__/CreateBuyerMutation'
import {
  GetPrivacyPolicyQuery,
  GetPrivacyPolicyQueryVariables,
} from './__generated__/GetPrivacyPolicyQuery'

const isMobileDevice = isMobile()

export function BuyerRegistrationStep({
  id,
  isNewBuyer,
}: {
  id: string
  isNewBuyer: React.MutableRefObject<boolean>
}) {
  const auth = useAuth()
  const [createBuyer] = useMutation(CREATE_BUYER_MUTATION)
  const { data } = useQuery(GET_PRIVACY_POLICY_QUERY, {
    variables: { privacyPolicyLineName: PRIVACY_POLICY_LINE },
  })

  const privacyPolicyUrl = data?.privacyPolicy
    ? '/terms/' +
      data.privacyPolicy.name +
      '/' +
      data.privacyPolicy.currentRevision.id
    : '#'

  const { formik } = useFlowFormStep<BuyerAuthFlowValues>({
    validationSchema,
    id,
    onBack: (helpers) => {
      formik.setFieldValue('buyer', {
        firstName: '',
        email: '',
      })

      helpers.preventTransition()
      helpers.goToStep(['auth-phone'])
    },
    onSubmit: async (values, helpers) => {
      const user = auth.fauth.currentUser

      if (!user) {
        throw new Error(
          'Invariant violation. User must be logged in to submit this step.'
        )
      }

      try {
        const { data } = await createBuyer({
          variables: {
            input: {
              uid: user.uid,
              firstName: values.buyer.firstName,
              lastName: values.buyer.lastName,
              phone: values.phone,
              email: values.buyer.email,
            },
          },
        })

        await auth.signInWithToken(data!.result.token)

        isNewBuyer.current = true
      } catch (err) {
        helpers.preventTransition()

        switch (err.code) {
          case 'auth/invalid-email':
            helpers.setFieldError(
              'buyer.email',
              'This email address appears to be invalid.'
            )
            break
          case 'auth/email-already-in-use':
            helpers.setFieldError(
              'buyer.email',
              'This email address is already in use by another account.'
            )
            break
          case 'auth/requires-recent-login':
            // the user must be reauthenticated before performing this operation.
            helpers.setSubmissionError(
              'There was a problem creating your account. Please refresh the page and try again.'
            )
            break
          default:
            ErrorReporting.report(err)

            helpers.setSubmissionError(
              'We were unable to create your account. Please contact hey@propps.com for support.' +
                (err.code ? ' Error code: ' + err.code : '')
            )
        }
      }
    },
  })

  return (
    <Fragment>
      <h2>
        Welcome
        <span className="light">It's nice to meet you</span>
      </h2>
      <ListWrapper>
        <ListItem>
          <label>First name</label>
          <Input
            autoFocus={!isMobileDevice}
            type="text"
            placeholder="..."
            {...formik.getFieldProps(`buyer.firstName`)}
            errors={getErrors(formik, `buyer.firstName`)}
            autoComplete="given-name"
          />
        </ListItem>
        <ListItem>
          <label>Last name</label>
          <Input
            type="text"
            placeholder="..."
            {...formik.getFieldProps(`buyer.lastName`)}
            errors={getErrors(formik, 'buyer.lastName')}
            autoComplete="family-name"
          />
        </ListItem>
        <ListItem>
          <label>Email address</label>
          <Input
            type="text"
            placeholder="..."
            {...formik.getFieldProps(`buyer.email`)}
            errors={getErrors(formik, `buyer.email`)}
            autoComplete="email"
            inputMode="email"
          />
        </ListItem>
        <ListItem>
          <label>Phone number</label>
          <Input
            type="text"
            placeholder="..."
            disabled
            {...formik.getFieldProps(`phone`)}
            errors={getErrors(formik, `phone`)}
            autoComplete="tel"
            inputMode="tel"
          />
        </ListItem>
      </ListWrapper>
      <p className="grey">
        We'll make an account for you that lets you save and retrieve your offer
        and access other neat features. Your information is treated with respect
        and subject to our{' '}
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href={privacyPolicyUrl} target="_blank">
          Privacy Policy
        </a>
        .
      </p>
      <p className="grey">
        Buying with others? We'll let you add them a little later.
      </p>
      <SubmissionError />
    </Fragment>
  )
}

const validationSchema = Yup.object({
  buyer: Yup.object({
    firstName: Yup.string().required().label('First name'),
    lastName: Yup.string().required().label('Last name'),
    email: Yup.string().trim().email().required().label('Email'),
  }),
})

const CREATE_BUYER_MUTATION: TypedDocumentNode<
  CreateBuyerMutation,
  CreateBuyerMutationVariables
> = gql`
  mutation CreateBuyerMutation($input: CreateBuyerInput!) {
    result: createBuyer(input: $input) {
      buyer {
        id
        user {
          buyer {
            id
          }
        }
      }
      token
    }
  }
`

const GET_PRIVACY_POLICY_QUERY: TypedDocumentNode<
  GetPrivacyPolicyQuery,
  GetPrivacyPolicyQueryVariables
> = gql`
  query GetPrivacyPolicyQuery($privacyPolicyLineName: String!) {
    privacyPolicy: termsLine(name: $privacyPolicyLineName) {
      name
      currentRevision {
        id
      }
    }
  }
`
