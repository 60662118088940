import React, { useContext } from 'react'
import styled from '@emotion/styled'
import Logo from './../elements/Logo'

import Icon, { ArrowLeft, Menu3 } from './../elements/icons'

import { color } from '../styles/colors'
import { zIndex } from '../styles/helpers'
import { screen } from '../styles/breakpoints'
import { size } from '../styles/sizes'
import { fontSize } from '../styles/typography'

import { StackContext } from '../layouts/Stacks'

const LogoWrapper = styled.div`
  position: absolute;
  display: flex;
  width: ${size(16)};
  height: ${size(6)};
  z-index: ${zIndex.stackChat - 2};

  @media ${screen.m.down} {
    margin-top: 12px;
    margin-left: calc(50vw - ${size(8)});
  }

  @media ${screen.m.only} {
    margin-top: ${size(4)};
  }

  @media ${screen.l.up} {
    position: fixed;
    margin-top: ${size(4)};
    margin-left: ${size(6)};
  }
`

const Links = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  right: 0;
  top: 0;
  z-index: ${zIndex.stackChat - 2};
  font-weight: bold;
  width: 100%;
  height: ${size(9)};

  @media ${screen.xs} {
    padding-left: ${size(2)};
    padding-right: ${size(2)};
  }

  @media ${screen.s.up} {
    padding-left: ${size(2)};
    padding-right: ${size(2)};
  }

  @media ${screen.m.up} {
    height: ${size(14)};
    padding-left: ${size(4)};
    padding-right: ${size(4)};
  }

  @media ${screen.l.up} {
    width: calc(100% - 432px);
    padding-left: ${size(12)};
    padding-right: ${size(6)};
  }

  @media ${screen.xl.up} {
    width: 65%;
    padding-left: ${size(16)};
  }

  label {
    display: block;
    height: ${size(3)};
    overflow-y: hidden;
    line-height: 26px;
    text-transform: uppercase;
    font-size: ${fontSize.xs};
    letter-spacing: 0.1rem;
    font-weight: bold;
    color: ${color.black};

    @media ${screen.xs} {
      display: none;
    }
  }

  img {
    width: ${size(6)};
    height: ${size(6)};
    border: 12px solid transparent;
    border-radius: ${size(1)};
    transition: 0.2s all ease-out;

    &:hover {
      background: ${color.black10};
    }
  }
`

const Left = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 12px;

    @media ${screen.l.up} {
      position: fixed;
      margin-right: ${size(4)};
      margin-left: -68px;
      margin-top: 0;
    }
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-left: 12px;
  }

  @media ${screen.l.up} {
    label {
      margin-right: ${size(5)};
    }

    img {
      position: fixed;
      right: ${size(4)};
      top: ${size(4)};
    }
  }
`

const Header = ({ ...props }) => {
  const stacks = useContext(StackContext)

  return (
    <header {...props}>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Links>
        <Left>
          <Icon svg={ArrowLeft} />
          <label>Back</label>
        </Left>
        <Right onClick={() => stacks.toggleNav()}>
          <label>Menu</label>
          <Icon svg={Menu3} />
        </Right>
      </Links>
    </header>
  )
}

export default Header
