/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  getErrors,
  useFlowFormStep,
  useSMSVerificationFlow,
} from '@propps/client'
import { Input, ListItem, ListWrapper } from '@propps/ui'
import isMobile from 'is-mobile'
import React, { Fragment } from 'react'
import * as Yup from 'yup'

import { BuyerAuthFlowValues } from '../buyer-auth-flow-values'
import { SubmissionError } from '../submission-error'

const isMobileDevice = isMobile()

export function AuthSMSValidateStep({
  id,
  smsVerification,
  title,
  isUserRegistered,
}: {
  id: string
  smsVerification: ReturnType<typeof useSMSVerificationFlow>
  title: string
  isUserRegistered: () => Promise<boolean>
}) {
  const { formik, setSubmissionError } = useFlowFormStep<BuyerAuthFlowValues>({
    id,
    validationSchema,
    onBack: (helpers) => {
      helpers.setFieldValue('smsVerificationCode', '', false)
      smsVerification.reset()
    },
    onSubmit: async (values, helpers) => {
      try {
        await smsVerification.complete(values.smsVerificationCode)

        const shouldRegister = !(await isUserRegistered())

        if (!shouldRegister) {
          helpers.preventTransition()
          await helpers.submitFinal()
        }
      } catch (err) {
        helpers.preventTransition()

        switch (err.code) {
          case 'auth/invalid-verification-id':
            helpers.setSubmissionError(
              'The verification code is invalid or expired. Please press resend to get a new verification code or contact hey@propps.com for support.'
            )
            break
          case 'auth/invalid-verification-code':
            helpers.setSubmissionError(
              'The verification code appears to be incorrect. Try retyping the code or press resend to get a new one. You can contact hey@propps.com for support.'
            )
            break
          default:
            console.error(err)
            helpers.setSubmissionError(
              'We were unable to complete phone verification. Try again later or contact hey@propps.com for support.' +
                (err.code ? ' Error code: ' + err.code : '')
            )
        }
      }
    },
  })

  return (
    <Fragment>
      <h2>
        {title}
        <span className="light">{formik.values.phone || '&nbsp;'}</span>
      </h2>
      <ListWrapper>
        <ListItem>
          <label>Verification code</label>
          <Input
            autoFocus={!isMobileDevice}
            type="text"
            placeholder="..."
            {...formik.getFieldProps(`smsVerificationCode`)}
            errors={getErrors(formik, `smsVerificationCode`)}
            inputMode="numeric"
            {...{ autoComplete: 'one-time-code' }}
          />
        </ListItem>
      </ListWrapper>
      <p className="grey">
        We’ve sent a verification code to make sure we don’t send your
        information to the wrong person. If you haven’t received anything, we
        can&nbsp;
        <a
          href="#"
          onClick={() => {
            setSubmissionError(null)
            smsVerification.resend()
          }}
        >
          resend the code
        </a>
        .
      </p>
      <SubmissionError />
    </Fragment>
  )
}

const validationSchema = Yup.object({
  smsVerificationCode: Yup.string()
    .required('Please enter the verification code.')
    .label('Verification code'),
}).required()
