import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { color } from '../styles/colors'
import { zIndex } from '../styles/helpers'
import { size } from '../styles/sizes'

import bgGrid from '../images/bg--grid.png'

const StackAppWrapper = styled.div<{ variant: StackAppProps['variant'] }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: ${zIndex.stackApp};

  ${({ variant }) => css`
    ${variant === 'app' &&
    css`
      background-color: ${color.offWhite};
    `}

    ${variant === 'admin' &&
    css`
      background-color: ${color.offWhite};
      background-image: url(${bgGrid});
      background-size: ${size(3)};
      background-repeat: repeat-x;
      background-attachment: fixed;
    `}

    ${variant === 'frames' &&
    css`
      background-color: ${color.white};
    `}
  `}
`

export interface StackAppProps {
  variant: 'app' | 'admin' | 'frames'
  children: React.ReactNode
}

const StackApp = ({ variant, children, ...props }: StackAppProps) => (
  <StackAppWrapper variant={variant} {...props}>
    {children}
  </StackAppWrapper>
)

export default StackApp
