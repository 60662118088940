import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'

import Icon, {
  CircleEmpty,
  CircleRadio2,
  CircleTick,
  Menu3,
} from '../elements/icons'
import { color } from '../styles/colors'
import { size } from '../styles/sizes'
// import { useAnalytics } from './analytics'

const Dropdown = styled.div`
  position: relative;
`

const DropdownTrigger = styled.button<{ show?: boolean }>`
  padding: 10px;
  background: transparent;
  border: 0;
  border-radius: ${size(1)};
  cursor: pointer;

  ${(props) =>
    props.show &&
    css`
      background: rgba(240, 240, 240, 0.92);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    `}

  &:focus {
    outline: none;
    background: rgba(240, 240, 240, 0.92);
  }
`

const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  width: 256px;
  margin: 0;
  padding: 12px 0;
  background: rgba(240, 240, 240, 0.92);
  border-radius: ${size(1)} 0 ${size(1)} ${size(1)};
  backdrop-filter: saturate(180%) blur(20px);
  list-style: none;

  hr {
    display: block;
    height: 1px;
    width: 100%;
    background: ${color.xxxLightGrey};
    border: none;
    margin: 12px 0;
  }
`

const DropdownMenuButton = styled.button<{ disabled?: boolean }>`
  display: inline-flex;
  width: 100%;
  align-items: center;
  padding: 0 ${size(3)};
  height: 44px;
  background: transparent;
  border: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.05);
  }

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `}
`

const DropdownMenuIcon = styled.div`
  margin-right: ${size(1)};
`

export type DropdownMenuItemStatus = 'done' | 'active' | 'unvisited'

type DropdownMenuItemAction = {
  key?: string
  label: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  status?: DropdownMenuItemStatus
  hidden?: boolean
}

type DropdownMenuSeparator = {
  sep: true
}

export type DropdownMenuItem = DropdownMenuItemAction | DropdownMenuSeparator

const StackDropdown = ({ menu = [] }: { menu?: DropdownMenuItem[] }) => {
  // TODO: extract analytics
  // const analytics = useAnalytics()
  const [open, setOpen] = useState(false)

  // Close the menu when the user clicks outside.
  // Does not block the click that closes the menu.
  const menuRef = useRef<HTMLElement>()

  useEffect(() => {
    if (open) {
      const handler = (e: MouseEvent) => {
        if (!menuRef?.current?.contains(e.target as Node)) {
          setOpen(false)
        }
      }

      window.addEventListener('click', handler, false)
      return () => window.removeEventListener('click', handler)
    }
  }, [open])

  // useEffect(() => {
  //   if (open) {
  //     analytics.logAmplitudeEvent({
  //       name: 'open menu bar menu',
  //     })
  //   }
  // }, [analytics, open])

  return (
    <Dropdown>
      <DropdownTrigger
        show={open}
        onClick={() => {
          setOpen((value) => !value)
        }}
      >
        <Icon svg={Menu3} size={24} aria-label="Menu" />
      </DropdownTrigger>

      <DropdownMenu
        style={{ display: open ? 'block' : 'none' }}
        ref={menuRef as React.RefObject<HTMLUListElement>}
      >
        {menu.map((item, i) =>
          'sep' in item ? (
            <hr key={i} />
          ) : (
            !item.hidden && (
              <li key={item.key || i}>
                <DropdownMenuButton
                  disabled={item.disabled}
                  onClick={() => {
                    item.onClick && item.onClick()
                    setOpen(false)
                  }}
                >
                  {item.status && (
                    <DropdownMenuIcon>
                      {item.status === 'done' && (
                        <Icon svg={CircleTick} aria-label="Done" />
                      )}
                      {item.status === 'active' && (
                        <Icon svg={CircleRadio2} aria-label="Current Step:" />
                      )}
                      {item.status === 'unvisited' && (
                        <Icon svg={CircleEmpty} aria-hidden="true" />
                      )}
                    </DropdownMenuIcon>
                  )}

                  {item.label}
                </DropdownMenuButton>
              </li>
            )
          )
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

export default StackDropdown
