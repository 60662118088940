import mitt, { Emitter, Handler } from 'mitt'

export class FrameTransport {
  emitter: Emitter = mitt()
  targetHost: string | undefined

  constructor() {
    this.handleMessage = this.handleMessage.bind(this)
    this.send = this.send.bind(this)
  }

  setTargetHost(value: string) {
    this.targetHost = value
  }

  init(targetHost: string) {
    this.targetHost = targetHost
    window.addEventListener('message', this.handleMessage, false)
    this.send({ type: 'frame-transport-open' })
  }

  dispose() {
    window.removeEventListener('message', this.handleMessage)
  }

  send<T extends { type: string }>(message: T) {
    if (!this.targetHost)
      throw new Error(
        'You must call the init method before trying to send a message'
      )
    window.parent.postMessage(message, this.targetHost)
  }

  handleMessage(e: MessageEvent) {
    if (
      this.targetHost !== '*' &&
      (!this.targetHost || e.origin !== this.targetHost)
    )
      return

    this.emitter.emit('message', e.data)
    this.emitter.emit(e.data.type, e.data)
  }

  subscribe<T extends { type: U }, U extends string = string>(
    type: U,
    handler: (message: T) => void
  ) {
    this.emitter.on(type, handler as Handler<T>)
    return () => this.emitter.off(type, handler as Handler<T>)
  }
}
