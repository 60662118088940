import React from 'react'
import styled from '@emotion/styled'

import Icon, {
  ArrowUpRight,
  Cross,
  CircleCross,
  CircleInfo2,
  CircleTick,
  CircleWarning,
} from './../elements/icons'

import { color } from '../styles/colors'
import { size } from '../styles/sizes'
import { fontSize } from '../styles/typography'

const NotificationWrapper = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  margin-bottom: ${size(1)};
  background: ${color.white};
  border-radius: 12px;
  padding: ${size(2)};
  overflow: hidden;
  pointer-events: all;
  font-size: ${fontSize.s};
  box-shadow: 0 ${size(1)} ${size(2)} 0 rgba(0, 0, 0, 0.1);

  .message {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    text-align: left;
    margin-left: 12px;

    label {
      display: flex;
      margin-bottom: 2px;
      line-height: ${size(3)};
      color: ${color.black};
      font-weight: bold;
    }

    .description {
      line-height: 20px;
      color: ${color.grey};
      overflow: hidden;
      height: 44px;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -${size(1)} -${size(1)} 0 0;
    padding: 0;
    height: auto;
    font-size: ${fontSize.m};
    line-height: ${size(3)};
    color: $grey;
    list-style: none;
    flex-shrink: 0;

    li {
      display: flex;
      padding: ${size(1)};
      margin: 0;
      border-radius: ${size(1)};
      cursor: pointer;
      transition: 0.2s all ease-out;

      &:hover {
        background: ${color.black10};
      }

      &:nth-child(2) {
        margin-top: 4px;
      }
    }
  }
`

const NotificationProgress = styled.div`
  display: flex;
  position: relative;
  width: calc(100% - ${size(2)});
  height: 4px;
  border-radius: 4px;
  margin: 6px 0 10px;
  background: ${color.xxxLightGrey};

  &::after {
    display: flex;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    border-radius: 4px;
    height: 4px;
    background: ${color.black};
    animation: loading 6s ease-in-out infinite;

    @keyframes loading {
      0% {
        width: 0%;
        left: 0;
        right: auto;
      }
      50% {
        width: 100%;
        left: 0;
        right: auto;
      }
      51% {
        width: 100%;
        left: auto;
        right: 0;
      }
      100% {
        width: 0%;
        left: auto;
        right: 0;
      }
    }
  }
`

export const NotificationToast = ({ ...props }) => (
  <NotificationWrapper>
    {props.appearance === 'success' && (
      <Icon svg={CircleTick} fill={color.green} />
    )}
    {props.appearance === 'error' && (
      <Icon svg={CircleCross} fill={color.red} />
    )}
    {props.appearance === 'info' ||
      (!props.appearance && <Icon svg={CircleInfo2} fill={color.black} />)}
    {props.appearance === 'warning' ||
      (!props.appearance && <Icon svg={CircleWarning} fill={color.orange} />)}
    {props.children}
    <ul className="actions">
      <li>
        <Icon svg={Cross} size={24} onClick={props.onDismiss} />
      </li>
      {props.action && (
        <li>
          <Icon svg={ArrowUpRight} size={24} />
        </li>
      )}
    </ul>
  </NotificationWrapper>
)

export const Notification = ({ ...props }) => {
  return (
    <div className="message">
      <label>{props.label}</label>
      {props.progress && <NotificationProgress />}
      {props.description && (
        <div className="description">{props.description}</div>
      )}
      {props.children}
    </div>
  )
}
