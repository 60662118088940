/* Z-index values */
export const zIndex = {
  stackNotification: 7777,
  stackModal: 6666,
  stackDrawer: 5555,
  stackMenu: 4444,
  stackChat: 3333,
  stackApp: 2222,
  stackHero: 1111,
}
