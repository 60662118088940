import styled from '@emotion/styled'

import { screen } from '../styles/breakpoints'
import { color } from '../styles/colors'
import { fontSize } from '../styles/typography'

const ReadOnly = styled.div`
  padding: 10px 0 10px 12px;
  font-size: ${fontSize.m};
  min-height: 44px;
  border-left: 1px solid ${color.xxxLightGrey};

  @media ${screen.xs} {
    padding: 10px 0;
    border-left: none;
  }
`

export default ReadOnly
