import React from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { color } from '../styles/colors'
import { screen } from '../styles/breakpoints'
import { size } from '../styles/sizes'

export const ListWrapper = styled.ul<{ help?: boolean; inactive?: boolean }>`
  margin: 0;
  padding: 0;
  margin-bottom: ${size(3)};
  transition: 0.2s all ease-out;

  /* Use the 'inactive' prop to dim list items that aren't the focus when in a flow */
  opacity: ${(props) => (props.inactive ? '0.2' : '1')};
`

const ListItemWrapper = styled.li<{
  column?: boolean
  vcenter?: boolean
  lineHeight?: string
  clickable?: boolean
  row?: boolean
}>`
  display: flex;
  align-items: ${(props) => (props.vcenter ? 'center' : 'stretch')};
  justify-content: space-between;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  list-style: none;
  padding: 10px 0;
  line-height: ${(props) =>
    props.lineHeight ? props.lineHeight : `${size(3)}`};
  border-bottom: 1px solid ${color.xxxLightGrey};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  background: none;
  transition: all 0.2s ease-out;

  &:first-of-type {
    border-top: 1px solid hsla(0, 0%, 0%, 0.1);
    margin-top: -1px;
  }

  ${({ clickable }) =>
    clickable
      ? css`
          &:hover,
          &:active,
          &:focus {
            transform: translate3d(0, 0, 0);
            padding-left: 10px;
            background: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.03),
              rgba(0, 0, 0, 0)
            );
            text-decoration: underline;
          }

          &:active,
          &:focus {
            background: none;
            box-shadow: 0 0 0 2px ${color.white}, 0 0 0 3px ${color.black};
          }
        `
      : ''}

  @media ${screen.xs} {
    flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  }

  /* This is primarily for list-item input fields */
  > div:last-child:not(:first-child) {
    position: relative;
    width: 65%;
    margin: ${(props) => (props.column ? '4px 0 -10px' : '-10px 0')};

    @media ${screen.xs} {
      width: 100%;
      margin: 4px 0 -10px;
    }
  }
`

const ListItem = (props: React.ComponentProps<typeof ListItemWrapper>) => {
  return <ListItemWrapper {...props}>{props.children}</ListItemWrapper>
}

export default ListItem
