import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import Icon, { LargePlus, LargeUser } from '../elements/icons'
import { screen } from '../styles/breakpoints'
import color from '../styles/colors'
import { size } from '../styles/sizes'
import { fontSize } from '../styles/typography'

const UserCardWrapper = styled.div<{
  variant?: 'dashed'
  draft?: boolean
  clickable?: boolean
}>`
  padding: 20px;
  border-radius: ${size(1)};
  display: flex;
  position: relative;
  align-items: center;
  cursor: default;
  transition: 0.2s all ease-out;
  background-color: ${color.white};
  margin-bottom: ${size(1)};
  width: 100%;

  & > *:not(:last-child) {
    margin-right: ${size(2)};

    @media ${screen.xs} {
      margin-right: 12px;
    }
  }

  @media ${screen.xs} {
    padding: ${size(2)};
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: ${size(1)};
    box-shadow: 0 0 0 0 transparent, inset 0 0 0 1px ${color.xxxLightGrey};
    transition: 0.1s all ease-out;
  }

  ${({ variant }) =>
    variant === 'dashed' &&
    css`
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        border-radius: ${size(1)};
        border: 2px dashed ${color.white};
      }

      &:hover {
        &:after {
          border: none;
        }
      }
    `}

  ${({ clickable }) =>
    clickable
      ? css`
          transition: all 0.2s ease-out;

          &:hover {
            cursor: pointer;
            transform: translate3d(0, -2px, 0);
            box-shadow: 0 ${size(1)} ${size(4)} 0 rgba(0, 0, 0, 0.15);
            z-index: 1;

            &:before {
              box-shadow: 0 0 0 2px ${color.black}, inset 0 0 0 0 transparent;
            }
          }

          &:focus,
          &:active {
            outline: none;

            &:before {
              box-shadow: 0 0 0 2px ${color.black}, inset 0 0 0 0 transparent;
            }
          }
        `
      : ''}
`

const UserCardAvatar = styled.div`
  svg {
    width: ${size(6)};
    height: ${size(6)};
  }

  @media (max-width: 430px) {
    display: none;
  }
`

const UserCardDetails = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
`

const Header = styled.div`
  font-weight: bold;
  @media ${screen.xs} {
    font-size: ${fontSize.s};
  }
`

const Content = styled.div`
  margin-top: 4px;

  @media ${screen.xs} {
    font-size: ${fontSize.s};
  }
`

const TrailingItems = styled.div`
  flex-shrink: 0;
  @media ${screen.xs} {
    font-size: ${fontSize.s};
  }
`

export interface UserCardOwnProps {
  variant?: 'dashed'
  draft?: boolean
  svg?: React.ComponentType<React.SVGProps<SVGSVGElement>>
  primaryText?: React.ReactNode
  secondaryText?: React.ReactNode
  trailingItems?: React.ReactNode
  clickable?: boolean
}

export interface UserCardProps
  extends UserCardOwnProps,
    Omit<React.HTMLProps<HTMLDivElement>, 'as'> {}

export function UserCard({
  variant,
  draft,
  svg,
  primaryText,
  secondaryText,
  trailingItems,
  onClick,
  clickable,
  ...rest
}: UserCardProps) {
  clickable = typeof clickable === 'undefined' ? !!onClick : clickable

  return (
    <UserCardWrapper
      draft={draft}
      clickable={clickable}
      variant={variant}
      onClick={onClick}
      {...rest}
    >
      <UserCardAvatar>
        {!draft && !svg && <Icon svg={LargeUser} size={48} />}
        {!draft && svg && <Icon svg={svg} size={48} />}
        {draft && <Icon svg={LargePlus} size={48} />}
      </UserCardAvatar>
      <UserCardDetails>
        <Header>{primaryText}</Header>
        {secondaryText && (
          <Content style={{ color: color.black50, fontSize: '14px' }}>
            {secondaryText}
          </Content>
        )}
      </UserCardDetails>
      <TrailingItems>{trailingItems || null}</TrailingItems>
    </UserCardWrapper>
  )
}
