import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Icon, { CircleTick } from './../elements/icons'

import color from '../styles/colors'
import { screen } from '../styles/breakpoints'
import { size } from '../styles/sizes'
import { fontSize } from '../styles/typography'

const AppCardWrapper = styled.li<{ list?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: ${color.white};
  border: inset 1px solid black;
  border-radius: ${size(1)};
  margin-bottom: ${size(2)};
  cursor: pointer;
  transition: 0.3s all ease-out;

  @media ${screen.l.up} {
    width: calc(50% - ${size(1)});
  }

  ${({ list }) =>
    list &&
    css`
      flex-direction: row;
      width: 100%;
    `}

  .app-bar {
    display: flex;
    align-items: center;
    height: ${size(4)};
    padding: 0 10px;

    div {
      width: 12px;
      height: 12px;
      margin-right: 4px;
      border-radius: 50%;
      border: 1px solid ${color.xxxLightGrey};
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: ${size(1)};
    box-shadow: 0 0 0 0 transparent, inset 0 0 0 1px ${color.xxxLightGrey};
    transition: 0.1s all ease-out;
  }

  &:hover {
    transform: translate3d(0, -2px, 0);
    box-shadow: 0 ${size(1)} ${size(4)} 0 rgba(0, 0, 0, 0.15);
    z-index: 1;

    &:before {
      box-shadow: 0 0 0 2px ${color.black}, inset 0 0 0 0 transparent;
    }
  }
`

const Metadata = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${size(2)} ${size(2)} 12px;
  border-radius: 0 0 ${size(1)} ${size(1)};
  font-weight: bold;
  border-top: 1px solid ${color.xxxLightGrey};

  h2 {
    font-size: 18px;
    letter-spacing: -0.01rem;
    margin: 0 0 4px;
  }

  .small {
    color: ${color.grey};
    font-size: ${fontSize.s};
    font-weight: normal;
    margin: 0 0 ${size(2)};
    padding: 0;
  }

  .status {
    display: flex;
    align-items: center;
    line-height: ${size(3)};
    font-size: ${fontSize.s};
    margin-bottom: 4px;
    font-weight: normal;

    svg {
      margin-right: ${size(1)};
    }
  }
`

type AppCardProps = {
  id?: string
  children?: React.ReactNode
  image?: string
  list?: boolean
  onClick?: (arg?: unknown) => void
  status?: string
  title?: string
}

const AppCard = ({
  children,
  id,
  image,
  list,
  onClick,
  status,
  title,
  ...props
}: AppCardProps) => {
  return (
    <AppCardWrapper onClick={() => onClick && onClick()} list={list} {...props}>
      <div className="app-bar">
        <div />
        <div />
        <div />
      </div>
      <Metadata>
        {title && <h2>{title}</h2>}
        {id && <p className="small">{id}</p>}
        {status && (
          <div className="status">
            <Icon svg={CircleTick} fill={color.green} />
            {status}
          </div>
        )}
      </Metadata>
    </AppCardWrapper>
  )
}

export default AppCard
