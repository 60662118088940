import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

const padding = 16
const sizes = { xs: 384, sm: 480, md: 960 }

export type StackContainerSizes = keyof typeof sizes

type StackContainerProps = {
  size?: StackContainerSizes
  fullHeight?: boolean
}

const StackContainerStyled = styled.div<StackContainerProps>`
  ${({ size, fullHeight }) => css`
    margin-left: auto;
    margin-right: auto;
    padding-left: ${padding}px;
    padding-right: ${padding}px;
    width: 100%;
    position: relative;

    ${size &&
    css`
      max-width: ${sizes[size] + 2 * padding}px;
    `}

    ${fullHeight &&
    css`
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    `}
  `}
`

const StackContainer = ({
  size,
  fullHeight,
  children,
  ...props
}: StackContainerProps & { children: React.ReactNode }) => (
  <StackContainerStyled size={size} fullHeight={fullHeight} {...props}>
    {children}
  </StackContainerStyled>
)

export default StackContainer
