import styled from '@emotion/styled'
import React from 'react'
import { Radio as BaseRadio } from 'reakit'

import { color } from '../styles/colors'
import { size as sizeUtility } from '../styles/sizes'
import { fontSize } from '../styles/typography'

import RadioEmpty from '../elements/icons/svg/circle/empty.svg'
import RadioSelected from '../elements/icons/svg/circle/radio.svg'

const Label = styled.label<{ size?: string }>`
  padding: ${({ size }) => (size === 'large' ? `${sizeUtility(2)}` : '10px')};
  display: flex;
  width: 100%;
  color: ${color.black};
  cursor: pointer;
  font-size: ${fontSize.m};
  line-height: ${sizeUtility(3)};
  user-select: none;
  border: 1px solid ${color.xxxLightGrey};
  border-radius: ${sizeUtility(1)};
  transition: background 0.2s ease-out;

  &:hover {
    background: ${color.offWhite};
  }

  &:focus,
  &:active {
    border: 1px solid ${color.black};
    box-shadow: inset 0 0 0 1px ${color.black};
  }

  p {
    margin: ${sizeUtility(1)} 0 ${sizeUtility(2)};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RadioControl = styled(BaseRadio as any)`
  appearance: none;
  outline: none;
  cursor: pointer;
  width: ${sizeUtility(3)};
  height: ${sizeUtility(3)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  &::after {
    content: '';
    background-image: url(${RadioEmpty});
    background-size: 100%;
    color: white;
    font-size: 70%;
    width: ${sizeUtility(3)};
    height: ${sizeUtility(3)};
    display: block;
    border-radius: 50%;
  }

  &:focus::after {
    box-shadow: 0 0 0 2px ${color.white}, 0 0 0 4px ${color.black};
  }

  &:checked::after {
    background-image: url(${RadioSelected});
  }
`

export const RadioGroup = styled.div`
  display: block;
  margin: ${sizeUtility(2)} 0 ${sizeUtility(4)};

  label + label {
    margin-top: ${sizeUtility(1)};
  }
`

type RadioProps = {
  size?: string
  label?: React.ReactNode
  value?: string | number | undefined
} & Omit<
  React.PropsWithoutRef<JSX.IntrinsicElements['input']>,
  'label' | 'as' | 'size'
>

const Radio = ({ id, label, size, checked, ...props }: RadioProps) => {
  return (
    <Label htmlFor={id} size={size}>
      <RadioControl checked={checked} id={id} {...props} />
      <span>{label}</span>
    </Label>
  )
}

export default Radio
