/* eslint-disable jsx-a11y/anchor-is-valid */
import { downloadSVGZip } from '@propps/client'
import { ArrowInDown, Icon, QrCodePlaceHolder, size } from '@propps/ui'
import gql from 'graphql-tag'
import QRCode from 'qrcode.react'
import React, { useRef, useState } from 'react'
import { useAnalytics } from '../../analytics'
import { QRCodeStep_Listing } from './__generated__/QRCodeStep_Listing'

export function QrCodeStep({
  id,
  listing,
}: {
  id: string
  listing: QRCodeStep_Listing | null
}) {
  const analytics = useAnalytics()

  const qrCodeContainerElement = useRef<HTMLDivElement>(null)

  const handleDownload = () => {
    if (!(listing && qrCodeContainerElement.current?.querySelector('svg')))
      return

    downloadSVGZip(
      qrCodeContainerElement.current.querySelector('svg')!,
      `qrcode_${listing.id}`
    )

    analytics.logAmplitudeEvent({
      name: 'download qr code',
      listingId: listing!.id,
      appId: listing!.source.appId,
      foreignListingId: listing!.source.appId,
    })
  }

  // Hacky solution to wait for the transition completion. Otherwise seeing the flickering.
  const [imageLoaded, setImageLoaded] = useState(false)
  setTimeout(() => setImageLoaded(true), 800)
  return (
    <>
      <h2>Switch to your phone</h2>
      <p className="grey">
        Scan the QR code with your phone's camera app and you can continue
        making your offer on your phone.
      </p>
      <a
        style={{
          display: imageLoaded ? 'block' : 'none',
          position: 'fixed',
          top: `${size(3)}`,
          right: `${size(3)}`,
          zIndex: 9999,
          border: 'none',
        }}
        href="#"
        onClick={handleDownload}
      >
        <Icon svg={ArrowInDown} />
      </a>
      <QrCodePlaceHolder>
        <div ref={qrCodeContainerElement}>
          {listing && (
            <QRCode value={listing.url} renderAs={'svg'} bgColor={'#ffffff'} />
          )}
        </div>
      </QrCodePlaceHolder>
    </>
  )
}

QrCodeStep.fragments = {
  Listing: gql`
    fragment QRCodeStep_Listing on Listing {
      id
      url
      source {
        appId
        foreignId
      }
    }
  `,
}
