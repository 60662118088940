import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useContext } from 'react'

import { StackContext } from './Stacks'
import { screen } from '../styles/breakpoints'
import { color } from '../styles/colors'
import { size } from '../styles/sizes'
import { Breadcrumb } from './StackBreadcrumb'
import StackContainer, { StackContainerSizes } from './StackContainer'

export interface StackMainProps {
  variant: 'app' | 'frames' | 'center' | 'topCenter'
  breadcrumb?: React.ReactElement<typeof Breadcrumb>
  size?: StackContainerSizes
  children?: React.ReactNode
}

const Wrapper = styled(StackContainer)<{
  variant: StackMainProps['variant']
  hasBreadcrumb?: boolean
  navOpen?: boolean
  onClick: () => void
}>`
  z-index: 1;
  position: relative;
  margin-bottom: ${size(6.5)};
  transition: transform 0.3s ease-out;

  ${({ hasBreadcrumb }) =>
    hasBreadcrumb &&
    css`
      margin-top: ${size(3)};
    `}

  ${({ navOpen }) =>
    navOpen &&
    css`
      &:hover {
        cursor: pointer;
      }

      @media (min-width: 960px) and (max-width: 1600px) {
        transform: translateX(calc(-832px + 50vw));
      }

      @media (max-width: 960px) {
        transform: translateX(-336px);
      }

      @media (max-width: 376px) {
        transform: translateX(calc(-100vw + ${size(3)}));
      }
    `}

  ${({ variant }) => css`
    ${variant === 'app' &&
    css`
      @media ${screen.s.down} {
        padding-left: 0;
        padding-right: 0;
      }
    `}

    ${variant === 'center' &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  `}
`

const Inner = styled.div<{
  variant: StackMainProps['variant']
}>`
  ${({ variant }) => css`
    ${variant === 'app' &&
    css`
      flex-grow: 1;
      background: ${color.white};
      box-shadow: 0 ${size(1)} ${size(4)} 0 rgba(0, 0, 0, 0.05);
      padding: ${size(12)} 10%;

      @media ${screen.m.up} {
        padding: ${size(16)} 160px;
      }
    `}

    ${variant === 'frames' &&
    css`
      flex-grow: 1;
      padding-top: ${size(9)};
      padding-bottom: ${size(6)};

      @media ${screen.m.up} {
        padding-top: ${size(4)};
      }
    `}

    ${variant === 'center' &&
    css`
      text-align: center;
      padding-top: ${size(7)};
      padding-bottom: ${size(4)};

      @media ${screen.m.up} {
        padding-top: ${size(9)};
      }
    `}

    ${variant === 'topCenter' &&
    css`
      flex-grow: 1;
      text-align: center;
      padding-top: ${size(7)};
      padding-bottom: ${size(4)};

      @media ${screen.m.up} {
        padding-top: ${size(9)};
      }
    `}
  `}

  > h1:first-of-type {
    text-align: center;
  }
`

export const BreadcrumbWrapper = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: ${size(3)};
  right: ${size(3)};
  padding: ${size(3)} ${size(6)};
  display: flex;
  justify-content: center;
  background: ${color.white};
  box-shadow: 0 ${size(1)} ${size(4)} 0 rgba(0, 0, 0, 0.05);
  transform: translateY(-${size(3)});
  transition: transform 0.2s ease-out;

  &:hover {
    transform: translateY(-100%);
  }
`

const StackMain = ({ variant, breadcrumb, children }: StackMainProps) => {
  const stacks = useContext(StackContext)

  const wrapperSize = (variant: StackMainProps['variant']) => {
    switch (variant) {
      case 'app':
        return 'md'
      case 'frames':
        return 'sm'
      case 'center':
      case 'topCenter':
        return 'xs'
      default:
        return 'md'
    }
  }

  return (
    <Wrapper
      variant={variant}
      hasBreadcrumb={!!breadcrumb}
      onClick={() => stacks.isMenuOpen && stacks.toggleNav()}
      size={wrapperSize(variant)}
      fullHeight
      as="main"
      navOpen={stacks.isMenuOpen}
    >
      {variant === 'app' && breadcrumb && (
        <BreadcrumbWrapper>{breadcrumb}</BreadcrumbWrapper>
      )}
      <Inner variant={variant}>{children}</Inner>
    </Wrapper>
  )
}

export default StackMain
