/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { color } from '../../styles/colors'
import { size as sizeUtility } from '../../styles/sizes'

export { ReactComponent as ArrowCycle } from './svg/arrow/cycle.svg'
export { ReactComponent as ArrowDown } from './svg/arrow/down.svg'
export { ReactComponent as ArrowInDown } from './svg/arrow/in-down.svg'
export { ReactComponent as ArrowInRight } from './svg/arrow/in-right.svg'
export { ReactComponent as ArrowLeft } from './svg/arrow/left.svg'
export { ReactComponent as ArrowLeftRight } from './svg/arrow/left-right.svg'
export { ReactComponent as ArrowOutDown } from './svg/arrow/out-down.svg'
export { ReactComponent as ArrowOutLeft } from './svg/arrow/out-left.svg'
export { ReactComponent as ArrowOutRight } from './svg/arrow/out-right.svg'
export { ReactComponent as ArrowOutUp } from './svg/arrow/out-up.svg'
export { ReactComponent as ArrowOutUpRight } from './svg/arrow/out-up-right.svg'
export { ReactComponent as ArrowRight } from './svg/arrow/right.svg'
export { ReactComponent as ArrowUp } from './svg/arrow/up.svg'
export { ReactComponent as ArrowUpDown } from './svg/arrow/up-down.svg'
export { ReactComponent as ArrowUpRight } from './svg/arrow/up-right.svg'

export { ReactComponent as CheckboxEmpty } from './svg/checkbox/empty.svg'
export { ReactComponent as CheckboxEmpty2 } from './svg/checkbox/empty2.svg'
export { ReactComponent as CheckboxTick } from './svg/checkbox/tick.svg'
export { ReactComponent as CheckboxTick2 } from './svg/checkbox/tick2.svg'

export { ReactComponent as ChevronDown } from './svg/chevron/down.svg'
export { ReactComponent as ChevronDown2 } from './svg/chevron/down2.svg'
export { ReactComponent as ChevronLeft } from './svg/chevron/left.svg'
export { ReactComponent as ChevronLeft2 } from './svg/chevron/left2.svg'
export { ReactComponent as ChevronRight } from './svg/chevron/right.svg'
export { ReactComponent as ChevronRight2 } from './svg/chevron/right2.svg'
export { ReactComponent as ChevronUp } from './svg/chevron/up.svg'
export { ReactComponent as ChevronUp2 } from './svg/chevron/up2.svg'
export { ReactComponent as ChevronUpDown2 } from './svg/chevron/up-down2.svg'

export { ReactComponent as CircleCross } from './svg/circle/cross.svg'
export { ReactComponent as CircleCross2 } from './svg/circle/cross2.svg'
export { ReactComponent as CircleEmpty } from './svg/circle/empty.svg'
export { ReactComponent as CircleEmpty2 } from './svg/circle/empty2.svg'
export { ReactComponent as CircleInfo } from './svg/circle/info.svg'
export { ReactComponent as CircleInfo2 } from './svg/circle/info2.svg'
export { ReactComponent as CircleMinus } from './svg/circle/minus.svg'
export { ReactComponent as CircleMinus2 } from './svg/circle/minus2.svg'
export { ReactComponent as CircleMoney } from './svg/circle/money.svg'
export { ReactComponent as CircleMoney2 } from './svg/circle/money2.svg'
export { ReactComponent as CirclePie } from './svg/circle/pie.svg'
export { ReactComponent as CirclePlus } from './svg/circle/plus.svg'
export { ReactComponent as CirclePlus2 } from './svg/circle/plus2.svg'
export { ReactComponent as CircleQuestion } from './svg/circle/question.svg'
export { ReactComponent as CircleQuestion2 } from './svg/circle/question2.svg'
export { ReactComponent as CircleRadio } from './svg/circle/radio.svg'
export { ReactComponent as CircleRadio2 } from './svg/circle/radio2.svg'
export { ReactComponent as CircleTick } from './svg/circle/tick.svg'
export { ReactComponent as CircleTick2 } from './svg/circle/tick2.svg'
export { ReactComponent as CircleWarning } from './svg/circle/warning.svg'
export { ReactComponent as CircleWarning2 } from './svg/circle/warning2.svg'

export { ReactComponent as FileSingle } from './svg/file/single.svg'
export { ReactComponent as FileMultiple } from './svg/file/multiple.svg'
export { ReactComponent as FileTick } from './svg/file/tick.svg'
export { ReactComponent as FileCross } from './svg/file/cross.svg'
export { ReactComponent as FilePlus } from './svg/file/plus.svg'
export { ReactComponent as FileMinus } from './svg/file/minus.svg'

export { ReactComponent as HeroTick } from './svg/hero/tick.svg'
export { ReactComponent as HeroCross } from './svg/hero/cross.svg'
export { ReactComponent as HeroSmile } from './svg/hero/smile.svg'

export { ReactComponent as LargeAuction } from './svg/large/auction.svg'
export { ReactComponent as LargeCompany } from './svg/large/company.svg'
export { ReactComponent as LargeDocument } from './svg/large/document.svg'
export { ReactComponent as LargeDocuments } from './svg/large/documents.svg'
export { ReactComponent as LargeForm } from './svg/large/form.svg'
export { ReactComponent as LargeGuess } from './svg/large/guess.svg'
export { ReactComponent as LargeHouse } from './svg/large/house.svg'
export { ReactComponent as LargeHouses } from './svg/large/houses.svg'
export { ReactComponent as LargeList } from './svg/large/list.svg'
export { ReactComponent as LargeOffer } from './svg/large/offer.svg'
export { ReactComponent as LargePlus } from './svg/large/plus.svg'
export { ReactComponent as LargeUser } from './svg/large/user.svg'
export { ReactComponent as LargeUsers } from './svg/large/users.svg'

export { ReactComponent as ListBullets } from './svg/list/bullets.svg'
export { ReactComponent as ListCross } from './svg/list/cross.svg'
export { ReactComponent as ListPlus } from './svg/list/plus.svg'
export { ReactComponent as ListTick } from './svg/list/tick.svg'

export { ReactComponent as UserCross } from './svg/user/cross.svg'
export { ReactComponent as UserMinus } from './svg/user/minus.svg'
export { ReactComponent as UserMultiple } from './svg/user/multiple.svg'
export { ReactComponent as UserPassport } from './svg/user/passport.svg'
export { ReactComponent as UserPlus } from './svg/user/plus.svg'
export { ReactComponent as UserSingle } from './svg/user/single.svg'
export { ReactComponent as UserTick } from './svg/user/tick.svg'

export { ReactComponent as Asterisk } from './svg/asterisk.svg'
export { ReactComponent as Bag } from './svg/bag.svg'
export { ReactComponent as Bell } from './svg/bell.svg'
export { ReactComponent as Bell2 } from './svg/bell2.svg'
export { ReactComponent as Bird } from './svg/bird.svg'
export { ReactComponent as Bookmark } from './svg/bookmark.svg'
export { ReactComponent as Bookmark2 } from './svg/bookmark2.svg'
export { ReactComponent as Bullet } from './svg/bullet.svg'
export { ReactComponent as Calendar } from './svg/calendar.svg'
export { ReactComponent as Cart } from './svg/cart.svg'
export { ReactComponent as Clock } from './svg/clock.svg'
export { ReactComponent as Contract } from './svg/contract.svg'
export { ReactComponent as Copy } from './svg/copy.svg'
export { ReactComponent as Cross } from './svg/cross.svg'
export { ReactComponent as Divide } from './svg/divide.svg'
export { ReactComponent as Expand } from './svg/expand.svg'
export { ReactComponent as Eye } from './svg/eye.svg'
export { ReactComponent as Folder } from './svg/folder.svg'
export { ReactComponent as Globe } from './svg/globe.svg'
export { ReactComponent as Globe2 } from './svg/globe2.svg'
export { ReactComponent as Heart } from './svg/heart.svg'
export { ReactComponent as Heart2 } from './svg/heart2.svg'
export { ReactComponent as Home } from './svg/home.svg'
export { ReactComponent as Home2 } from './svg/home2.svg'
export { ReactComponent as Link } from './svg/link.svg'
export { ReactComponent as Lock } from './svg/lock.svg'
export { ReactComponent as Lock2 } from './svg/lock2.svg'
export { ReactComponent as Mail } from './svg/mail.svg'
export { ReactComponent as Menu } from './svg/menu.svg'
export { ReactComponent as Menu2 } from './svg/menu2.svg'
export { ReactComponent as Menu3 } from './svg/menu3.svg'
export { ReactComponent as Messages } from './svg/messages.svg'
export { ReactComponent as Messages2 } from './svg/messages2.svg'
export { ReactComponent as Mic } from './svg/mic.svg'
export { ReactComponent as Minus } from './svg/minus.svg'
export { ReactComponent as Paperclip } from './svg/paperclip.svg'
export { ReactComponent as Percent } from './svg/percent.svg'
export { ReactComponent as Phone } from './svg/phone.svg'
export { ReactComponent as Plus } from './svg/plus.svg'
export { ReactComponent as Print } from './svg/print.svg'
export { ReactComponent as QR } from './svg/qr.svg'
export { ReactComponent as Search } from './svg/search.svg'
export { ReactComponent as Settings } from './svg/settings.svg'
export { ReactComponent as Smartphone } from './svg/smartphone.svg'
export { ReactComponent as Tick } from './svg/tick.svg'
export { ReactComponent as Timer } from './svg/timer.svg'
export { ReactComponent as Timer2 } from './svg/timer2.svg'
export { ReactComponent as Trash } from './svg/trash.svg'
export { ReactComponent as Trash2 } from './svg/trash2.svg'
export { ReactComponent as Unlock } from './svg/unlock.svg'
export { ReactComponent as Unlock2 } from './svg/unlock2.svg'

function Icon({
  svg,
  fill,
  size,
  ...props
}: {
  svg: React.ComponentType<React.SVGProps<SVGSVGElement>>
  fill?: string
  size?: number
} & React.SVGProps<SVGSVGElement>) {
  const SpecificIcon = svg

  const fillColor = css`
    path {
      fill: ${fill || color.black};
    }
  `

  return (
    <SpecificIcon
      width={size ? size + 'px' : `${sizeUtility(3)}`}
      height={size ? size + 'px' : `${sizeUtility(3)}`}
      css={fillColor}
      style={{ flexShrink: 0 }}
      {...props}
    />
  )
}

export default Icon
