import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { formatDistanceToNow } from 'date-fns'

import { body } from '../styles/typography.js'
import { size } from '../styles/sizes'

const TimestampWrapper = styled.span<{ size?: string }>`
  font-weight: normal;

  ${(props) =>
    props.size === 's' &&
    css`
      ${body.xs}
      line-height: ${size(3)};
    `}
`

type TimestampProps = {
  timestamp: Date
  size?: 'l' | 'm' | 's'
} & Omit<React.HTMLProps<HTMLDivElement>, 'size'>

const Timestamp = React.forwardRef<HTMLDivElement, TimestampProps>(
  (props, ref) => {
    return (
      <TimestampWrapper size={props.size} ref={ref}>
        {formatDistanceToNow(props.timestamp, {
          addSuffix: true,
        })}
      </TimestampWrapper>
    )
  }
)

export default Timestamp
