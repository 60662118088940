import { GlobalStyle, StackApp } from '@propps/ui'
import React, { Fragment, useEffect, useState } from 'react'
import { match as Match, Route, Switch, useRouteMatch } from 'react-router-dom'

import { ListingRoute } from './pages/listing'
import { TermsRoute } from './pages/terms'
import { useStore } from './store'
import { useAnalytics } from './components/analytics'
import { AmplitudeEventStepNames } from './components/offer-form/amplitude-event-step-names'
import { ListingNotAvailable, PageNotFound } from './pages/error'

export interface ListingUrlRouteParams {
  appId: string
  foreignListingId: string
}

function App() {
  const store = useStore()
  const match = useRouteMatch<ListingUrlRouteParams>(
    '/:appId/listings/:foreignListingId'
  )
  const analytics = useAnalytics()

  const [hasBeenOpened, setHasBeenOpened] = useState(false)
  useEffect(() => {
    if (store.isVisible) {
      setHasBeenOpened(true)
    }
  }, [store.appId, store.isVisible])

  useEffect(() => {
    if (match) {
      analytics.logAmplitudeEvent({
        name: AmplitudeEventStepNames.LISTING_PAGE_VISITED,
        listingId: match.params.foreignListingId ?? '',
        appId: match.params.appId,
        foreignListingId: match.params.foreignListingId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StackApp variant="frames">
      <GlobalStyle />

      {hasBeenOpened && (
        <Switch>
          <Route path="/:appId">
            {({ match }: { match: Match<{ appId: string }> }) => (
              <AppScope appId={match.params.appId}>
                <Route path={`${match.path}/listings/:foreignListingId`}>
                  {({ match }) => <ListingRoute match={match!} />}
                </Route>
              </AppScope>
            )}
          </Route>
        </Switch>
      )}

      <Switch>
        <Route
          path={['/terms/:lineName', '/terms/:lineName/:revisionId']}
          exact
        >
          {({ match }) => <TermsRoute match={match!} />}
        </Route>
        <Route path={['/listing/unavailable']} exact>
          {({ match }) => <ListingNotAvailable />}
        </Route>
        <Route path={['/pagenotfound']} exact>
          {({ match }) => <PageNotFound />}
        </Route>
      </Switch>
    </StackApp>
  )
}

/**
 * Safeguard to make sure we don't accidentally show any data for an
 * app that isn't the one intended by the embedder
 */
function AppScope({
  appId,
  children,
}: {
  appId: string
  children?: React.ReactNode
}) {
  const store = useStore()

  if (store.appId !== appId) {
    return null
  }

  return <Fragment>{children}</Fragment>
}

export default App
