import styled from '@emotion/styled'
import {
  Button,
  FixedButtonWrapper,
  fontSize,
  size,
  StackMain,
  StackNav,
} from '@propps/ui'
import React from 'react'
import { useFrameTransport } from '../../components/FrameTransport'
import Cross from '../../images/big-cross.svg'

const Message = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  img {
    filter: grayscale(100%);
    margin-bottom: ${size(1)};
  }

  svg {
    width: ${size(12)};
    height: ${size(12)};
  }

  p {
    max-width: 290px;
    font-size: ${fontSize.s};
    color: #7f7f7f;
  }
`

const H2 = styled.h2`
  margin-bottom: ${size(2)};

  > div {
    font-weight: normal;
    font-size: ${fontSize.xxl};
  }
`

export function Listing404() {
  const { send } = useFrameTransport()

  return (
    <>
      <StackNav variant="frames" />
      <StackMain variant="topCenter">
        <Message>
          <img width="128" height="128" src={Cross} alt="cross" />
          <H2>
            Digital offers are unavailable <div>for this listing</div>
          </H2>
          <p className="small">
            If you would like to purchase this property, get in touch with the
            agent directly.
          </p>
        </Message>
        <FixedButtonWrapper>
          <Button
            cta
            label="Close"
            type="submit"
            onClick={() => send({ type: 'close' })}
          />
        </FixedButtonWrapper>
      </StackMain>
    </>
  )
}
