import {
  DraftOffer,
  GetCurrentBuyer,
  GetCurrentOffers,
  GetDraftOffer,
  isEmpty,
  Offer,
  Role,
  useAuth,
  useQuery,
} from '@propps/client'
import {
  HeroSmile,
  Icon,
  StackMain,
  StackNav,
  TextPlaceholder,
} from '@propps/ui'
import { compareDesc } from 'date-fns'
import gql from 'graphql-tag'
import React, { Fragment, useEffect, useMemo, useState } from 'react'

import { FrameContentLayout } from '../frame-content-layout'
import { useFrameTransport } from '../FrameTransport'
import { WelcomePage_Listing } from './__generated__/WelcomePage_Listing'

export function WelcomePage({
  listing,
  goToOfferForm,
  goToOfferStatus,
}: {
  listing: WelcomePage_Listing | null
  goToOfferForm: (draft: DraftOffer | null) => void
  goToOfferStatus: (offer: Offer) => void
}) {
  const auth = useAuth()
  const transport = useFrameTransport()

  const listingId = listing?.id ?? null

  const { data: buyer } = useQuery([GetCurrentBuyer, undefined], {
    static: true,
  })

  const { data: currentOffers } = useQuery(
    auth.role && auth.role.name === Role.BUYER && listingId
      ? [GetCurrentOffers, { listingId, buyerId: auth.role.id }]
      : null,
    { static: true }
  )

  const currentOffer = useMemo(
    () =>
      currentOffers !== undefined
        ? currentOffers
            ?.filter((a) => a.created)
            .sort((a, b) => compareDesc(a.created, b.created))[0] || null
        : undefined,
    [currentOffers]
  )

  const { data: draftOffer } = useQuery(
    auth.role && auth.role.name === Role.BUYER && listingId
      ? [GetDraftOffer, { listingId, buyerId: auth.role.id }]
      : null,
    { static: true }
  )

  const [showStatus, setShowStatus] = useState(false)

  useEffect(() => {
    // Hold for three seconds to welcome the user back!
    setTimeout(() => setShowStatus(true), 3000)
    if (currentOffer && showStatus) {
      goToOfferStatus(currentOffer)
    }
  })

  useEffect(() => {
    if (showStatus && draftOffer === null && currentOffer === null) {
      // Both current and draft offers retrieved but both are empty. No point of showing this page.
      goToOfferForm(null)
    }
  }, [showStatus, currentOffer, draftOffer, goToOfferForm])

  return (
    <Fragment>
      <StackNav
        variant="frames"
        menu={[
          {
            label: 'Sign out',
            onClick: () => {
              auth.signOut()
              transport.send({ type: 'close' })
            },
          },
        ]}
      />
      <StackMain variant="topCenter">
        <FrameContentLayout>
          {!showStatus ||
            (currentOffer !== undefined && !currentOffer && (
              <>
                <Icon svg={HeroSmile} size={128} />
                <h1 style={{ textAlign: 'center' }}>
                  Welcome back
                  <span className="light">
                    {buyer ? (
                      buyer.profile.firstName + ' ' + buyer.profile.lastName
                    ) : (
                      <TextPlaceholder />
                    )}
                  </span>
                </h1>
                {!isEmpty(draftOffer) &&
                  currentOffer !== undefined &&
                  !currentOffer && (
                    <p className="grey" style={{ textAlign: 'center' }}>
                      We saved the offer you were working on.
                      <br />
                      Let's continue from where you left off,
                      <br />
                      or if you prefer, you can&nbsp;
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a onClick={() => goToOfferForm(null)}>start again</a>.
                    </p>
                  )}
              </>
            ))}
          {(currentOffer && !showStatus) ||
          currentOffer === undefined ||
          draftOffer === undefined ? (
            <FrameContentLayout.PrimaryAction pending disabled />
          ) : (
            <FrameContentLayout.PrimaryAction
              label={currentOffer ? 'Update offer' : 'Continue'}
              onClick={() => goToOfferForm(draftOffer || null)}
            />
          )}
        </FrameContentLayout>
      </StackMain>
    </Fragment>
  )
}

WelcomePage.fragments = {
  Listing: gql`
    fragment WelcomePage_Listing on Listing {
      id
    }
  `,
}
