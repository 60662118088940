import React from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Emoji from '../elements/Emoji'

import { fontSize } from '../styles/typography.js'
import { size } from '../styles/sizes'

const UserWrapper = styled.div<{ size?: 'l' | 'm' | 's' }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: ${size(3)};
  cursor: pointer;

  > div {
    font-size: ${fontSize.l};
    margin-right: ${size(1)};
  }

  > p {
    margin: 0;
    padding: 0;
    font-size: ${fontSize.m};
  }

  ${(props) =>
    props.size === 'l' &&
    css`
      > div {
        font-size: ${fontSize.xl};
        margin-right: ${size(1)};
      }

      > p {
        font-size: ${fontSize.l};
      }
    `}

  ${(props) =>
    props.size === 's' &&
    css`
      > div {
        font-size: ${fontSize.m};
        margin-right: 4px;
      }

      > p {
        font-size: ${fontSize.s};
      }
    `}
`

type UserProps = {
  name: string
  emoji?: string
  size?: 'l' | 's'
} & Omit<React.HTMLProps<HTMLDivElement>, 'size' | 'as'>

const User = React.forwardRef<HTMLDivElement, UserProps>((props, ref) => {
  return (
    <UserWrapper {...props} ref={ref}>
      <Emoji character={props.emoji ? props.emoji : '😀'} />
      <p>{props.name}</p>
    </UserWrapper>
  )
})

export default User
