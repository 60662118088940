/// <reference types="@emotion/react/types/css-prop" />

import { ApolloProvider } from '@apollo/client'
import {
  APIClientProvider,
  app,
  AuthController,
  createApolloClient,
  ErrorReporting,
  firebase,
  FirebaseAppProvider,
} from '@propps/client'
import { createBrowserHistory } from 'history'
import { autorun, when } from 'mobx'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import { Analytics } from './analytics'
import App from './App'
import { AnalyticsProvider } from './components/analytics'
import { FrameTransportProvider } from './components/FrameTransport'
import { FrameTransport } from './frame-transport'
import { Store, StoreProvider } from './store'

const GA_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACK_ID
const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_ANALYTICS_ID
const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT!

ErrorReporting.init()

const history = createBrowserHistory()
const analytics = new Analytics(history)
const store = Store.create({})
const transport = new FrameTransport()
const apollo = createApolloClient(app.auth(), GRAPHQL_ENDPOINT)

let initialized = false

transport.subscribe(
  'init-app',
  (message: {
    type: 'init-app'
    appId: string
    recaptchaEnabled?: boolean
  }) => {
    initialized = true
    store.setRecaptchaEnabled(
      Boolean(message.recaptchaEnabled && process.env.NODE_ENV === 'production')
    )
    store.setAppId(message.appId)
  }
)

transport.subscribe(
  'set-visible',
  (message: { type: 'set-visible'; value: boolean }) => {
    store.setVisible(message.value)
  }
)

// activate/disable recaptcha validation
autorun(() => {
  if (!store.recaptchaEnabled) {
    console.log('[OfferKit] Recaptcha disabled')
  }
  firebase.auth().settings.appVerificationDisabledForTesting = !store.recaptchaEnabled
})

// activate analytics once the app id is loaded
when(
  () => !!store.appId,
  () => {
    // Do not start analytics if running on a test app
    if (store.appId!.startsWith('app_test_')) return

    analytics.init({
      gaTrackingId: GA_TRACKING_ID,
      amplitudeApiKey: AMPLITUDE_API_KEY,
    })
  }
)

// mount once the app id is loaded
when(
  () => !!store.appId,
  () => {
    ReactDOM.render(
      <React.StrictMode>
        <StoreProvider store={store}>
          <AnalyticsProvider analytics={analytics}>
            <FrameTransportProvider value={transport}>
              <FirebaseAppProvider value={app}>
                <ApolloProvider client={apollo}>
                  <APIClientProvider
                    basepath={process.env.REACT_APP_SERVICES_BASEPATH!}
                  >
                    <AuthController>
                      <Router history={history}>
                        <App />
                      </Router>
                    </AuthController>
                  </APIClientProvider>
                </ApolloProvider>
              </FirebaseAppProvider>
            </FrameTransportProvider>
          </AnalyticsProvider>
        </StoreProvider>
      </React.StrictMode>,
      document.getElementById('root')
    )
  }
)

// default to the test app
setTimeout(() => {
  if (!initialized) {
    console.log(
      'Got no response from the parent frame. Assuming Frames was loaded at the top level.'
    )
    store.setAppId('app_test_offerkit-test-app')
    store.setRecaptchaEnabled(false)
    store.setVisible(true)
  }
}, 100)

transport.init('*')
