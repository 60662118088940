import React from 'react'

import styled from '@emotion/styled'

import { color } from '../styles/colors'
import { fontSize } from '../styles/typography'
import { size } from '../styles/sizes'

import Icon, { CircleInfo } from '../elements/icons'

export const CommonErrorWrapper = styled.div`
  position: relative;
  margin: ${size(2)} 0 ${size(4)};
  padding-right: 10px;
  font-size: ${fontSize.m};
  line-height: 1.5;
  color: ${color.red};
  list-style: none;
  display: flex;

  &:first-child {
    margin-top: 12px;
  }

  &:last-child {
    margin-bottom: 4px;
  }
`
export const SvgWrapper = styled.span`
  padding-right: 12px;
  margin-top: -2px;
`

const CommonError = ({ children, ...props }) => {
  return (
    <CommonErrorWrapper {...props}>
      <SvgWrapper>
        <Icon svg={CircleInfo} fill={color.red} />
      </SvgWrapper>{' '}
      {children}
    </CommonErrorWrapper>
  )
}

export default CommonError
