export enum AmplitudeEventStepNames {
  LISTING_PAGE_VISITED = 'listing page visited',
  WELCOME = 'welcome',
  PRIMARY_BUYER_PHONE = 'primary buyer phone number',
  VIEW_QR_CODE = 'qr code',
  PRIMARY_SMS_VALIDATE = 'primary buyer sms verification',
  PRIMARY_BUYER_BASIC_DETAILS = 'primary buyer basic details',
  OFFER_AMOUNT = 'offer amount',
  SETTLEMENT = 'settlement',
  CONDITIONS = 'conditions',
  FINANCE = 'finance',
  CONVEYANCER = 'conveyancer',
  BUYERS = 'buyers',
  ADD_BUYER = 'add buyer',
  ADDITIONAL_SMS_VALIDATE = 'additional buyer sms verification',
  IDENTITY = 'identity',
  SUMMARY = 'summary',
  SIGNING = 'signing',
}
