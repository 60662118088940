import { useFlowFormContext } from '@propps/client'
import { CommonError } from '@propps/ui'
import React from 'react'

export function SubmissionError() {
  const { submissionError } = useFlowFormContext()

  const errorMessage =
    submissionError &&
    (typeof submissionError === 'string'
      ? submissionError
      : submissionError.message)

  if (!errorMessage) {
    return null
  }

  return <CommonError>{errorMessage}</CommonError>
}
