import { History, Location } from 'history'
import * as Amplitude from 'amplitude-js'
import * as AmplitudeEvents from './amplitude-events'

declare global {
  interface Window {
    ga: Function
    gtag: Function
  }

  const ga: Function
  const gtag: Function
}

export class Analytics {
  gaTrackingId: string | undefined
  amplitudeApiKey: string | undefined
  amplitude: Amplitude.AmplitudeClient | null = null
  userId: string | null = null

  constructor(private history: History) {}

  init({
    gaTrackingId,
    amplitudeApiKey,
  }: {
    gaTrackingId?: string
    amplitudeApiKey?: string
  }) {
    this.gaTrackingId = gaTrackingId
    if (this.gaTrackingId) {
      this.history.listen(this.handleLocationChange.bind(this))
    }

    this.amplitudeApiKey = amplitudeApiKey
    if (this.amplitudeApiKey) {
      this.amplitude = Amplitude.getInstance()
      this.amplitude.init(this.amplitudeApiKey)
    }
  }

  handleLocationChange(location: Location) {
    gtag('config', this.gaTrackingId, {
      page_title: document.title,
      page_path: location.pathname,
    })
    gtag('event', 'screen_view', {
      app_name: window.location.origin,
      screen_name: location.pathname,
    })
  }

  logAmplitudeEvent<
    T extends { name: string } = AmplitudeEvents.Event,
    U extends T = T
  >({ name, ...data }: U) {
    this.amplitude?.logEvent(name, data)
  }

  setUserId(id: string) {
    this.amplitude?.setUserId(id)
  }
}

export { AmplitudeEvents }
