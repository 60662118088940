import styled from '@emotion/styled'
import React, { Fragment, useState } from 'react'
import BaseDatePicker from 'react-datepicker'
import { DialogDisclosure } from 'reakit'

import StackModal, { useStackModalState } from '../layouts/StackModal'
import color from '../styles/colors'
import { screen } from '../styles/breakpoints'
import { fontSize } from '../styles/typography'
import { size } from '../styles/sizes'
import Button, { FixedButtonWrapper } from './Button'
import Icon, { Calendar } from './icons'
import ReadOnly from './ReadOnly'

import ArrowLeft from './icons/svg/arrow/left.svg'
import ArrowRight from './icons/svg/arrow/right.svg'

type BaseDatePickerProps = React.ComponentProps<typeof BaseDatePicker>

const Container = styled.div`
  position: relative;

  .react-datepicker__navigation {
    position: absolute;
    top: 0;
    border: none;
    background: none;
    border-radius: ${size(3)};
    text-indent: -9999px;
    width: ${size(6)};
    height: ${size(6)};
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${size(3)};
    cursor: pointer;

    &:hover {
      background-color: ${color.xxxLightGrey};
    }

    &:active,
    &:focus {
      box-shadow: 0 0 0 2px black;
      outline: none;
      background-color: ${color.white};
    }
  }

  .react-datepicker__navigation--previous {
    left: 0;
    background-image: url(${ArrowLeft});
  }

  .react-datepicker__navigation--next {
    right: 0;
    background-image: url(${ArrowRight});
  }

  .react-datepicker__current-month {
    font-weight: bold;
    font-size: 18px;
    height: ${size(6)};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    font-size: ${fontSize.s};
  }

  .react-datepicker__header .react-datepicker__day-names {
    font-size: ${fontSize.xs};
    font-weight: bold;
    height: ${size(5)};

    .react-datepicker__day-name {
      height: ${size(5)};
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${size(6)};

    @media ${screen.s.down} {
      height: ${size(5)};
    }
  }

  .react-datepicker__month {
  }

  .react-datepicker__day {
    border: 1px solid ${color.xxxLightGrey};
    cursor: pointer;
    background: white;
    margin-top: -1px;
    margin-left: -1px;
    line-height: 1.1;

    &:focus:not(.react-datepicker__day--disabled),
    &:hover:not(.react-datepicker__day--disabled),
    &:active:not(.react-datepicker__day--disabled) {
      z-index: 2;
      outline: 2px solid black;
      outline-offset: -2px;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day:active {
    z-index: 1;
    background-color: black;
    color: white;
    outline: 2px solid black;
    outline-offset: -2px;
  }

  .react-datepicker__day--outside-month {
    color: ${color.black10};
  }

  .react-datepicker__day--disabled.react-datepicker__day--disabled {
    color: ${color.xxxLightGrey};
    text-decoration: line-through;
    background-color: white;
    cursor: default;
  }

  .react-datepicker__day--outside-month.react-datepicker__day--selected {
    color: ${color.white};
    background-color: ${color.grey};
  }
`

export function DatePickerInline({
  ...rest
}: Omit<
  BaseDatePickerProps,
  'inline' | 'fixedHeight' | 'disabledKeyboardNavigation' | 'calendarContainer'
>) {
  const [keyboardEnabled, setKeyboardEnabled] = useState(true)

  return (
    <div
      onClick={() => {
        setKeyboardEnabled(false)
      }}
      onKeyDown={() => {
        setKeyboardEnabled(true)
      }}
    >
      <BaseDatePicker
        calendarContainer={Container}
        inline
        fixedHeight
        disabledKeyboardNavigation={!keyboardEnabled}
        {...rest}
      />
    </div>
  )
}

export function DatePicker({
  format,
  onChange,
  value,
  placeholder = '',
  minDate,
}: {
  format: (value: Date) => string
  onChange: (value: Date) => void
  value: Date | null
  placeholder?: React.ReactNode
  minDate?: Date
}) {
  const state = useStackModalState()

  return (
    <Fragment>
      <DialogDisclosure
        {...state}
        as={ReadOnly}
        style={{ WebkitAppearance: 'none' }}
      >
        <Icon svg={Calendar} style={{ marginRight: `${size(1)}` }} />
        {value ? format(value) : placeholder || <span>&nbsp;</span>}
      </DialogDisclosure>
      <StackModal cta state={state} preventBodyScroll noDismiss>
        <Fragment>
          {state.visible && (
            <DatePickerInline
              selected={value}
              onChange={(date) => {
                if (date instanceof Date) {
                  onChange && onChange(date)
                }
              }}
              minDate={minDate}
            />
          )}
          <FixedButtonWrapper>
            <Button
              cta
              label="Done"
              onClick={() => {
                console.log('done')
                state.hide()
              }}
            />
          </FixedButtonWrapper>
        </Fragment>
      </StackModal>
    </Fragment>
  )
}
