import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { color } from '../styles/colors'
import { size as sizeUtility } from '../styles/sizes'

const StyledCard = styled.div<CardProps>`
  position: relative;
  display: block;
  width: 100%;
  background: transparent;
  border: 0;
  color: inherit;
  box-shadow: inset 0 0 0 1px ${color.xxxLightGrey};

  ${({ size }) =>
    size === 'xs' &&
    css`
      padding: 10px;
      border-radius: 6px;
    `}

  ${({ size }) =>
    size === 'sm' &&
    css`
      padding: 12px;
      border-radius: ${sizeUtility(1)};
    `}

  ${({ size }) =>
    size === 'md' &&
    css`
      padding: 20px;
      border-radius: ${sizeUtility(1)};
    `}

  ${({ size }) =>
    size === 'lg' &&
    css`
      padding: ${sizeUtility(3)};
      border-radius: ${sizeUtility(1)};
    `}

  ${({ clickable }) =>
    clickable &&
    css`
      transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        border-radius: inherit;
        box-shadow: 0 0 0 2px transparent;
        transition: box-shadow 0.1s ease-out;
      }

      &:hover {
        cursor: pointer;
        transform: translateY(-2px);
        box-shadow: 0 ${sizeUtility(1)} ${sizeUtility(4)} 0 rgba(0, 0, 0, 0.15);
        z-index: 1;

        &:before {
          box-shadow: 0 0 0 2px ${color.black};
        }
      }

      &:focus {
        outline: none;
      }

      &:focus,
      &:active {
        &:before {
          box-shadow: 0 0 0 2px ${color.black};
        }
      }
    `}
`

export type CardProps = {
  as?: React.ElementType
  size?: 'xs' | 'sm' | 'md' | 'lg'
  clickable?: boolean
  children: React.ReactNode
}

const Card = ({
  as = 'div',
  size = 'xs',
  clickable,
  children,
  ...props
}: CardProps) => (
  <StyledCard as={as} clickable={clickable} size={size} {...props}>
    {children}
  </StyledCard>
)

export default Card
