import { TypedDocumentNode, useApolloClient } from '@apollo/client'
import {
  BaseStep,
  FlowFormProvider,
  RecursiveStep,
  Role,
  useAuth,
  useFlowForm,
  useSMSVerificationFlow,
} from '@propps/client'
import {
  FixedButtonWrapper,
  Icon,
  QR,
  StackMain,
  StackNav,
  TextPlaceholder,
} from '@propps/ui'
import { Form } from 'formik'
import gql from 'graphql-tag'
import isMobile from 'is-mobile'
import { omit } from 'ramda'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { useStore } from '../../store'
import { useAnalytics } from '../analytics'
import { FrameContentLayout } from '../frame-content-layout'
import { AmplitudeEventStepNames } from '../offer-form/amplitude-event-step-names'
import QRLink from '../QRLink'
import { BuyerAuthFlowValues } from './buyer-auth-flow-values'
import {
  AuthPhoneStep,
  AuthSMSValidateStep,
  BuyerRegistrationStep,
  QrCodeStep,
} from './steps'
import { BuyerAuthFlow_Listing } from './__generated__/BuyerAuthFlow_Listing'
import { GetCurrentBuyerQuery } from './__generated__/GetCurrentBuyerQuery'

const isMobileDevice = isMobile()

type Values = BuyerAuthFlowValues

type AuthFormBaseStep<T> = {
  render: (props: { id: string }) => React.ReactElement
  hideButton?: boolean
} & BaseStep<T>

type AuthFormStep<T> = RecursiveStep<AuthFormBaseStep<T>>

export function BuyerAuthFlow({
  listing,
  onComplete,
  onBackToPreroll,
}: {
  listing?: BuyerAuthFlow_Listing | null
  onComplete: (isNewBuyer: boolean) => void
  onBackToPreroll: () => void
}) {
  const store = useStore()
  const auth = useAuth()
  const apollo = useApolloClient()
  const smsVerification = useSMSVerificationFlow()
  const isNewBuyer = useRef(false)

  const isUserRegistered = useCallback(async () => {
    const { data } = await apollo.query({
      query: GET_CURRENT_BUYER_QUERY,
      fetchPolicy: 'network-only',
    })

    return !!data.me?.buyer
  }, [apollo])

  const steps: AuthFormStep<any>[] = useMemo(
    () => [
      {
        id: 'auth-phone',
        amplitudeEvent: { step: AmplitudeEventStepNames.PRIMARY_BUYER_PHONE },
        render: ({ id }) => (
          <AuthPhoneStep
            id={id}
            smsVerification={smsVerification}
            title={
              <span>
                {listing?.legallyBindingOffersAllowed ? 'Make' : 'Prepare'} an
                offer for
                <br />
                <span className="light">
                  {listing ? (
                    listing.property.address.line1
                  ) : (
                    <TextPlaceholder />
                  )}
                </span>
              </span>
            }
            legallyBindingOffersAllowed={
              listing?.legallyBindingOffersAllowed ?? false
            }
          />
        ),
        children: [
          {
            id: 'qr-code',
            amplitudeEvent: { step: AmplitudeEventStepNames.VIEW_QR_CODE },
            render: ({ id }) => (
              <QrCodeStep id={id} listing={listing ?? null} />
            ),
            hideButton: true,
          },
        ],
      },
      {
        id: 'auth-sms-validate',
        amplitudeEvent: { step: AmplitudeEventStepNames.PRIMARY_SMS_VALIDATE },
        render: ({ id }) => (
          <AuthSMSValidateStep
            id={id}
            smsVerification={smsVerification}
            title="Let's just confirm your number before we continue"
            isUserRegistered={isUserRegistered}
          />
        ),
      },
      {
        id: 'buyer-registration',
        amplitudeEvent: {
          step: AmplitudeEventStepNames.PRIMARY_BUYER_BASIC_DETAILS,
        },
        render: ({ id }) => (
          <BuyerRegistrationStep id={id} isNewBuyer={isNewBuyer} />
        ),
      },
    ],
    [isUserRegistered, listing, smsVerification]
  )

  const initialValues: Values = useMemo(
    () => ({
      phone: '',
      smsVerificationCode: '',
      buyer: {
        email: '',
        firstName: '',
        lastName: '',
      },
      newUser: false,
    }),
    []
  )

  const form = useFlowForm<Values, AuthFormStep<any>>({
    steps,
    initialValues,
    onSubmit: async (values) => {
      if (!auth.fauth.currentUser) {
        throw new Error(
          'Buyer auth flow managed to complete without authenticating the user.'
        )
      }

      if (auth.ref.current?.role?.name !== Role.BUYER) {
        await auth.activateRole(Role.BUYER)
      }

      apollo.cache.modify({
        fields: {
          me: (value, { INVALIDATE }) => INVALIDATE,
        },
      })

      onComplete(isNewBuyer.current)
    },
  })

  // Send the current step to amplitude
  const analytics = useAnalytics()
  const currentStepId = form.currentStep?.id || null
  useEffect(() => {
    if (listing && currentStepId && form.currentStep?.amplitudeEvent) {
      analytics.logAmplitudeEvent({
        name: 'view ' + form.currentStep?.amplitudeEvent.step,
        listingId: listing.id,
        appId: listing.source.appId,
        foreignListingId: listing.source.foreignId,
        ...omit(['step'])(form.currentStep!.amplitudeEvent),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, currentStepId, listing])

  return (
    <>
      <StackNav
        variant="frames"
        onBack={() =>
          form.currentStepInfo.isFirst ? onBackToPreroll() : form.previous()
        }
        showBack
      />
      <StackMain variant="frames">
        <FlowFormProvider value={form}>
          <Form style={{ width: '100%' }}>
            <FrameContentLayout>
              {store.isVisible &&
                form.currentStep &&
                React.cloneElement(
                  form.currentStep.render({
                    id: form.currentStepInfo.path.join('/'),
                  }),
                  { key: form.currentStepInfo.path.join('/') }
                )}
              {!form.currentStep?.hideButton && (
                <FrameContentLayout.PrimaryAction
                  type="submit"
                  label="Continue"
                  pending={form.formik.isSubmitting}
                />
              )}
            </FrameContentLayout>
          </Form>
        </FlowFormProvider>
        {!isMobileDevice &&
          form.currentStep &&
          form.currentStepInfo.isFirst &&
          !form.currentStepInfo.parentId && (
            <FixedButtonWrapper>
              <QRLink>
                <Icon
                  svg={QR}
                  size={40}
                  style={{ marginTop: '-4px', marginLeft: '-4px' }}
                  onClick={() =>
                    form.goToStep([form.currentStep!.id, 'qr-code'])
                  }
                />
              </QRLink>
            </FixedButtonWrapper>
          )}
        <div
          ref={
            smsVerification.verifierContainerRef as React.RefObject<HTMLDivElement>
          }
        />
      </StackMain>
    </>
  )
}

BuyerAuthFlow.fragments = {
  Listing: gql`
    fragment BuyerAuthFlow_Listing on Listing {
      property {
        address {
          line1
        }
      }
      legallyBindingOffersAllowed
      ...QRCodeStep_Listing
    }
    ${QrCodeStep.fragments.Listing}
  `,
}

const GET_CURRENT_BUYER_QUERY: TypedDocumentNode<GetCurrentBuyerQuery> = gql`
  query GetCurrentBuyerQuery {
    me {
      uid
      buyer {
        id
      }
    }
  }
`
