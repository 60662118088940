import { useFlowFormStep } from '@propps/client'
import {
  Button,
  Cards,
  CheckboxControl,
  ChevronRight,
  CircleInfo2,
  CircleMinus,
  CircleTick,
  color,
  Icon,
  LargeCompany,
  LargeUser,
  LargeUsers,
  Section,
  SectionHeader,
  size,
  UserCard,
} from '@propps/ui'
import { FieldArray } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'

import { OfferFormValues } from '../offer-form-values'
import { SubmissionError } from '../submission-error'
import { validateBuyerIdentityStep } from './identity'

export function BuyersStep({ id }: { id: string }) {
  const ctx = useFlowFormStep<OfferFormValues>({
    id,
    onSubmit: (values, helpers) => {
      if (
        values.buyers.signatories.some(
          (signatory, i) => !isBuyerComplete(values, i)
        )
      ) {
        helpers.preventTransition()
        helpers.setSubmissionError(
          'Oops, some buyer details are yet to be completed.'
        )
      }
    },
  })
  const { formik } = ctx
  const {
    values: { buyers },
  } = formik

  const [deleteMode, setDeleteMode] = useState(false)

  useEffect(() => {
    if (deleteMode && buyers.signatories.length === 1) {
      setDeleteMode(false)
    }
  }, [deleteMode, buyers.signatories.length])

  const editBuyer = (index: number) => {
    ctx.goToStep([
      'buyers',
      index + (buyers.signatories[index].id ? '-identity' : '-auth-phone'),
    ])
  }

  const addBuyer = () => {
    ctx.goToStep(['buyers', 'add-buyer'])
  }

  const isBuyerComplete = (values: OfferFormValues, index: number) => {
    let errors = validateBuyerIdentityStep(values, index)
    return Object.entries(errors).length === 0
  }

  return (
    <FieldArray name="buyers.signatories">
      {({ remove }) => (
        <>
          <h2 style={{ marginBottom: size(6) }}>
            Tell us about the people buying this property
          </h2>
          <Section>
            <SectionHeader h3="Buyers" hr>
              {buyers.signatories.length > 1 && (
                <Button
                  xsmall
                  label={deleteMode ? 'Done' : 'Edit'}
                  onClick={() => setDeleteMode((val) => !val)}
                />
              )}
            </SectionHeader>
            <Cards>
              {buyers.signatories.map((buyer, index) => (
                <UserCard
                  svg={
                    buyer.representing.type === 'self'
                      ? LargeUser
                      : buyer.representing.type === 'person'
                      ? LargeUsers
                      : buyer.representing.type === 'company'
                      ? LargeCompany
                      : undefined
                  }
                  tabIndex={0}
                  key={buyer.id || index}
                  primaryText={
                    <span>
                      <span style={{ fontWeight: 'bold' }}>
                        {[buyer.firstName, buyer.lastName]
                          .filter((item) => !!item)
                          .join(' ')}
                      </span>
                      {buyer.representing.type !== 'self' && (
                        <span> on behalf of {buyer.representing.name}</span>
                      )}
                    </span>
                  }
                  secondaryText={
                    isBuyerComplete(formik.values, index)
                      ? buyer.phone
                      : 'Please complete buyer details.'
                  }
                  trailingItems={
                    <Fragment>
                      {deleteMode && index !== 0 ? (
                        <Icon
                          svg={CircleMinus}
                          fill={color.red}
                          onClick={() => remove(index)}
                        />
                      ) : (
                        <Fragment>
                          {buyer.idvDisclaimerAccepted ? (
                            <Icon svg={CircleTick} fill={color.green} />
                          ) : (
                            <Icon svg={CircleInfo2} fill={color.yellow} />
                          )}
                          <Icon svg={ChevronRight} />
                        </Fragment>
                      )}
                    </Fragment>
                  }
                  onClick={!deleteMode ? () => editBuyer(index) : undefined}
                />
              ))}
              {!deleteMode && (
                <UserCard
                  tabIndex={0}
                  draft
                  primaryText="Add a person or company"
                  secondaryText="Buying with someone else?"
                  onClick={() => addBuyer()}
                />
              )}
              <SubmissionError />
            </Cards>
          </Section>
          <Section>
            <SectionHeader h3="Add buyers later" hr />
            <Cards>
              <UserCard
                tabIndex={0}
                svg={LargeUsers}
                primaryText="And/or nominee"
                secondaryText="This allows you to nominate buyers later."
                trailingItems={
                  <CheckboxControl
                    style={{ marginRight: '0' }}
                    {...formik.getFieldProps({
                      type: 'checkbox',
                      name: 'buyers.andOrNominee',
                    })}
                  />
                }
                onClick={() => {
                  formik.setFieldValue(
                    'buyers.andOrNominee',
                    !formik.values.buyers.andOrNominee
                  )
                }}
              />
            </Cards>
          </Section>
        </>
      )}
    </FieldArray>
  )
}
