import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { screen } from '../styles/breakpoints'
import { size } from '../styles/sizes'

const gutterWidth = `${size(1)}`

export type FlexBreakpointType = {
  display?: 'flex' | 'inline-flex'
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  alignContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'stretch'
  fullHeight?: boolean
  fullWidth?: boolean
}

export type FlexType = {
  xs?: FlexBreakpointType
  sm?: FlexBreakpointType
  md?: FlexBreakpointType
  lg?: FlexBreakpointType
}

const flexResponsive = (breakpoint: FlexBreakpointType, screen: string) => css`
  @media ${screen} {
    display: ${breakpoint?.display};
    flex-direction: ${breakpoint?.direction};
    flex-wrap: ${breakpoint?.wrap};
    justify-content: ${breakpoint?.justifyContent};
    align-items: ${breakpoint?.alignItems};
    align-content: ${breakpoint?.alignContent};
    ${breakpoint?.fullHeight !== undefined &&
    css`
      height: ${breakpoint?.fullHeight ? '100%' : 'auto'};
    `}
    ${breakpoint?.fullWidth !== undefined &&
    css`
      width: ${breakpoint?.fullWidth ? '100%' : 'auto'};
    `}
  }
`

const FlexStyled = styled.div<FlexType>`
  margin-right: ${`-${gutterWidth}`};
  margin-left: ${`-${gutterWidth}`};

  ${({ xs }) =>
    css`
      display: ${xs?.display || 'flex'};
      flex-direction: ${xs?.direction};
      flex-wrap: ${xs?.wrap || 'wrap'};
      justify-content: ${xs?.justifyContent};
      align-items: ${xs?.alignItems};
      align-content: ${xs?.alignContent};
      ${xs?.fullHeight &&
      css`
        height: 100%;
      `};
      ${xs?.fullWidth &&
      css`
        width: 100%;
      `};
    `}

  ${({ sm }) => sm && flexResponsive(sm, screen.s.up)}
  ${({ md }) => md && flexResponsive(md, screen.m.up)}
  ${({ lg }) => lg && flexResponsive(lg, screen.l.up)}
`

const Flex = ({
  xs,
  sm,
  md,
  lg,
  children,
  ...props
}: {
  children: React.ReactNode
} & FlexType &
  Omit<React.HTMLProps<HTMLDivElement>, 'as'>) => {
  return (
    <FlexStyled xs={xs} sm={sm} md={md} lg={lg} {...props}>
      {children}
    </FlexStyled>
  )
}

export default Flex
