/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import {
  Alert,
  Bird,
  CircleInfo,
  color,
  fontSize,
  Icon,
  size,
} from '@propps/ui'
import { Tooltip, TooltipReference, useTooltipState } from 'reakit/Tooltip'

const styledTooltip = css`
  max-width: 257px;
  background-color: ${color.black};
  margin: 0 ${size(0.5)};
  padding: 12px ${size(2)};
  border-radius: ${size(1)};
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  opacity: 0;
  transform-origin: top center;
  transform: translate3d(0, ${size(2)}, 0);
  color: ${color.white};
  font-size: ${fontSize.s};
  line-height: ${fontSize.l};
  position: relative;

  [data-enter] & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const warningText = `The Contract of Sale is not available for this property just yet. You
        can still prepare and send an offer, but it won't be binding on you
        until you can review and accept the contract terms.`

export const NonLegallyBindingWarningCard = ({ ...props }) => {
  return (
    <Alert
      {...props}
      title="Early Bird Offer"
      customIcon={<Icon svg={Bird} size={24} fill={color.white} />}
    >
      {warningText}
    </Alert>
  )
}

export const NonLegallyBindingWarningTooltip = () => {
  const tooltip = useTooltipState({ animated: 250, gutter: 14 })
  return (
    <span>
      <TooltipReference {...tooltip}>
        <Icon svg={CircleInfo} />
      </TooltipReference>
      <Tooltip {...tooltip}>
        <div css={styledTooltip}>{warningText}</div>
      </Tooltip>
    </span>
  )
}
