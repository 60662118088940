/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Button, FixedButtonWrapper, screen } from '@propps/ui'
import { AnimatePresence, motion } from 'framer-motion'
import getIsMobile from 'is-mobile'
import { complement } from 'ramda'
import { Children, cloneElement } from 'react'
import * as ReactIs from 'react-is'
import useMedia from 'use-media'

const isMobile = getIsMobile()

export function FrameContentLayout({
  children,
}: {
  children?: React.ReactNode
}) {
  const button = Children.toArray(children).find(isPrimaryAction) ?? null
  children = Children.toArray(children).filter(complement(isPrimaryAction))

  const key = getKey(children)

  const isButtonInline = useMedia(screen.xs) && isMobile

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => {
          window.scrollTo(0, 0)
        }}
      >
        {children && (
          <Transition key={key}>
            <div css={{ flexGrow: 1 }}>{children}</div>
            {isButtonInline && (
              <NormalButtonWrapper css={{ marginBottom: '-56px' }}>
                {button && cloneElement(button, { internal_isChild: true })}
              </NormalButtonWrapper>
            )}
          </Transition>
        )}
      </AnimatePresence>
      {!isButtonInline && (
        <FixedButtonWrapper>
          {button && cloneElement(button, { internal_isChild: true })}
        </FixedButtonWrapper>
      )}
    </div>
  )
}

function PrimaryAction({
  internal_isChild,
  ...props
}: Omit<React.ComponentProps<typeof Button>, 'cta'> & {
  internal_isChild?: boolean
}) {
  if (process.env.NODE_ENV !== 'production') {
    if (!internal_isChild) {
      throw new Error(
        '<FrameContentLayout.Button> must only be rendered as a direct child of <FrameContentLayout>'
      )
    }
  }

  return <Button cta {...props} />
}

const isPrimaryAction = (
  child: React.ReactNode
): child is React.ReactElement<React.ComponentProps<typeof PrimaryAction>> =>
  ReactIs.isElement(child) && child.type === PrimaryAction

FrameContentLayout.PrimaryAction = PrimaryAction

function Transition({ children }: { children?: React.ReactNode }) {
  const shouldReduceMotion: boolean =
    useMedia('(prefers-reduced-motion)') || (window as any).__CI__

  return (
    <motion.div
      initial={{ y: 64, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -64, opacity: 0 }}
      transition={
        shouldReduceMotion
          ? { type: 'tween', duration: 0 }
          : { type: 'spring', damping: 40, stiffness: 400 }
      }
      css={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      {children}
    </motion.div>
  )
}

function NormalButtonWrapper({
  children,
  ...rest
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div css={{ display: 'flex', justifyContent: 'center' }} {...rest}>
      {children}
    </div>
  )
}

function getKey(node: React.ReactNode): string | number | undefined {
  if (Children.count(node) !== 1) {
    return undefined
  }

  node = Children.only(Array.isArray(node) ? node[0] : node)

  if (ReactIs.isElement(node) || ReactIs.isFragment(node)) {
    return node.key || undefined
  }

  return undefined
}
