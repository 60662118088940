/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from '@emotion/styled'
import { useAuth } from '@propps/client'
import {
  Icon,
  HeroCross,
  size,
  StackNav,
  StackMain,
  fontSize,
} from '@propps/ui'
import React from 'react'
import { useHistory } from 'react-router-dom'

const H2 = styled.h2`
  margin-top: ${size(3)};
  margin-bottom: ${size(2)};

  > div {
    font-weight: normal;
    font-size: ${fontSize.xxl};
  }
`

function ErrorPage(
  title: string,
  errorMessage: string,
  showHomeButton?: boolean
) {
  const auth = useAuth()
  const history = useHistory()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function signOut() {
    auth.signOut()
    history.push('/')
  }

  return (
    <>
      <StackNav variant="frames" />
      <StackMain variant="topCenter">
        <Icon svg={HeroCross} size={128} />
        <H2>{title}</H2>
        <p>{errorMessage}</p>
        {showHomeButton && <a onClick={() => history.push('/')}>Home</a>}
      </StackMain>
    </>
  )
}

export function PageNotFound() {
  return ErrorPage('Page not found', `The page cannot be found.`, false)
}

export function ListingNotAvailable() {
  return ErrorPage(
    'Listing not available',
    `The listing is not available yet.
          If you would like to purchase the property, get in touch with the
          agent directly.`
  )
}
