/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  FormattedPhoneNumberInput,
  getErrors,
  StepSubmissionHelpers,
  useFlowFormContext,
  useFlowFormStep,
} from '@propps/client'
import { Input, ListItem, ListWrapper, Select } from '@propps/ui'
import { FormikErrors, yupToFormErrors } from 'formik'
import isMobile from 'is-mobile'
import React, { Fragment } from 'react'
import * as Yup from 'yup'

import {
  OFFER_FORM_DEFAULT_VALUES,
  OfferFormValues,
} from '../offer-form-values'
import { prune } from '../prune'

const isMobileDevice = isMobile()

export function ConveyancerStep({ id }: { id: string }) {
  const { formik } = useFlowFormStep<OfferFormValues>({
    id,
    validate,
    onSubmit: handleSubmit,
  })
  const ctx = useFlowFormContext()

  const skip = () => {
    ctx.next({ snapshot: true })
    formik.setFieldValue('legal', OFFER_FORM_DEFAULT_VALUES.legal)
  }

  return (
    <>
      <h2>Do you have a conveyancer or legal help ready for a purchase?</h2>
      <ListWrapper>
        <ListItem>
          <label>Legal help</label>
          <Select
            autoFocus={!isMobileDevice}
            {...formik.getFieldProps('legal.status')}
            errors={getErrors(formik, 'legal.status')}
          >
            <optgroup label="Legal help status">
              <option disabled value="">
                ...
              </option>
              <option value="conveyancer">
                I have a conveyancer or solicitor ready
              </option>
              <option value="none">I haven't got one yet</option>
              <option value="unsure">I'm unsure what this means</option>
            </optgroup>
          </Select>
        </ListItem>
        {formik.values.legal.status === 'conveyancer' && (
          <Fragment>
            <ListItem>
              <label>Contact name</label>
              <Input
                type="text"
                placeholder="..."
                {...formik.getFieldProps('legal.conveyancerName')}
                errors={getErrors(formik, 'legal.conveyancerName')}
              />
            </ListItem>
            <ListItem>
              <label>Phone number</label>
              <FormattedPhoneNumberInput<React.ComponentProps<typeof Input>>
                component={Input}
                defaultCountry="AU"
                type="text"
                placeholder="..."
                {...formik.getFieldProps('legal.conveyancerPhone')}
                onChange={(value) =>
                  formik.setFieldValue('legal.conveyancerPhone', value)
                }
                errors={getErrors(formik, 'legal.conveyancerPhone')}
                autoComplete="tel"
                inputMode="tel"
              />
            </ListItem>
          </Fragment>
        )}
      </ListWrapper>
      <p className="grey">
        You'll need legal representation to help you with the purchase of a
        property. If you're unsure about this, your agent can help you out. You
        can also <a onClick={() => skip()}>skip this step</a>.
      </p>
    </>
  )
}

const validationSchema = Yup.object().shape({
  legal: Yup.object({
    status: Yup.string()
      .oneOf(
        ['', 'none', 'conveyancer', 'unsure'],
        'Please select a legal option'
      )
      .required('Please select a legal option'),
    conveyancerName: Yup.string().when(
      'status',
      (status: string, schema: Yup.StringSchema) =>
        status === 'conveyancer'
          ? schema.required("Please enter the legal contact's name.")
          : schema.notRequired()
    ),
    conveyancerPhone: Yup.string().when(
      'status',
      (status: string, schema: Yup.StringSchema) =>
        status === 'conveyancer'
          ? schema.required("Please enter the legal contact's phone number.")
          : schema.notRequired()
    ),
  }),
})

function validate(values: OfferFormValues): FormikErrors<OfferFormValues> {
  try {
    validationSchema.validateSync(values)
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      // to avoid errors on the select input thrashing, validation
      // of legal.status is deferred to submission
      return prune(['legal', 'status'], yupToFormErrors(err))
    }
  }

  return {}
}

function handleSubmit(
  values: OfferFormValues,
  helpers: StepSubmissionHelpers<OfferFormValues>
) {
  // to avoid errors on the select input thrashing, validation
  // of legal.status is deferred to submission
  try {
    validationSchema.validateSync(values)
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      helpers.preventTransition()
      helpers.setErrors(yupToFormErrors(err))
      return
    }

    throw err
  }
}
