import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { screen } from '../styles/breakpoints'
import { size } from '../styles/sizes'

const gutterWidth = `${size(1)}`

type FlexItemBreakpointType = {
  size?: 1 | 2 | 3 | 4 | 5 | 6
  grow?: boolean
  shrink?: boolean
  basis?: 'auto' | string
  align?: 'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  order?: number
  margin?: ('top' | 'right' | 'bottom' | 'left')[]
  display?: 'none' | 'block' | 'flex'
}

export type FlexItemType = {
  xs?: FlexItemBreakpointType
  sm?: FlexItemBreakpointType
  md?: FlexItemBreakpointType
  lg?: FlexItemBreakpointType
}

const flexItemResponsive = (
  breakpoint: FlexItemBreakpointType,
  screen: string
) =>
  css`
    @media ${screen} {
      ${(breakpoint?.grow !== undefined &&
        css`
          flex-grow: 1;
        `) ||
      (breakpoint?.shrink !== undefined &&
        css`
          flex-grow: 0;
        `)}
      ${(breakpoint?.basis &&
        css`
          flex-basis: ${breakpoint?.basis};
          max-width: ${breakpoint?.basis};
        `) ||
      (breakpoint?.size &&
        css`
          flex-basis: ${(breakpoint?.size / 6) * 100}%;
          max-width: ${(breakpoint?.size / 6) * 100}%;
        `) ||
      ((breakpoint?.grow !== undefined || breakpoint?.shrink !== undefined) &&
        css`
          flex-basis: auto;
          max-width: none;
        `)}
    align-self: ${breakpoint?.align};
      order: ${breakpoint?.order};
      ${breakpoint?.margin &&
      css`
        margin: 0;
        ${breakpoint?.margin?.map((side) => css`margin-${side}: auto;`)}
      `}
      ${breakpoint?.display !== undefined &&
      css`
        display: ${breakpoint?.display};
      `};
    }
  `

const FlexItemStyled = styled.div<FlexItemType>`
  padding-right: ${gutterWidth};
  padding-left: ${gutterWidth};
  flex: 0 1 auto;

  ${({ xs }) =>
    css`
      ${(xs?.grow &&
        css`
          flex-grow: 1;
        `) ||
      (xs?.shrink &&
        css`
          flex-grow: 0;
        `)}
      ${(xs?.basis &&
        css`
          flex-basis: ${xs?.basis};
          max-width: ${xs?.basis};
        `) ||
      (xs?.size &&
        css`
          flex-basis: ${(xs?.size / 6) * 100}%;
          max-width: ${(xs?.size / 6) * 100}%;
        `) ||
      ((xs?.grow || xs?.shrink) &&
        css`
          flex-basis: auto;
          max-width: none;
        `)}
      align-self: ${xs?.align};
      order: ${xs?.order};
      ${xs?.margin?.map((side) => css`margin-${side}: auto;`)}
      display: ${xs?.display};
    `};

  ${({ sm }) => sm && flexItemResponsive(sm, screen.s.up)};
  ${({ md }) => md && flexItemResponsive(md, screen.m.up)};
  ${({ lg }) => lg && flexItemResponsive(lg, screen.l.up)};
`

const FlexItem = ({
  xs,
  sm,
  md,
  lg,
  children,
  ...props
}: {
  children: React.ReactNode
} & FlexItemType &
  Omit<React.HTMLProps<HTMLDivElement>, 'as'>) => {
  return (
    <FlexItemStyled xs={xs} sm={sm} md={md} lg={lg} {...props}>
      {children}
    </FlexItemStyled>
  )
}

export default FlexItem
