import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { screen } from '../styles/breakpoints'
import { size } from '../styles/sizes'

const CardsWrapper = styled.div`
  display: block;
  margin: ${size(2)} 0 ${size(2)};

  @media ${screen.l.up} {
    margin: ${size(4)} 0 ${size(6)};
  }

  ${(props) =>
    props.grid &&
    css`
      display: flex;
      flex-direciton: row;
      flex-wrap: wrap;
      justify-content: space-between;
    `}

  ${(props) =>
    props.list &&
    css`
      display: flex;
      flex-direciton: column;
    `}
`

const Cards = (props) => {
  return <CardsWrapper {...props}>{props.children}</CardsWrapper>
}

export default Cards
