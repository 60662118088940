import React from 'react'

import styled from '@emotion/styled'

import QrCodeBackground from '../images/qr-placeholder.jpg'
import { size } from '../styles/sizes'

const QrCodePlaceHolderWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  margin-left: -10vw;
  margin-top: ${size(4)};
  margin-bottom: -81px;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;
  max-height: 280px;

  @media (min-width: 480px) {
    width: 100%;
    margin-left: 0;
    max-height: 346px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  svg {
    position: absolute;
    top: 35%;
    left: 30%;
    width: 40%;
    height: 40%;
  }
`

const QrCodePlaceHolder = ({ children }: { children?: React.ReactNode }) => {
  return (
    <QrCodePlaceHolderWrapper>
      <img src={QrCodeBackground} width="972" height="772" />
      {children}
    </QrCodePlaceHolderWrapper>
  )
}

export default QrCodePlaceHolder
