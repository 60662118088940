export enum OfferStatus {
  ACTIVE = 'active',
  WITHDRAWN = 'withdrawn',
  EXPIRED = 'expired',
}

export type SignatureValues = {
  data: string
  signedDate: string
  agreements: {
    id: string
    agreed: boolean
  }[]
}

export type SignatoryValues = {
  id: string
  firstName: string
  middleName: string
  lastName: string
  dateOfBirth: string
  address: string
  phone: string
  email: string
  signature: SignatureValues
  identityDocument: {
    country: string
    idType: string
    region: string
    idNumber: string
  }
  smsVerificationCode: string
  idvDisclaimerAccepted: boolean
  representing: {
    type: string
    name: string
  }
}

export type BuyersValues = {
  primaryBuyerId: string
  signatories: SignatoryValues[]
  andOrNominee: boolean
}

export type ConditionsValues = {
  conditions: { region: string; line: string; id: string }[]
  customCondition: string
}

export type FinanceValues = {
  dateObtained: string
  institution: string
  institutionOther: string
  status: string
  statusOther: string
}

type LegalValues = {
  status: string
  conveyancerName: string
  conveyancerPhone: string
}

export type SettlementValues = {
  type: string
  value: string
}

export type OfferAmountValues = {
  amount: string
  expiry: Date | null
}

type AddBuyerStepValues = {
  canSignNow: boolean
  buyer: {
    firstName: string
    phone: string
    email: string
  }
}

export type OfferFormValues = {
  offer: OfferAmountValues
  buyers: BuyersValues
  settlement: SettlementValues
  conditions: ConditionsValues
  finance: FinanceValues
  legal: LegalValues
  agreeToUseOfDigitalSignatures: boolean
  addBuyerStep: AddBuyerStepValues
  legallyBinding: boolean
  agreeToNonBindingTnC: boolean
}

/* Default values */

export const IDENTITY_DOCUMENT_DEFAULT_VALUES = {
  country: 'AUS',
  idType: 'DRIVERS_LICENCE',
  region: '',
  idNumber: '',
}

export const SIGNATURE_DEFAULT_VALUES = {
  data: '',
  signedDate: '',
  agreements: [],
}

export const SIGNATORY_DEFAULT_VALUES: SignatoryValues = {
  id: '',
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  address: '',
  phone: '',
  smsVerificationCode: '',
  email: '',
  signature: SIGNATURE_DEFAULT_VALUES,
  identityDocument: IDENTITY_DOCUMENT_DEFAULT_VALUES,
  idvDisclaimerAccepted: false,
  representing: {
    type: 'self',
    name: '',
  },
}

export const BUYERS_DEFAULT_VALUES: BuyersValues = {
  primaryBuyerId: '',
  signatories: [SIGNATORY_DEFAULT_VALUES],
  andOrNominee: false,
}

export const OFFER_FORM_DEFAULT_VALUES: OfferFormValues = {
  addBuyerStep: {
    canSignNow: true,
    buyer: {
      firstName: '',
      phone: '',
      email: '',
    },
  },
  buyers: BUYERS_DEFAULT_VALUES,
  settlement: { type: '', value: '' },
  conditions: {
    conditions: [],
    customCondition: '',
  },
  finance: {
    status: '',
    statusOther: '',
    institution: '',
    institutionOther: '',
    dateObtained: '',
  },
  legal: { status: '', conveyancerName: '', conveyancerPhone: '' },
  offer: {
    amount: '',
    expiry: null,
  },
  agreeToUseOfDigitalSignatures: false,
  legallyBinding: false,
  agreeToNonBindingTnC: false,
}
