import React from 'react'
import styled from '@emotion/styled'

const QRLinkWrapper = styled.a`
  position: absolute;
  width: 34px;
  height: 34px;
  bottom: 34px;
  right: 34px;
  border: none;
  opacity: 0.15;

  &:hover,
  &:active,
  &:focus {
    opacity: 1;
  }

  @media (max-width: 414px) and (min-height: 736px) {
    bottom: 44px; /* Larger gap for iPhone X home bar */
  }

  @media (max-width: 576px) {
    display: none;
  }

  svg {
    cursor: pointer;
    pointer-events: all;
  }
`

export const QRLink = ({ children }: { children: any }) => {
  return <QRLinkWrapper>{children}</QRLinkWrapper>
}

export default QRLink
