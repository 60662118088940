import React from 'react'
import styled from '@emotion/styled'

import { color } from '../styles/colors'
import { size } from '../styles/sizes'

const UploadFileWrapper = styled.div`
  [type='file'] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
  }

  label {
    display: inline-flex;
    background: ${color.xxxLightGrey};
    border-radius: ${size(1)};
    line-height: ${size(3)};
    padding: 10px ${size(2)};
    outline: none;
    white-space: nowrap;
    color: ${color.black};
    -webkit-user-select: none;
    cursor: pointer;
    transition: 0.2s all ease-out, 0s color linear, 0s text-indent linear;

    &:hover,
    &:active,
    &:focus {
      background: ${color.xxLightGrey};
    }

    &:active,
    &:focus {
      background: ${color.white};
      box-shadow: 0 0 0 2px ${color.black};
    }
  }
`

const UploadFile = ({ ...props }) => {
  return (
    <UploadFileWrapper>
      <input
        type="file"
        name="upload-file"
        id="upload-file"
        {...props}
        value=""
      />
      <label htmlFor="upload-file">Choose file(s)</label>
    </UploadFileWrapper>
  )
}

export default UploadFile
