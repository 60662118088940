import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { NavLink, NavLinkProps } from 'react-router-dom'

import { StackContext } from './Stacks'

import { zIndex } from '../styles/helpers.jsx'
import { screen } from '../styles/breakpoints'
import { color } from '../styles/colors.jsx'
import { size } from '../styles/sizes'
import { fontSize } from '../styles/typography'

export interface StackMenuWrapperProps {
  showMenu?: boolean
}

const StackMenuWrapper = styled.aside<StackMenuWrapperProps>`
  position: fixed;
  right: 0;
  top: 0;
  width: 336px;
  max-width: 100vw;
  padding: ${size(22)} ${size(3)} ${size(8)} ${size(6)};
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior-y: contain;
  z-index: ${zIndex.stackMenu};
  transition: transform 0.3s ease-out, opacity 0.2s ease-out;
  transform: translate3d(0, 0, 0);

  @media ${screen.s.down} {
    padding-top: ${size(14)};
  }

  @media (max-width: 1600px) {
    visibility: hidden;
    opacity: 0;
    transform: translate3d(${size(8)}, 0, 0);

    li {
      opacity: 0;
      transform: translate3d(${size(8)}, -${size(2)}, 0);
      transition: transform 0.2s ease-out, opacity 0.2s ease-out;
      transition-delay: 0.28s;

      &:nth-child(1) {
        transition-delay: 0s;
      }
      &:nth-child(2) {
        transition-delay: 0.02s;
      }
      &:nth-child(3) {
        transition-delay: 0.04s;
      }
      &:nth-child(4) {
        transition-delay: 0.06s;
      }
      &:nth-child(5) {
        transition-delay: 0.08s;
      }
      &:nth-child(6) {
        transition-delay: 0.1s;
      }
      &:nth-child(7) {
        transition-delay: 0.12s;
      }
      &:nth-child(8) {
        transition-delay: 0.14s;
      }
      &:nth-child(9) {
        transition-delay: 0.16s;
      }
      &:nth-child(10) {
        transition-delay: 0.18s;
      }
      &:nth-child(11) {
        transition-delay: 0.2s;
      }
      &:nth-child(12) {
        transition-delay: 0.22s;
      }
      &:nth-child(13) {
        transition-delay: 0.24s;
      }
      &:nth-child(14) {
        transition-delay: 0.26s;
      }
    }

    ${(props) =>
      props.showMenu &&
      css`
        visibility: visible;
        opacity: 1;
        transform: translate3d(0, 0, 0);

        li {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      `}
  }

  @media (max-width: 376px) {
    width: 100vw;
    border-radius: ${size(3)} ${size(3)} 0 0;
  }

  > h1 {
    margin-top: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  hr {
    margin: ${size(3)} 0 ${size(3)} ${size(8)};
    width: calc(100% + ${size(8)});
  }
`

export interface StyledUserMenuItemProps {
  small?: boolean
  selected?: boolean
}

const StyledUserMenuItem = styled.li<StyledUserMenuItemProps>`
  display: flex;
  padding: 0;
  margin: 0;

  a {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding: ${size(1)} 0;
    margin-left: ${size(8)};
    width: calc(100% + ${size(8)});
    max-width: 200px;
    color: ${color.grey};
    text-decoration: none;
    border-radius: ${size(1)};
    font-size: 18px;
    transition: 0.2s all ease-out;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover,
    &:focus {
      padding: ${size(1)} 10px;
      color: ${color.black};
      background: ${color.black5};
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    ${(props) =>
      props.small &&
      css`
        font-size: ${fontSize.s};
        color: ${color.lightGrey};
      `}

    &.active {
      color: ${color.black};

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -26px;
        top: 15px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${color.black};
      }
    }
  }
`

const StyledMenuItem = styled(StyledUserMenuItem)`
  a {
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
  }

  svg {
    position: absolute;
    left: -${size(4)};
    top: 6px;
  }
`

const Avatar = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -${size(8)};
  width: ${size(6)};
  height: ${size(6)};
  background: ${color.black};
  border-radius: 50%;
  color: ${color.white};
`
const Username = styled.div`
  color: ${color.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const UserEmail = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${fontSize.xs};
  margin-top: 4px;
  width: calc(100% - ${size(3)});
`

export interface MenuItemOwnProps {
  selected?: boolean
  small?: boolean
}

export interface MenuItemProps
  extends MenuItemOwnProps,
    Partial<NavLinkProps> {}

export const MenuItem = ({
  selected,
  small,
  children,
  ...rest
}: MenuItemProps) => {
  return (
    <StyledMenuItem selected={selected} small={small}>
      {rest.href ? (
        <a href={rest.href} {...rest}>
          {children}
        </a>
      ) : (
        <NavLink activeClassName="active" {...(rest as NavLinkProps)}>
          {children}
        </NavLink>
      )}
    </StyledMenuItem>
  )
}

export const MenuItemUser = ({ ...props }) => {
  return (
    <StyledUserMenuItem>
      {props.href ? (
        <a {...props}>
          <Avatar>{props.avatar}</Avatar>
          <Username>{props.name}</Username>
          <UserEmail>{props.email ? props.email : 'No email found'}</UserEmail>
        </a>
      ) : (
        <NavLink {...(props as NavLinkProps)}>
          <Avatar>{props.avatar}</Avatar>
          <Username>{props.name}</Username>
          <UserEmail>{props.email}</UserEmail>
        </NavLink>
      )}
    </StyledUserMenuItem>
  )
}

const StackMenu = ({ ...props }) => {
  const stacks = useContext(StackContext)

  return (
    <StackMenuWrapper showMenu={stacks.isMenuOpen}>
      <nav>
        <ul>{props.children}</ul>
      </nav>
    </StackMenuWrapper>
  )
}

export default StackMenu
