import React, { useState, useEffect } from 'react'

export const TextPlaceholder = () => {
  const [dots, setDots] = useState(1)

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((value) => (value % 3) + 1)
    }, 500)
    return () => clearInterval(interval)
  }, [])

  return <span>{repeat(dots, '.')}</span>
}

function repeat(n: number, str: string) {
  let result = ''
  for (let i = 0; i < n; i++) {
    result = result + str
  }
  return result
}
