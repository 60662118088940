import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { Link } from 'react-router-dom'

import Icon, { ArrowLeft, Smartphone } from '../elements/icons'
import Logo from '../elements/Logo'
import { screen } from '../styles/breakpoints'
import { color } from '../styles/colors'
import { zIndex } from '../styles/helpers'
import { size } from '../styles/sizes'
import { fontSize } from '../styles/typography'
import StackDropdown, { DropdownMenuItem } from './StackDropdown'

/* StackNavItem */
export interface StackNavItemProps {
  as?: 'button' | 'span'
  onClick?: () => void
  icon?:
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    | React.ComponentType<React.SVGProps<SVGSVGElement>>
  iconPlacment?: 'left' | 'right'
  children: React.ReactNode
}

const NavItem = styled.span<{ as?: StackNavItemProps['as'] }>`
  display: inline-flex;
  align-items: center;
  padding: 10px;

  ${(props) =>
    props.as === 'button' &&
    css`
      background: transparent;
      border: 0;
      border-radius: ${size(1)};
      cursor: pointer;

      &:focus {
        outline: none;
        background: ${color.xxxxLightGrey};
      }
    `}
`

const NavLabel = styled.span<{ spacing?: 'left' | 'right' }>`
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${fontSize.xs};
  letter-spacing: 0.1em;

  ${(props) =>
    props.spacing === 'left' &&
    css`
      margin-left: ${size(1)};
    `}

  ${(props) =>
    props.spacing === 'right' &&
    css`
      margin-right: ${size(1)};
    `}
`

/* StackNav */
export interface StackNavProps {
  variant?: 'app' | 'frames'
  showBack?: boolean
  onBack?: () => void
  showSignIn?: boolean
  onSignIn?: () => void
  showMenu?: boolean
  menu?: DropdownMenuItem[]
  customMenu?: React.ReactNode
  left?: React.ReactElement<typeof StackNavItem>
  right?: React.ReactElement<typeof StackNavItem>
  logo?: boolean
  logoLink?: string
  children?: React.ReactNode
}

const Nav = styled.nav<{ variant: StackNavProps['variant'] }>`
  top: 0;
  left: 0;
  z-index: ${zIndex.stackMenu};
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
  height: ${size(8)};
  margin-bottom: ${size(1)};
  padding: 0 ${size(1)};

  @media ${screen.m.up} {
    margin-bottom: ${size(4)};
  }

  ${({ variant }) => css`
    ${variant === 'app' &&
    css`
      margin-top: ${size(1)};

      @media ${screen.m.up} {
        margin-top: ${size(4)};
        padding: 0 ${size(4)};
      }
    `}

    ${variant === 'frames' &&
    css`
      position: sticky;
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: saturate(180%) blur(20px);
    `}
  `}
`

const NavItemContainer = styled.div<{ flush: 'left' | 'center' | 'right' }>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.flush === 'left' &&
    css`
      grid-column: 1 / 2;
      justify-content: flex-start;
    `}

  ${(props) =>
    props.flush === 'center' &&
    css`
      grid-column: 2 / 3;
    `}

  ${(props) =>
    props.flush === 'right' &&
    css`
      grid-column: 3 / 4;
      justify-content: flex-end;
    `}
`

export const StackNavItem = ({
  as = 'span',
  onClick,
  icon,
  iconPlacment = 'left',
  children,
}: StackNavItemProps) => (
  <NavItem as={as} onClick={onClick}>
    {iconPlacment === 'right' && (
      <NavLabel spacing={icon && iconPlacment}>{children}</NavLabel>
    )}
    {icon && <Icon svg={icon} size={24} aria-hidden="true" />}
    {iconPlacment === 'left' && (
      <NavLabel spacing={icon && iconPlacment}>{children}</NavLabel>
    )}
  </NavItem>
)

const StackNav = ({
  variant = 'frames',
  showBack,
  onBack,
  showSignIn,
  onSignIn,
  showMenu = true,
  menu = [],
  customMenu,
  left,
  right,
  logo,
  logoLink,
  children,
}: StackNavProps) => (
  <Nav variant={variant}>
    {(showBack || left) && (
      <NavItemContainer flush="left">
        {showBack && (
          <StackNavItem as="button" onClick={onBack} icon={ArrowLeft}>
            Back
          </StackNavItem>
        )}

        {left}
      </NavItemContainer>
    )}

    {(logo || children) && (
      <NavItemContainer flush="center">
        {logo ? (
          logoLink ? (
            <Link to={logoLink}>
              <Logo />
            </Link>
          ) : (
            <Logo />
          )
        ) : (
          children
        )}
      </NavItemContainer>
    )}

    {(right || showSignIn || showMenu || customMenu) && (
      <NavItemContainer flush="right">
        {right}

        {showSignIn && (
          <StackNavItem
            as="button"
            onClick={onSignIn}
            icon={Smartphone}
            iconPlacment="right"
          >
            Sign In
          </StackNavItem>
        )}

        {showMenu &&
          (customMenu || (!!menu?.length && <StackDropdown menu={menu} />))}
      </NavItemContainer>
    )}
  </Nav>
)

export default StackNav
