import React from 'react'
import styled from '@emotion/styled'

import { color } from '../styles/colors'
import { size } from '../styles/sizes'

const MediaCardWrapper = styled.li`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  width: ${size(16)};
  height: ${size(16)};
  padding: 12px;
  border: 1px solid ${color.xxxLightGrey};
  text-align: center;
`

const MediaCard = () => {
  return <MediaCardWrapper></MediaCardWrapper>
}

export default MediaCard
