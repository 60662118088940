import React from 'react'
import styled from '@emotion/styled'

import { color } from '../styles/colors'
import { screen } from '../styles/breakpoints'
import { size } from '../styles/sizes'

const HeroVideWrapper = styled.div`
  background-color: ${color.black};
  border-radius: ${size(2)};
  box-shadow: 0 ${size(2)} ${size(4)} 0 ${color.black20};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }

  @media ${screen.s.down} {
    position: absolute;
    width: 100vw;
    height: 56vw;
    left: 0;
    top: ${size(9)};
    box-shadow: none;
    border-radius: ${size(3)} ${size(3)} 0 0;
    background-color: ${color.black};
  }

  @media ${screen.s.only} {
    width: 100vw;
    height: 56vw;
    max-width: 556px;
    max-height: 312px;
    margin-left: calc((100% - 556px) / 2);
  }

  @media ${screen.m.up} {
    width: 80vw;
    height: 45vw;
    margin-left: -10vw;
  }

  @media ${screen.l.up} {
    width: 400px;
    height: 225px;
    margin-left: -${size(8)};
  }

  @media ${screen.xl.up} {
    width: 400px; /* 480 x 270 */
    height: 225px;
    margin-left: -${size(2)};
  }
`

export interface HeroVideoProps {
  stream: string
}

const HeroVideo = ({ stream }: HeroVideoProps) => {
  return (
    <HeroVideWrapper>
      <iframe
        src={stream}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </HeroVideWrapper>
  )
}

export default HeroVideo
