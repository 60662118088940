import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useContext } from 'react'

import { screen } from '../styles/breakpoints'
import { color } from '../styles/colors.jsx'
import { zIndex } from '../styles/helpers'
import { size } from '../styles/sizes'
import { StackContext } from './Stacks'

const MenuToggleArea = styled.div`
  display: none;

  @media (min-width: 960px) and (max-width: 1600px) {
    display: flex;
    position: fixed;
    width: calc(50vw - 480px);
    height: 100vh;
    right: 0;
    top: 0;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }
`

const MenuIcon = styled.button<{ navOpen?: boolean }>`
  width: ${size(8)};
  height: ${size(8)};
  padding: 0;
  z-index: ${zIndex.stackMenu + 1};
  background: transparent;
  border-radius: ${size(1)};
  transform: scale(0.75);
  padding: 0;
  border: 0;

  @media (min-width: 1600px) {
    display: none;
  }

  @media ${screen.s.down} {
    top: ${size(1)};
    right: ${size(1)};
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    background-color: ${color.xxxxLightGrey};
    cursor: pointer;
  }

  div {
    position: absolute;
    width: ${size(3)};
    height: 2px;
    background: ${color.black};
    transition: 0.2s all ease-out;
  }

  div:nth-child(1) {
    top: 26px;
    left: 20px;
  }

  div:nth-child(2) {
    top: 36px;
    left: 20px;
  }

  ${({ navOpen }) =>
    navOpen &&
    css`
      div:nth-child(1) {
        top: 31px;
        left: 20px;
        transform: rotate(45deg);
      }

      div:nth-child(2) {
        top: 31px;
        left: 20px;
        transform: rotate(-45deg);
      }
    `}
`

const StackMenuTrigger = ({ hideMenuIcon }: { hideMenuIcon?: boolean }) => {
  const stacks = useContext(StackContext)

  return (
    <>
      {!hideMenuIcon && (
        <MenuIcon
          navOpen={stacks.isMenuOpen}
          onClick={() => stacks.toggleNav()}
        >
          <div />
          <div />
        </MenuIcon>
      )}
      {!stacks.isMenuOpen && (
        <MenuToggleArea onClick={() => stacks.toggleNav()} />
      )}
    </>
  )
}

export default StackMenuTrigger
