import React from 'react'
import { css, Global } from '@emotion/react'
import { color } from './colors'
import { sanitize } from './sanitize'
import { size } from './sizes'
import { typography, fontImports } from './typography'
import { forms } from './forms'

export const GlobalStyle = () => (
  <Global
    styles={css`
      ${fontImports}
      ${sanitize}
      ${typography}
      ${forms}

      body {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
      }

      ::-webkit-scrollbar {
        width: ${size(1)};
        height: ${size(1)};
        background-color: ${color.white};
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${color.black20};
        border: 2px solid ${color.white};
        border-radius: ${size(1)};
        transition: background-color 0.2s ease-out;

        &:hover {
          background-color: ${color.black};
        }
      }
    `}
  />
)

export default GlobalStyle
