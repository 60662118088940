import React, { createContext, useState, useCallback, useMemo } from 'react'

// Define shared state or methods that need to be accessed by the context consumers
type StackContextType = {
  toggleModal: () => void
  toggleNav: () => void
  isModalOpen: boolean
  isMenuOpen: boolean
  modalContent: React.ReactNode
}

export const StackContext = createContext<StackContextType>({
  toggleModal: () => {
    /* noop */
  },
  toggleNav: () => {
    /* noop */
  },
  isModalOpen: false,
  isMenuOpen: false,
  modalContent: null,
})

const Stacks = ({ children }: { children: React.ReactNode }) => {
  // State
  const [state, setState] = useState({
    isModalOpen: false,
    isMenuOpen: false,
    modalContent: null,
  })

  // Methods
  const toggleModal = useCallback(() => {
    setState((state) => ({
      ...state,
      isModalOpen: !state.isModalOpen,
    }))
  }, [])

  const toggleNav = useCallback(() => {
    setState((state) => ({
      ...state,
      isMenuOpen: !state.isMenuOpen,
    }))
  }, [])

  // Combine methods and state into a context object
  const context = useMemo(
    () => ({
      ...state,
      toggleModal,
      toggleNav,
    }),
    [state, toggleModal, toggleNav]
  )

  return (
    <StackContext.Provider value={context}>{children}</StackContext.Provider>
  )
}

export default Stacks
