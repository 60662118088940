import {
  FlowFormContextType,
  formatDollarAmountForInput,
  formatPhoneNumber,
  FormattedNumberInput,
  getError,
  getErrors,
  useFlowFormContext,
  useFlowFormStep,
} from '@propps/client'
import {
  Button,
  Cards,
  Checkbox,
  color,
  CommonError,
  FixedButtonWrapper,
  Flex,
  FlexItem,
  Input,
  Label,
  ListItem,
  ListWrapper,
  ReadOnly,
  Section,
  SectionHeader,
  size,
  StackModal,
  UserCard,
  useStackModalState,
  ArrowOutUpRight,
  Calendar,
  CircleCross,
  CircleInfo2,
  CircleTick,
  Clock,
  FileSingle,
  LargeCompany,
  LargeDocument,
  LargeUser,
  Icon,
  LargeUsers,
} from '@propps/ui'
import { addDays, format, isAfter, isValid } from 'date-fns'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { UserSignature } from '../../UserSignature'
import { OfferFormValues } from '../offer-form-values'
import { SubmissionError } from '../submission-error'
import {
  NonLegallyBindingWarningCard,
  NonLegallyBindingWarningTooltip,
} from '../../NonLegallyBindingWarning'

export function SummaryStep({
  id,
  address,
  documents,
  isUpdatingOffer,
  legallyBinding,
}: {
  id: string
  address: React.ReactNode
  documents: ViewableDocument[]
  isUpdatingOffer: boolean
  legallyBinding: boolean
}) {
  const [confirmSubmit, setConfirmSubmit] = useState(false)
  const [tnc, setTnc] = useState<ViewableDocument>()

  const { formik } = useFlowFormStep<OfferFormValues>({
    id,
    validationSchema,
    onSubmit: (values, helpers) => {
      if (isUpdatingOffer && !confirmSubmit) {
        helpers.preventTransition()
        modal.show()
        return
      }
    },
  })

  const modal = useStackModalState()

  const ctx = useFlowFormContext()

  const svgTick = <Icon svg={CircleTick} fill={color.green} />
  const svgCross = <Icon svg={CircleCross} fill={color.red} />
  const svgInfo = <Icon svg={CircleInfo2} fill={color.yellow} />
  const offerSummaryItems = [
    {
      label: 'Settlement',
      stepId: 'settlement',
      status: `${
        formik.values.settlement.type === 'customDate'
          ? formik.values.settlement.value
          : formik.values.settlement.value + ' days'
      }`,
      icon: <Icon svg={Calendar} />,
    },
    {
      label: 'Valid until',
      stepId: 'conditions',
      status: formik.values.offer.expiry
        ? format(formik.values.offer.expiry, 'h:mma dd MMM yyyy')
        : 'Until withdrawn',
      icon: <Icon svg={Clock} />,
    },
    {
      label: 'Conditions',
      stepId: 'conditions',
      ...(!formik.values.conditions.conditions.length &&
      (!formik.values.conditions.customCondition ||
        formik.values.conditions.customCondition.trim().length <= 0)
        ? {
            status: 'Unconditional',
            icon: svgTick,
          }
        : {
            status: 'Conditions specified',
            icon: svgInfo,
          }),
    },
    {
      label: 'Finance',
      stepId: 'finance',
      ...(['preapproved', 'not-applicable'].includes(
        formik.values.finance.status
      )
        ? {
            status: 'Prepared',
            icon: svgTick,
          }
        : {
            status: 'Not prepared',
            icon: svgCross,
          }),
    },
    {
      label: 'Legal',
      stepId: 'conveyancer',
      ...(formik.values.legal.status === 'conveyancer'
        ? {
            status: 'Engaged',
            icon: svgTick,
          }
        : {
            status: 'Not engaged',
            icon: svgCross,
          }),
    },
  ]

  function goToSigning(index: number) {
    if (
      !formik.values.buyers.signatories[index].signature.agreements ||
      formik.values.buyers.signatories[index].signature.agreements.length === 0
    ) {
      formik.setFieldValue(
        `buyers.signatories[${index}].signature.agreements`,
        documents.map((document) => ({ id: document.name, agreed: false }))
      )
    }

    ctx.goToStep(['summary', index + '-signing'])
  }

  useEffect(() => {
    const tnc = documents.find(({ name }) => name === 'tnc')
    setTnc(tnc)
    formik.setFieldValue('legallyBinding', legallyBinding)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, legallyBinding])

  return (
    <>
      <h2 style={{ marginBottom: size(6) }}>
        Your offer for<span className="light">{address}</span>
      </h2>
      <Section>
        <SectionHeader hr h3="Offer summary" />
        <ListWrapper>
          <ListItem>
            <Label size="small" color="grey">
              Offer amount
            </Label>
            <FormattedNumberInput<React.ComponentProps<typeof Input>>
              component={Input}
              format={formatDollarAmountForInput}
              xlarge
              centered
              type="text"
              {...formik.getFieldProps('offer.amount')}
              onChange={(value) => formik.setFieldValue('offer.amount', value)}
              errors={getErrors(formik, 'offer.amount')}
            />
          </ListItem>
          {offerSummaryItems.map((offerSummary, index) => {
            return (
              <ListItem key={'offer_summary_item_' + index} lineHeight="0">
                <Label size="small" color="grey">
                  {offerSummary.label}
                </Label>
                <ReadOnly>
                  <Flex xs={{ wrap: 'nowrap', alignItems: 'center' }}>
                    <FlexItem xs={{ grow: true }}>
                      {offerSummary.icon}
                      <Label icon color="black" style={{ lineHeight: '20px' }}>
                        {offerSummary.status}
                      </Label>
                    </FlexItem>

                    <FlexItem>
                      <Button
                        xsmall
                        label="View / Edit"
                        onClick={() =>
                          ctx.goToStep([offerSummary.stepId], {
                            returnPath: ['summary'],
                            snapshot: true,
                          })
                        }
                      />
                    </FlexItem>
                  </Flex>
                </ReadOnly>
              </ListItem>
            )
          })}
        </ListWrapper>
      </Section>
      <Section>
        <SectionHeader hr h3="Buyers">
          <Button
            xsmall
            label="Add / Edit"
            onClick={() =>
              ctx.goToStep(['buyers'], { returnPath: ['summary'] })
            }
          />
        </SectionHeader>
        <Cards>
          {formik.values.buyers.signatories.map((signatory, index) => {
            return (
              <UserCard
                svg={
                  signatory.representing.type === 'self'
                    ? LargeUser
                    : signatory.representing.type === 'person'
                    ? LargeUsers
                    : signatory.representing.type === 'company'
                    ? LargeCompany
                    : undefined
                }
                primaryText={
                  <span>
                    <span style={{ fontWeight: 'bold' }}>
                      {[signatory.firstName, signatory.lastName]
                        .filter((item) => !!item)
                        .join(' ')}
                    </span>
                    {signatory.representing.type !== 'self' && (
                      <span> on behalf of {signatory.representing.name}</span>
                    )}
                  </span>
                }
                secondaryText={formatPhoneNumber(signatory.phone)}
                key={'user_' + index}
              />
            )
          })}
          {formik.values.buyers.andOrNominee && (
            <UserCard
              svg={LargeUsers}
              primaryText="And/or nominee"
              secondaryText="You may nominate additional buyers later."
            />
          )}
        </Cards>
      </Section>
      <Section>
        <SectionHeader hr h3="Terms & conditions" />
        <ListWrapper>
          {!legallyBinding ? (
            <ListItem row>
              <Flex
                xs={{ alignItems: 'flex-start' }}
                style={{ opacity: '30%' }}
              >
                <Icon svg={FileSingle} />
                <Label icon color="black" cursor="pointer">
                  Contract of Sale
                </Label>
              </Flex>
              <NonLegallyBindingWarningTooltip />
            </ListItem>
          ) : null}
          {documents.map((document, index) => {
            return (
              <ListItem
                row
                clickable
                onClick={() => window.open(document.url, '_blank')}
                key={document.name + '_' + index}
              >
                <span>
                  <Icon svg={FileSingle} />
                  <Label icon color="black" cursor="pointer">
                    {document.displayName}
                  </Label>
                </span>

                <Icon svg={ArrowOutUpRight} />
              </ListItem>
            )
          })}
        </ListWrapper>
      </Section>
      {legallyBinding ? (
        <Section>
          <SectionHeader hr h3="Signatures" />
          <Cards>
            {formik.values.buyers.signatories.map((signatory, index) => {
              return (
                <UserSignature
                  name={[
                    signatory.firstName,
                    signatory.middleName,
                    signatory.lastName,
                  ]
                    .filter((item) => !!item)
                    .join(' ')}
                  signature={signatory.signature}
                  onClick={() => goToSigning(index)}
                  key={'signature_' + index}
                />
              )
            })}
          </Cards>
          {formik.values.buyers.signatories.map((signatory, index) => {
            return (
              <>
                {getError(
                  formik,
                  `buyers.signatories[${index}].signature.data`
                ) && (
                  <CommonError
                    style={{ paddingTop: '10px', marginBottom: '5px' }}
                    key={'signature_error_' + index}
                  >
                    <span>
                      {getError(
                        formik,
                        `buyers.signatories[${index}].signature.data`
                      )}
                    </span>
                  </CommonError>
                )}
              </>
            )
          })}
          {getError(formik, 'offer.expiry') && (
            <CommonError>
              <span>{getError(formik, `offer.expiry`)}</span>
            </CommonError>
          )}
        </Section>
      ) : (
        <Section>
          <SectionHeader hr h3="Agreement" />
          {tnc ? (
            <>
              <Checkbox
                label={
                  <span>
                    I agree to the{' '}
                    <a href={tnc.url} target="_blank" rel="noopener noreferrer">
                      {documents[0].displayName}
                    </a>
                  </span>
                }
                {...formik.getFieldProps({
                  type: 'checkbox',
                  name: `agreeToNonBindingTnC`,
                })}
              />
              {getError(formik, `agreeToNonBindingTnC`) && (
                <CommonError>
                  <span>{getError(formik, `agreeToNonBindingTnC`)}</span>
                </CommonError>
              )}
              <NonLegallyBindingWarningCard />
            </>
          ) : null}
        </Section>
      )}

      <SubmissionError />
      {legallyBinding ? (
        <Flex
          xs={{
            direction: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          style={{
            textAlign: 'center',
            marginBottom: `-${size(4)}`,
          }}
        >
          <Icon svg={LargeDocument} size={48} />
          <p>
            This offer is legally binding
            <br />
            if accepted by the vendor
          </p>
        </Flex>
      ) : null}
      <StackModal cta size="s" centered state={modal} title="Update offer">
        <p className="grey">
          By sending your updated offer, your previous offer will be withdrawn.
        </p>
        <FixedButtonWrapper>
          <Button
            cta
            onClick={() => {
              setConfirmSubmit(true)
              formik.handleSubmit()
              modal.hide()
            }}
          >
            Send offer
          </Button>
        </FixedButtonWrapper>
      </StackModal>
    </>
  )
}

const validationSchema = Yup.object({
  offer: Yup.object({
    amount: Yup.number()
      // eslint-disable-next-line no-template-curly-in-string
      .typeError('${label} must be a number.')
      .integer()
      .positive("Nothing's free, pal!")
      .label('Amount'),
    expiry: Yup.date()
      .nullable()
      .test(
        'is-future',
        'Please choose a date at least 24 hours in the future.',
        (value: Date | null | undefined): value is Date | null =>
          !value || (isValid(value) && isAfter(value, addDays(new Date(), 1)))
      )
      .label('Expiry date'),
  }),
  buyers: Yup.object().when('legallyBinding', {
    is: true,
    then: Yup.object({
      signatories: Yup.array().of(
        Yup.object({
          signature: Yup.object({
            data: Yup.string().required('Please sign to complete your offer.'),
          }),
        })
      ),
    }).required(),
  }),
  legallyBinding: Yup.boolean(),
  agreeToNonBindingTnC: Yup.boolean()
    .when('legallyBinding', {
      is: false,
      then: Yup.boolean()
        .oneOf([true], 'Please acknowledge you agree to the above.')
        .required('Please acknowledge you agree to the above.'),
    })
    .required(),
})

export type InternalViewableDocument = {
  goTo: (ctx: FlowFormContextType<any, any>) => void
  url?: undefined
  name: string
  displayName: string
}

export type ExternalViewableDocument = {
  goTo?: undefined
  url: string
  name: string
  displayName: string
}

export type ViewableDocument =
  | InternalViewableDocument
  | ExternalViewableDocument
