import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import color from '../styles/colors'
import { size } from '../styles/sizes'
import { fontSize } from '../styles/typography'

const ThumbCardWrapper = styled.div<{ list?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  /* box-shadow: inset 0 0 0 1px ${color.xxxLightGrey}; */
  border: inset 1px solid black;
  border-radius: ${size(1)};
  margin-bottom: ${size(2)};
  cursor: pointer;
  transition: 0.3s all ease-out;

  ${({ list }) =>
    list &&
    css`
      flex-direction: row;
      width: 100%;
    `}

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: ${size(1)};
    box-shadow: 0 0 0 0 transparent, inset 0 0 0 1px ${color.xxxLightGrey};
    transition: 0.1s all ease-out;
  }

  &:hover {
    transform: translate3d(0, -2px, 0);
    box-shadow: 0 ${size(1)} ${size(4)} 0 rgba(0, 0, 0, 0.15);
    z-index: 1;

    &:before {
      box-shadow: 0 0 0 2px ${color.black}, inset 0 0 0 0 transparent;
    }
  }
`

const Thumbnail = styled.div<{ imageFitCover?: boolean; imageHeight?: string }>`
  display: flex;
  justify-content: center;
  flex-basis: ${size(16)};
  width: 100%;
  background: ${color.white};
  border-radius: ${size(1)} ${size(1)} 0 0;

  img {
    width: 256px;
    max-width: 100%;
    max-height: 100%;
    padding-top: 0%;
    object-fit: scale-down;
    object-position: center;
  }

  ${({ imageFitCover }) =>
    imageFitCover &&
    css`
      overflow: hidden;

      img {
        width: 100%;
        object-fit: cover;
      }
    `}

  ${({ imageHeight }) =>
    imageHeight &&
    css`
      flex-basis: ${imageHeight};
    `}
`

const Metadata = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${size(2)} ${size(2)} 12px;
  background: ${color.offWhite};
  border-radius: 0 0 ${size(1)} ${size(1)};
  font-weight: bold;
  border-top: 1px solid ${color.xxxLightGrey};

  .small {
    color: ${color.grey};
    font-size: ${fontSize.s};
    font-weight: normal;
    margin: 0;
    padding: 0;
  }
`

export interface ThumbCardOwnProps {
  desc?: React.ReactNode
  children?: React.ReactNode
  image?: string
  imageFitCover?: boolean
  imageHeight?: string
  list?: boolean
  title?: string
  onClick?: (arg?: unknown) => void
}

export interface ThumbCardProps
  extends ThumbCardOwnProps,
    Omit<React.HTMLProps<HTMLDivElement>, 'list' | 'onClick'> {}

const ThumbCard = ({
  desc,
  children,
  image,
  imageFitCover,
  imageHeight,
  list,
  onClick,
  title,
  ...props
}: ThumbCardOwnProps) => {
  return (
    <ThumbCardWrapper
      onClick={() => onClick && onClick()}
      list={list}
      {...props}
    >
      <Thumbnail imageFitCover={imageFitCover} imageHeight={imageHeight}>
        {image && <img src={image} alt={title} />}
      </Thumbnail>
      <Metadata>
        {title && title}
        {desc && <p className="small">{desc}</p>}
      </Metadata>
    </ThumbCardWrapper>
  )
}

export default ThumbCard
