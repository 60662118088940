import {
  APIClient,
  CreateBuyer,
  formatPhoneNumber,
  FormattedPhoneNumberInput,
  GetCurrentBuyer,
  getErrors,
  runAsUser,
  useFlowFormStep,
  useSMSVerificationFlow,
} from '@propps/client'
import {
  Cards,
  CheckboxControl,
  CommonError,
  Input,
  LargeUsers,
  ListItem,
  ListWrapper,
  Radio,
  RadioGroup,
  Section,
  SectionHeader,
  size,
  UserCard,
} from '@propps/ui'
import { FormikErrors, yupToFormErrors } from 'formik'
import { mergeDeepLeft } from 'ramda'
import React, { useEffect } from 'react'
import * as Yup from 'yup'

import {
  OfferFormValues,
  OFFER_FORM_DEFAULT_VALUES,
  SIGNATORY_DEFAULT_VALUES,
} from '../offer-form-values'
import { SubmissionError } from '../submission-error'

export function AddBuyerStep({
  id,
  smsVerification,
}: {
  id: string
  smsVerification: ReturnType<typeof useSMSVerificationFlow>
}) {
  const { formik } = useFlowFormStep<OfferFormValues>({
    id,
    validate,
    onBack: (helpers) => {
      helpers.setFieldValue(
        'addBuyerStep',
        OFFER_FORM_DEFAULT_VALUES.addBuyerStep,
        false
      )
    },
    onSubmit: async (values, helpers) => {
      helpers.preventTransition()

      if (values.addBuyerStep.canSignNow) {
        const formattedPhoneNumber = formatPhoneNumber(
          values.addBuyerStep.buyer.phone
        )
        helpers.setFieldValue(
          'values.addBuyerStep.buyer.phone',
          formattedPhoneNumber,
          false
        )

        const buyerIndex = values.buyers.signatories.length

        formik.setFieldValue(
          'buyers.signatories',
          [
            ...values.buyers.signatories,
            {
              ...SIGNATORY_DEFAULT_VALUES,
              firstName: values.addBuyerStep.buyer.firstName,
              email: values.addBuyerStep.buyer.email.trim(),
              phone: values.addBuyerStep.buyer.phone,
            },
          ],
          false
        )

        try {
          await smsVerification.start(
            formattedPhoneNumber,
            async (credential) => {
              // Sign in the additional user and create/retrieve
              // their buyer id.
              const id = await runAsUser(credential, async (auth) => {
                const token = { current: auth.currentUser!.getIdToken() }

                const client = new APIClient({
                  basepath: process.env.REACT_APP_SERVICES_BASEPATH!,
                  token,
                  refresh: () =>
                    (token.current = auth.currentUser!.getIdToken()),
                })

                let buyer = await client.request(GetCurrentBuyer)

                if (!buyer) {
                  buyer = await client.request(CreateBuyer, {
                    input: {
                      profile: {
                        firstName: values.addBuyerStep.buyer.firstName,
                        lastName: '',
                        email: values.addBuyerStep.buyer.email.trim(),
                        phone: values.addBuyerStep.buyer.phone,
                      },
                    },
                  })
                }

                return buyer.id
              })

              formik.setFieldValue(
                `buyers.signatories[${buyerIndex}].id`,
                id,
                false
              )

              // reset this step's values
              helpers.setFieldValue(
                'addBuyerStep',
                OFFER_FORM_DEFAULT_VALUES.addBuyerStep,
                false
              )
            }
          )
        } catch (err) {
          helpers.preventTransition()

          switch (err.code) {
            case 'auth/invalid-phone-number':
              console.log('error')
              helpers.setFieldError(
                'addBuyerStep.buyer.phone',
                'Please enter a valid phone number.'
              )
              break
            case 'auth/network-request-failed':
              helpers.setSubmissionError(
                'We were unable to send a verification code due to a network error. Please check your network connection and try again or contact hey@propps.com for support.'
              )
              break
            case 'auth/user-disabled':
            case 'auth/too-many-requests':
              helpers.setSubmissionError(
                'This device or phone number has been temporarily blocked due to unusual activity. Please try again after some time or contact hey@propps.com for support.'
              )
              break
            default:
              console.error(err)
              helpers.setSubmissionError(
                'We were unable to send a verification code. Try again later or contact hey@propps.com for support.' +
                  (err.code ? ' Error code: ' + err.code : '')
              )
          }

          // remove the just added signatory
          formik.setFieldValue(
            'buyers.signatories',
            values.buyers.signatories,
            false
          )

          return
        }

        helpers.goToStep([
          'buyers',
          values.buyers.signatories.length + '-auth-sms-validate',
        ])
      } else {
        helpers.setFieldValue(
          'addBuyerStep',
          OFFER_FORM_DEFAULT_VALUES.addBuyerStep,
          false
        )

        helpers.goToStep(['buyers'])
      }
    },
  })

  useEffect(() => {
    smsVerification.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <h2 style={{ marginBottom: size(6) }}>Add new buyer</h2>
      <Section>
        <SectionHeader h3="Can this person sign now?" hr />
        <RadioGroup>
          <Radio
            label="Yes, this person can sign with me now"
            name="addBuyerStep.canSignNow"
            value="true"
            checked={formik.values.addBuyerStep.canSignNow}
            onChange={(e) => {
              if (e.target.checked) {
                formik.setFieldValue('addBuyerStep.canSignNow', true)
              }
            }}
          />
          <Radio
            label="No, this person can't sign now"
            name="addBuyerStep.canSignNow"
            value="false"
            checked={!formik.values.addBuyerStep.canSignNow}
            onChange={(e) => {
              if (e.target.checked) {
                formik.setFieldValue('addBuyerStep.canSignNow', false)
              }
            }}
          />
        </RadioGroup>
      </Section>
      <Section>
        {formik.values.addBuyerStep.canSignNow ? (
          <>
            <SectionHeader h3="New buyer" hr />
            <ListWrapper>
              <ListItem>
                <label>First name</label>
                <Input
                  type="text"
                  placeholder="..."
                  {...formik.getFieldProps(`addBuyerStep.buyer.firstName`)}
                  errors={getErrors(formik, `addBuyerStep.buyer.firstName`)}
                  autoComplete="given-name"
                />
              </ListItem>
              <ListItem>
                <label>Phone number</label>
                <FormattedPhoneNumberInput<React.ComponentProps<typeof Input>>
                  component={Input}
                  type="text"
                  placeholder="..."
                  defaultCountry="AU"
                  {...formik.getFieldProps(`addBuyerStep.buyer.phone`)}
                  onChange={(value) =>
                    formik.setFieldValue(`addBuyerStep.buyer.phone`, value)
                  }
                  errors={getErrors(formik, `addBuyerStep.buyer.phone`)}
                  autoComplete="tel"
                  inputMode="tel"
                />
              </ListItem>
              <ListItem>
                <label>Email address</label>
                <Input
                  type="text"
                  placeholder="..."
                  {...formik.getFieldProps(`addBuyerStep.buyer.email`)}
                  errors={getErrors(formik, `addBuyerStep.buyer.email`)}
                  autoComplete="email"
                  inputMode="email"
                />
              </ListItem>
            </ListWrapper>
          </>
        ) : (
          <>
            <SectionHeader h3="Add buyers later" hr />
            <Cards>
              <UserCard
                svg={LargeUsers}
                primaryText="And/or nominee"
                secondaryText="This allows you to nominate buyers later."
                trailingItems={
                  <CheckboxControl
                    style={{ marginRight: '0' }}
                    {...formik.getFieldProps({
                      type: 'checkbox',
                      name: 'buyers.andOrNominee',
                    })}
                  />
                }
                onClick={() => {
                  formik.setFieldValue(
                    'buyers.andOrNominee',
                    !formik.values.buyers.andOrNominee
                  )
                }}
              />
            </Cards>
            {formik.touched.buyers?.andOrNominee &&
              formik.errors.buyers?.andOrNominee && (
                <CommonError>{formik.errors.buyers.andOrNominee}</CommonError>
              )}
          </>
        )}
        <SubmissionError />
        {formik.values.addBuyerStep.canSignNow && (
          <p className="grey">
            We’ll send an SMS to verify this buyer’s phone. This person will
            need to complete an identity verification and sign the offer. You
            can also choose to add them later.
          </p>
        )}
      </Section>
    </>
  )
}

function validate(values: OfferFormValues): FormikErrors<OfferFormValues> {
  let errors = {}

  if (values.addBuyerStep.canSignNow) {
    try {
      validationSchema.validateSync(values, { abortEarly: false })
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        errors = mergeDeepLeft(errors, yupToFormErrors(err))
      } else {
        throw err
      }
    }

    if (
      values.buyers.signatories
        .filter((buyer) => !!buyer.id)
        .map((buyer) => buyer.phone.replace(/\s/g, ''))
        .includes(values.addBuyerStep.buyer.phone.replace(/\s/g, ''))
    ) {
      errors = mergeDeepLeft(errors, {
        addBuyerStep: {
          buyer: {
            phone:
              'This phone number is already being used by another buyer on this offer.',
          },
        },
      })
    }

    if (
      values.buyers.signatories
        .filter((buyer) => !!buyer.id)
        .map((buyer) => buyer.email)
        .includes(values.addBuyerStep.buyer.email.trim())
    ) {
      errors = mergeDeepLeft(errors, {
        addBuyerStep: {
          buyer: {
            email:
              'This email address is already being used by another buyer on this offer.',
          },
        },
      })
    }
  } else {
    console.log("Can't sign now")
    if (!values.buyers.andOrNominee) {
      errors = mergeDeepLeft(errors, {
        buyers: {
          andOrNominee:
            'Enable the and/or nominee clause to allow you nominate additional buyers later.',
        },
      })
    }
    console.log(errors)
  }

  return errors as FormikErrors<OfferFormValues>
}

const validationSchema = Yup.object({
  addBuyerStep: Yup.object({
    buyer: Yup.object({
      firstName: Yup.string().required().label('First name'),
      email: Yup.string().trim().email().required().label('Email'),
      phone: Yup.string().required().label('Phone number'),
    }),
  }),
})
