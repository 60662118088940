import {
  BlurFormatInput,
  getError,
  getErrors,
  useAddressFinderWidget,
  useFlowFormStep,
} from '@propps/client'
import {
  Checkbox,
  CommonError,
  Input,
  Label,
  ListItem,
  ListWrapper,
  Section,
  SectionHeader,
  Select,
  size,
} from '@propps/ui'
import { FormikErrors, yupToFormErrors } from 'formik'
import isMobile from 'is-mobile'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import * as Yup from 'yup'

import { DateSchema, formatInputDate } from '../date-schema'
import { OfferFormValues } from '../offer-form-values'

const isMobileDevice = isMobile()

export type IdentityStepProps = {
  id: string
  buyerIndex: number
}

export function IdentityStep({
  id,
  buyerIndex,
}: {
  id: string
  buyerIndex: number
}) {
  const validate = useCallback(
    (values: OfferFormValues) => validateBuyerIdentityStep(values, buyerIndex),
    [buyerIndex]
  )

  const { formik } = useFlowFormStep<OfferFormValues>({
    id,
    validate,
  })

  const [isMounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  // Clear the region field when the id type changes
  useEffect(() => {
    if (isMounted) {
      formik.setFieldValue(
        `buyers.signatories[${buyerIndex}].identityDocument.region`,
        ''
      )
    }
    // Run when idType changes after first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.buyers.signatories[buyerIndex].identityDocument.idType])

  // Clear the representing name field when the representing type changes to 'self'
  useEffect(() => {
    if (isMounted) {
      formik.setFieldValue(
        `buyers.signatories[${buyerIndex}].representing.name`,
        ''
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.buyers.signatories[buyerIndex].representing.type])

  const addressInput = useRef<HTMLElement>()
  useAddressFinderWidget(addressInput, {
    onAddressSelectPre: (address: string) => {
      formik.setFieldValue(`buyers.signatories[${buyerIndex}].address`, address)
      formik.setFieldTouched(`buyers.signatories[${buyerIndex}].address`)
    },
  })

  return (
    <>
      <h2>
        Buyer details
        <span className="light">
          {formik.values.buyers.signatories[buyerIndex].firstName ||
          formik.values.buyers.signatories[buyerIndex].lastName
            ? formik.values.buyers.signatories[buyerIndex].firstName +
              ' ' +
              formik.values.buyers.signatories[buyerIndex].lastName
            : '...'}
        </span>
      </h2>
      <Section>
        <ListWrapper>
          {/* <hr className="tight" /> */}
          <ListItem>
            <Label>First name</Label>
            <Input
              autoFocus={!isMobileDevice}
              type="text"
              placeholder="..."
              {...formik.getFieldProps(
                `buyers.signatories[${buyerIndex}].firstName`
              )}
              errors={getErrors(
                formik,
                `buyers.signatories[${buyerIndex}].firstName`
              )}
              autoComplete="given-name"
            />
          </ListItem>
          <ListItem>
            <Label>Middle name</Label>
            <Input
              type="text"
              placeholder="..."
              {...formik.getFieldProps(
                `buyers.signatories[${buyerIndex}].middleName`
              )}
              errors={getErrors(
                formik,
                `buyers.signatories[${buyerIndex}].middleName`
              )}
              autoComplete="additional-name"
            />
          </ListItem>
          <ListItem>
            <Label>Last name</Label>
            <Input
              type="text"
              placeholder="..."
              {...formik.getFieldProps(
                `buyers.signatories[${buyerIndex}].lastName`
              )}
              errors={getErrors(
                formik,
                `buyers.signatories[${buyerIndex}].lastName`
              )}
              autoComplete="family-name"
            />
          </ListItem>
          <ListItem>
            <Label>Date of birth</Label>
            <BlurFormatInput<React.ComponentProps<typeof Input>>
              component={Input}
              type="text"
              placeholder="DD / MM / YYYY"
              {...formik.getFieldProps(
                `buyers.signatories[${buyerIndex}].dateOfBirth`
              )}
              errors={getErrors(
                formik,
                `buyers.signatories[${buyerIndex}].dateOfBirth`
              )}
              format={(value) => formatInputDate(value)}
              onBlur={(event) => {
                formik.setFieldValue(
                  `buyers.signatories[${buyerIndex}].dateOfBirth`,
                  formatInputDate(
                    formik.values.buyers.signatories[buyerIndex].dateOfBirth
                  )
                )
                formik.handleBlur(event)
              }}
            />
          </ListItem>
          <ListItem>
            <Label>Address</Label>
            <Input
              type="textarea"
              {...formik.getFieldProps(
                `buyers.signatories[${buyerIndex}].address`
              )}
              errors={getErrors(
                formik,
                `buyers.signatories[${buyerIndex}].address`
              )}
              ref={addressInput as React.RefObject<HTMLInputElement>}
            />
          </ListItem>
          <ListItem>
            <label>Identification</label>
            <Select
              {...formik.getFieldProps(
                `buyers.signatories[${buyerIndex}].identityDocument.idType`
              )}
              errors={getErrors(
                formik,
                `buyers.signatories[${buyerIndex}].identityDocument.idType`
              )}
            >
              <optgroup label="Identification type">
                <option value="DRIVERS_LICENCE">Driver's licence</option>
                <option value="PASSPORT">Passport</option>
              </optgroup>
            </Select>
          </ListItem>
          {formik.values.buyers.signatories[buyerIndex].identityDocument
            .idType === 'DRIVERS_LICENCE' && (
            <ListItem>
              <label>State</label>
              <Select
                {...formik.getFieldProps(
                  `buyers.signatories[${buyerIndex}].identityDocument.region`
                )}
                errors={getErrors(
                  formik,
                  `buyers.signatories[${buyerIndex}].identityDocument.region`
                )}
              >
                <optgroup label="State">
                  <option disabled value="">
                    ...
                  </option>
                  <option value="NSW">New South Wales</option>
                  <option value="VIC">Victoria</option>
                  <option value="QLD">Queensland</option>
                  <option value="WA">Western Australia</option>
                  <option value="SA">South Australia</option>
                  <option value="TAS">Tasmania</option>
                  <option value="ACT">Australian Capital Territory</option>
                  <option value="NT">Northern Territory</option>
                </optgroup>
              </Select>
            </ListItem>
          )}
          <ListItem>
            <label>
              {formik.values.buyers.signatories[buyerIndex].identityDocument
                .idType === 'DRIVERS_LICENCE'
                ? 'Licence no.'
                : 'Passport no.'}
            </label>
            <Input
              type="text"
              placeholder="..."
              {...formik.getFieldProps(
                `buyers.signatories[${buyerIndex}].identityDocument.idNumber`
              )}
              errors={getErrors(
                formik,
                `buyers.signatories[${buyerIndex}].identityDocument.idNumber`
              )}
            />
          </ListItem>
          <p className="grey">
            To submit your offer, we'll need to perform an identity verification
            online. Not to worry, it's simple and free. Just make sure the
            information above is what's on your identity document.
          </p>
          <Checkbox
            size="lg"
            id={'idv-disclaimer-checkbox'}
            label={
              <Fragment>
                <strong>I agree to an online identity verification</strong>
                <p style={{ marginTop: `${size(2)}` }}>
                  I confirm that I am authorised to provide the personal details
                  presented and I consent to my information being checked with
                  the document issuer or official record holder via third party
                  systems for the purpose of confirming my identity.
                </p>
              </Fragment>
            }
            {...formik.getFieldProps({
              name: `buyers.signatories[${buyerIndex}].idvDisclaimerAccepted`,
              type: 'checkbox',
            })}
          />
          {getError(
            formik,
            `buyers.signatories[${buyerIndex}].idvDisclaimerAccepted`
          ) && (
            <CommonError>
              {getError(
                formik,
                `buyers.signatories[${buyerIndex}].idvDisclaimerAccepted`
              )}
            </CommonError>
          )}
        </ListWrapper>
      </Section>
      <Section>
        <SectionHeader hr h3="Buyer type" />
        <ListWrapper>
          <ListItem>
            <Label>Buying</Label>
            <Select
              {...formik.getFieldProps(
                `buyers.signatories[${buyerIndex}].representing.type`
              )}
              errors={getErrors(
                formik,
                `buyers.signatories[${buyerIndex}].representing.type`
              )}
            >
              <option value="self">As an individual</option>
              <option value="person">On behalf of another person</option>
              <option value="company">On behalf of a company</option>
            </Select>
          </ListItem>
          {formik.values.buyers.signatories[buyerIndex].representing.type ===
            'person' && (
            <ListItem>
              <Label>Name of represented</Label>
              <Input
                type="text"
                {...formik.getFieldProps(
                  `buyers.signatories[${buyerIndex}].representing.name`
                )}
                errors={getErrors(
                  formik,
                  `buyers.signatories[${buyerIndex}].representing.name`
                )}
              />
            </ListItem>
          )}
          {formik.values.buyers.signatories[buyerIndex].representing.type ===
            'company' && (
            <ListItem>
              <Label>Company name</Label>
              <Input
                type="text"
                {...formik.getFieldProps(
                  `buyers.signatories[${buyerIndex}].representing.name`
                )}
                errors={getErrors(
                  formik,
                  `buyers.signatories[${buyerIndex}].representing.name`
                )}
              />
            </ListItem>
          )}
        </ListWrapper>
        {formik.values.buyers.signatories[buyerIndex].representing.type !==
        'self' ? (
          <p className="grey">
            An agent will be in touch when more information about the
            represented party is needed.
          </p>
        ) : undefined}
      </Section>
    </>
  )
}

export function validateBuyerIdentityStep(
  values: OfferFormValues,
  buyerIndex: number
): FormikErrors<OfferFormValues> {
  try {
    validationSchema.validateSync(values.buyers.signatories[buyerIndex], {
      abortEarly: false,
    })
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      return {
        buyers: {
          signatories: createSingleErrorArray(
            values.buyers.signatories.length,
            buyerIndex,
            yupToFormErrors(err)
          ),
        },
      }
    }

    throw err
  }

  return {}
}

const validationSchema = Yup.object({
  firstName: Yup.string().label('First name').required(),
  middleName: Yup.string().label('Middle name'),
  lastName: Yup.string().label('Last name').required(),
  dateOfBirth: DateSchema.label('Date of birth').required(),
  address: Yup.string().label('Address').required(),
  identityDocument: Yup.object({
    country: Yup.string().oneOf(['AUS']).required(),
    idType: Yup.string().oneOf(['PASSPORT', 'DRIVERS_LICENCE']).required(),
    region: Yup.string().when(
      'idType',
      (type: string, schema: Yup.StringSchema) =>
        type === 'DRIVERS_LICENCE'
          ? schema.required('Please select a state or territory.')
          : schema.notRequired()
    ),
    idNumber: Yup.string().required('Please enter the document number.'),
  }),
  idvDisclaimerAccepted: Yup.boolean().oneOf(
    [true],
    'Please confirm for your identity to be verified'
  ),
  representing: Yup.object({
    type: Yup.string().oneOf(['self', 'person', 'company']).required(),
    name: Yup.string()
      .when('type', (type: string, schema: Yup.StringSchema) =>
        type !== 'self'
          ? schema.required('Please enter the name of the represented party.')
          : schema.notRequired()
      )
      .label('Represented party'),
  }),
})

function createSingleErrorArray(
  length: number,
  index: number,
  errors: FormikErrors<any>
) {
  return Array(length)
    .fill(null)
    .map((value, i) => (i === index ? errors : {}))
}
