/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import styled from '@emotion/styled'
import {
  useTooltipState,
  Tooltip,
  TooltipArrow,
  TooltipReference,
} from 'reakit/Tooltip'
import { fontSize } from '../styles/typography'
import { color } from '../styles/colors'
import { size } from '../styles/sizes'
import Icon, { CircleTick, CircleQuestion2 } from '../elements/icons'
import Card from '../elements/Card'

const Label = styled.p`
  margin: 0 10px;
  flex-grow: 1;
`

const TooltipContainer = styled.div`
  align-self: start;
`

const TooltipReferenceContainer = styled.div`
  span {
    cursor: help;
    color: ${color.xxLightGrey};
    transition: 0.2s color ease-out;

    &:hover,
    &:focus {
      color: ${color.black};
    }

    &:focus {
      outline: none;
    }
  }
`

const tooltipStyles = css`
  z-index: 1;
`

const TooltipModal = styled.div`
  max-width: 257px;
  background-color: ${color.black};
  margin: 0 ${size(0.5)};
  padding: 12px ${size(2)};
  border-radius: ${size(1)};
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  opacity: 0;
  transform-origin: top center;
  transform: translate3d(0, ${size(2)}, 0);
  color: ${color.white};
  font-size: ${fontSize.s};
  line-height: ${fontSize.l};
  position: relative;

  svg {
    fill: ${color.black};
    position: absolute;
    top: 0;
  }

  [data-enter] & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`
export type Props = {
  label: React.ReactNode
  children?: React.ReactNode
}

const TooltipCard = ({ label, children }: Props) => {
  const tooltip = useTooltipState({ animated: 250, gutter: 14 })
  return (
    <Card
      css={css`
        display: flex;
      `}
    >
      <Icon svg={CircleTick} />

      <Label>{label}</Label>

      {children && (
        <TooltipContainer>
          <TooltipReferenceContainer>
            <TooltipReference {...tooltip} as="span">
              <Icon svg={CircleQuestion2} fill="currentColor" />
            </TooltipReference>
          </TooltipReferenceContainer>
          <Tooltip {...tooltip} css={tooltipStyles}>
            <TooltipModal>
              <TooltipArrow {...tooltip} size="12px" />
              {children}
            </TooltipModal>
          </Tooltip>
        </TooltipContainer>
      )}
    </Card>
  )
}

export default TooltipCard
