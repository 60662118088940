import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { color } from '../styles/colors.js'
import { body } from '../styles/typography.js'
import { size } from '../styles/sizes'

const TagWrapper = styled.div`
  ${body.xs}
  color: ${color.black};
  display: inline-flex;
  background: ${color.xxxLightGrey};
  padding: 4px 12px;
  line-height: ${size(2)};
  border-radius: 12px;

  ${(props) =>
    props.large &&
    css`
      ${body.s}
      line-height: 1;
      padding: 9px 18px;
      border-radius: ${size(2)};
    `}

  ${(props) =>
    props.blue &&
    css`
      background: ${color.blue};
      color: ${color.white};
    `}

  ${(props) =>
    props.green &&
    css`
      background: ${color.blue};
      color: ${color.white};
    `}

  ${(props) =>
    props.red &&
    css`
      background: ${color.red};
      color: ${color.white};
    `}
`

const Tag = (props) => {
  return <TagWrapper {...props}>{props.label}</TagWrapper>
}

export default Tag
