import {
  ErrorReporting,
  formatPhoneNumber,
  FormattedPhoneNumberInput,
  getErrors,
  useAuth,
  useFlowFormStep,
  useSMSVerificationFlow,
} from '@propps/client'
import { Input, ListItem, ListWrapper } from '@propps/ui'
import isMobile from 'is-mobile'
import React, { Fragment, useEffect } from 'react'
import * as Yup from 'yup'

import { useAnalytics } from '../../analytics'
import { BuyerAuthFlowValues } from '../buyer-auth-flow-values'
import { SubmissionError } from '../submission-error'
import { NonLegallyBindingWarningCard } from '../../NonLegallyBindingWarning'

const isMobileDevice = isMobile()

export function AuthPhoneStep({
  id,
  smsVerification,
  title,
  legallyBindingOffersAllowed,
}: {
  id: string
  smsVerification: ReturnType<typeof useSMSVerificationFlow>
  title: React.ReactNode
  legallyBindingOffersAllowed: boolean
}) {
  const auth = useAuth()
  const analytics = useAnalytics()

  const { formik } = useFlowFormStep<BuyerAuthFlowValues>({
    id,
    validationSchema,
    onSubmit: async (values, helpers) => {
      const formattedPhoneNumber = formatPhoneNumber(values.phone, {
        defaultCountry: 'AU',
      })
      helpers.setFieldValue(`phone`, formattedPhoneNumber)

      try {
        await smsVerification.start(
          formattedPhoneNumber,
          async (credential) => {
            await auth.signIn(credential)
            const uid = auth.ref.current!.uid!

            analytics.setUserId(uid)
            ErrorReporting.setUserId(uid)

            analytics.logAmplitudeEvent({
              name: 'login',
            })
          }
        )
      } catch (err) {
        helpers.preventTransition()

        switch (err.code) {
          case 'auth/invalid-phone-number':
            helpers.setFieldError(`phone`, 'Please enter a valid phone number.')
            break
          case 'auth/network-request-failed':
            helpers.setSubmissionError(
              'We were unable to send you a verification code due to a network error. Please refresh the page and try again or contact hey@propps.com for support.'
            )
            break
          case 'auth/user-disabled':
          case 'auth/too-many-requests':
            helpers.setSubmissionError(
              'This device or phone number has been temporarily blocked due to unusual activity. Please try again after some time or contact hey@propps.com for support.'
            )
            break
          default:
            console.error(err)
            helpers.setSubmissionError(
              'We were unable to send you a verification code. Try again later or contact hey@propps.com for support.' +
                (err.code ? ' Error code: ' + err.code : '')
            )
        }
      }
    },
  })

  useEffect(() => {
    smsVerification.reset()
    formik.setFieldValue(`smsVerificationCode`, '')
    // only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <h2>{title}</h2>
      <ListWrapper>
        <ListItem>
          <label>Mobile number</label>
          <FormattedPhoneNumberInput<React.ComponentProps<typeof Input>>
            autoFocus={!isMobileDevice}
            component={Input}
            defaultCountry="AU"
            type="text"
            placeholder="..."
            {...formik.getFieldProps(`phone`)}
            onChange={(value) => formik.setFieldValue(`phone`, value)}
            errors={getErrors(formik, `phone`)}
            autoComplete="tel"
            inputMode="tel"
          />
        </ListItem>
      </ListWrapper>
      <p className="grey">
        We'll send you an SMS to verify your number, as we use it to save and
        retrieve your data. We’ll also send you an email with your offer.
      </p>
      <SubmissionError />
      {!legallyBindingOffersAllowed ? <NonLegallyBindingWarningCard /> : null}
    </Fragment>
  )
}

const validationSchema = Yup.object({
  phone: Yup.string().label('Phone number').required(),
})
