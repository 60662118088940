import { getError, useFlowFormStep } from '@propps/client'
import {
  Checkbox,
  CheckboxGroup,
  CommonError,
  Flex,
  Label,
  Section,
  SectionHeader,
  size,
} from '@propps/ui'
import { format, formatISO, parseISO } from 'date-fns'
import { yupToFormErrors } from 'formik'
import React, { useCallback } from 'react'
import * as Yup from 'yup'

import { SignatureCanvas } from '../../SignatureCanvas'
import { displayDateFormat } from '../date-schema'
import { OfferFormValues } from '../offer-form-values'
import { SubmissionError } from '../submission-error'
import { ViewableDocument } from './summary'

export function SigningStep({
  id,
  buyerIndex,
  documents,
}: {
  id: string
  buyerIndex: number
  documents: ViewableDocument[]
}) {
  const validate = useCallback(
    (values: OfferFormValues) => {
      try {
        validationSchema.validateSync(values.buyers.signatories[buyerIndex], {
          abortEarly: false,
        })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          return {
            buyers: {
              signatories: Array(values.buyers.signatories.length)
                .fill(null)
                .map((value, i) =>
                  i === buyerIndex ? yupToFormErrors(err) : {}
                ),
            },
          }
        }
        throw err
      }
      return {}
    },
    [buyerIndex]
  )

  const { formik } = useFlowFormStep<OfferFormValues>({
    id,
    validate,
  })

  const getAgreementsError = () => {
    // Just consolidate the errors into one
    const agreementErrors = formik.values.buyers.signatories[
      buyerIndex
    ].signature.agreements
      .map((agreement, index) => {
        return getError(
          formik,
          `buyers.signatories[${buyerIndex}].signature.agreements[${index}].agreed`
        )
      })
      .filter((error) => error)

    return agreementErrors && agreementErrors.length > 0
      ? agreementErrors[0]
      : null
  }

  const buyer = formik.values.buyers.signatories[buyerIndex]

  function sign(value: string) {
    formik.setFieldValue(
      `buyers.signatories[${buyerIndex}].signature.signedDate`,
      formatISO(new Date())
    )
    formik.setFieldValue(
      `buyers.signatories[${buyerIndex}].signature.data`,
      value
    )
  }

  return (
    <>
      <h2 style={{ marginBottom: size(6) }}>
        It's time to sign<span className="light">{getFullName(buyer)}</span>
      </h2>
      <Section>
        <SectionHeader hr h3="Agreement" />
        <CheckboxGroup>
          {documents.map((document, index: number) => {
            return (
              <Checkbox
                label={
                  <span>
                    I agree to the{' '}
                    <a
                      href={document.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {document.displayName}
                    </a>
                  </span>
                }
                {...formik.getFieldProps({
                  type: 'checkbox',
                  name: `buyers.signatories[${buyerIndex}].signature.agreements[${index}].agreed`,
                })}
              />
            )
          })}
          {getAgreementsError() && (
            <CommonError>
              <span>{getAgreementsError()}</span>
            </CommonError>
          )}
        </CheckboxGroup>
      </Section>
      <Section>
        <SectionHeader hr h3="Signature" />
        <SignatureCanvas
          style={{ width: '100%', height: '240px' }}
          value={buyer.signature.data}
          onChange={(value) => sign(value)}
        />
        <Flex
          xs={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          style={{ marginTop: `-${size(11)}` }}
        >
          <Label color="black" style={{ fontWeight: 'bold' }}>
            {getFullName(buyer)}
          </Label>
          <Label color="grey" style={{ marginRight: '0' }}>
            {buyer.signature.signedDate
              ? format(parseISO(buyer.signature.signedDate), 'dd MMM yyyy')
              : format(new Date(), displayDateFormat)}
          </Label>
        </Flex>
      </Section>
      {getError(formik, `buyers.signatories[${buyerIndex}].signature.data`) && (
        <CommonError>
          {getError(formik, `buyers.signatories[${buyerIndex}].signature.data`)}
        </CommonError>
      )}
      <SubmissionError />
    </>
  )
}

const validationSchema = Yup.object({
  signature: Yup.object({
    data: Yup.string().required('Please sign to complete your offer.'),
    agreements: Yup.array()
      .of(
        Yup.object({
          agreed: Yup.boolean()
            .oneOf([true], 'Please acknowledge you agree to the above.')
            .required('Please acknowledge you agree to the above.'),
        })
      )
      .required(),
  }).required(),
})

function getFullName(obj: {
  firstName: string
  middleName: string
  lastName: string
}) {
  return [obj.firstName, obj.middleName, obj.lastName]
    .filter((part) => !!part)
    .join(' ')
}
