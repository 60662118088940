import styled from '@emotion/styled'
import { Button, color, screen, fontSize, size } from '@propps/ui'
import { format as formatDate, parseISO } from 'date-fns'
import React from 'react'
import { SignatureValues } from './offer-form/offer-form-values'

const UserDetails = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${size(3)};
  border: 1px solid ${color.xxxLightGrey};
  background-color: ${color.white};
  border-radius: ${size(1)} 0 0 ${size(1)};

  @media ${screen.xs} {
    width: 100%;
    padding: 12px ${size(2)};
    border-radius: 0 0 ${size(1)} ${size(1)};
  }
`

const Header = styled.div`
  font-weight: bold;
`

const Content = styled.div`
  color: ${color.grey};
  font-size: ${fontSize.s};
  margin-top: 4px;
`

const Signature = styled.div`
  display: flex;
  width: 100%;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
`

const SignatureWrapper = styled.div<{
  borderStyle: string
}>`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px ${(props) => props.borderStyle} ${color.xxxLightGrey};
  background-color: ${color.white};
  border-radius: 0 ${size(1)} ${size(1)} 0;
  border-left: none;
  cursor: pointer;

  @media ${screen.xs} {
    width: 100%;
    min-height: ${size(12)};
    border-radius: ${size(1)} ${size(1)} 0 0;
    border-left: 1px ${(props) => props.borderStyle} ${color.xxxLightGrey};
    border-bottom: none;
  }

  &:hover,
  &:active,
  &:focus {
    background: ${color.offWhite};
  }

  &:active,
  &:focus {
    background: ${color.white};
    box-shadow: 0 0 0 2px ${color.white}, 0 0 0 3px ${color.black};
  }
`

const UserSignatureWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: ${size(1)};
  margin-bottom: ${size(1)};

  @media ${screen.xs} {
    flex-direction: column-reverse;
  }
`

type UserSignatureProps = {
  name: string
  signature: SignatureValues
  onClick?: Function
}

export const UserSignature = React.forwardRef<
  HTMLDivElement,
  UserSignatureProps
>((props, ref) => {
  return (
    <UserSignatureWrapper
      {...props}
      ref={ref}
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      <UserDetails>
        <Header>{props.name}</Header>
        <Content>
          {props.signature.data
            ? `Signed on ${formatDate(
                parseISO(props.signature.signedDate),
                'dd MMM yyyy'
              )}`
            : 'Signature required'}
        </Content>
      </UserDetails>
      <SignatureWrapper borderStyle={props.signature.data ? 'solid' : 'dashed'}>
        {props.signature.data ? (
          <Signature
            style={{
              backgroundImage: props.signature.data
                ? `url(${props.signature.data})`
                : undefined,
            }}
          />
        ) : (
          <Button xsmall label="Add signature" />
        )}
      </SignatureWrapper>
    </UserSignatureWrapper>
  )
})
