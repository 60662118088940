import React from 'react'
import NumberFormat from 'react-number-format'

import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { color } from '../styles/colors.js'
import { screen } from '../styles/breakpoints'
import { size } from '../styles/sizes'
import { fontSize } from '../styles/typography'

import Icon, {
  Timer,
  UserMultiple,
  UserSingle,
  CircleInfo2,
  CircleTick,
} from '../elements/icons'
import { formatDistanceToNow, isPast, isValid } from 'date-fns'
import Button from '../elements/Button'
import Flex from '../elements/Flex'

const OfferCardWrapper = styled.li<{ hoverless?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: ${size(1)};
  padding: 12px;
  width: 100%;
  margin-bottom: ${size(1)};
  transition: 0.2s all ease-out;

  @media ${screen.l.up} {
    width: calc(50% - ${size(1)});
    margin-bottom: ${size(2)};
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: ${size(1)};
    box-shadow: 0 0 0 0 transparent, inset 0 0 0 1px ${color.xxxLightGrey};
    transition: 0.1s all ease-out;
  }

  ${({ hoverless }) =>
    !hoverless &&
    css`
      transition: all 0.2s ease-out;

      &:hover {
        cursor: pointer;
        transform: translate3d(0, -2px, 0);
        box-shadow: 0 ${size(1)} ${size(4)} 0 rgba(0, 0, 0, 0.15);
        z-index: 1;

        &:before {
          box-shadow: 0 0 0 2px ${color.black}, inset 0 0 0 0 transparent;
        }
      }

      &:focus,
      &:active {
        outline: none;

        &:before {
          box-shadow: 0 0 0 2px ${color.black}, inset 0 0 0 0 transparent;
        }
      }
    `}
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${fontSize.s};
  line-height: ${size(3)};
  height: ${size(3)};
`

const Middle = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: ${size(6)};
`

const Amount = styled.div`
  font-size: ${fontSize.xl};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: ${size(6)};
  text-align: center;
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fontSize.s};
  line-height: ${size(3)};
  height: ${size(3)};
`

const Conditions = styled.div``

type OfferCardProps = {
  fullName: string
  hoverless?: boolean
  amount: string
  timestamp: Date
  identity?: boolean
  finance?: boolean
  deposit?: boolean
  conditional?: boolean
  leadingOffer: boolean
  multipleBuyers: boolean
  expiry?: Date | null
  offerStatus?: string | null
  onClick: React.MouseEventHandler<HTMLLIElement>
} & Omit<React.HTMLProps<HTMLDivElement>, 'size' | 'onClick'>

const OfferCard = React.forwardRef<HTMLLIElement, OfferCardProps>(
  (props, ref) => {
    return (
      <OfferCardWrapper
        hoverless={props.hoverless}
        onClick={props.onClick}
        ref={ref}
      >
        <Top>
          <div>
            <Icon
              svg={props.multipleBuyers ? UserMultiple : UserSingle}
              style={{ marginRight: `${size(1)}` }}
            />
            {props.fullName}
          </div>
          <div
            style={{
              display: 'flex',
              height: `${size(3)}`,
              alignItems: 'center',
              marginRight: `${size(1)}`,
            }}
          >
            {formatDistanceToNow(props.timestamp, { addSuffix: true })}
          </div>
        </Top>
        <Middle>
          {/* props.leadingOffer && <Icon svg={Bookmark} /> */}
          <Amount>
            <NumberFormat
              value={props.amount}
              displayType={'text'}
              thousandSeparator
              prefix={'$ '}
            />
          </Amount>
        </Middle>
        <Bottom>
          <Conditions style={{ height: `${size(3)}`, alignItems: 'center' }}>
            <Icon
              svg={props.conditional ? CircleInfo2 : CircleTick}
              style={{ marginRight: `${size(1)}` }}
              fill={props.conditional ? color.yellow : color.green}
            />
            {props.conditional ? 'Conditional' : 'Unconditional'}
          </Conditions>
          {isWithdrawn(props) && (
            <div>
              <Button
                xsmall
                label="Withdrawn"
                style={{ backgroundColor: color.red, color: color.white }}
              />
            </div>
          )}
          {!isWithdrawn(props) && props.expiry && isValid(props.expiry) && (
            <div>
              {isPast(props.expiry) ? (
                <Button
                  xsmall
                  label="Expired"
                  style={{ backgroundColor: color.red, color: color.white }}
                />
              ) : (
                <Flex xs={{ alignItems: 'center' }}>
                  {formatDistanceToNow(props.expiry)}
                  <Icon svg={Timer} style={{ marginLeft: `${size(1)}` }} />
                </Flex>
              )}
            </div>
          )}
        </Bottom>
      </OfferCardWrapper>
    )
  }
)

export default OfferCard

function isWithdrawn(props: React.PropsWithChildren<OfferCardProps>) {
  return props.offerStatus && props.offerStatus === 'withdrawn'
}
