import { css } from '@emotion/react'
import { color } from './colors'
import { size } from './sizes'
import { screen } from './breakpoints'

/* Imports */
export const fontImports = css`
  @import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');
`

/* Fonts */
export const fontFamily = {
  sans: `'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol'`,
  mono: `'Source Code Pro', monospace`,
}

/* Sizes */
export const fontSize = {
  xs: '12px',
  s: '14px',
  m: '16px',
  l: '20px',
  xl: '24px',
  xxl: '32px',
  xxxl: '40px',
  jumbo: '48px',
  super: '64px',
}

/* Body */
export const body = {
  xs: css`
    font-size: ${fontSize.xs};
    line-height: ${fontSize.m};
  `,
  s: css`
    font-size: ${fontSize.s};
    line-height: ${fontSize.l};
  `,
  m: css`
    font-size: ${fontSize.m};
    line-height: ${fontSize.xl};
  `,
  l: css`
    font-size: ${fontSize.l};
    line-height: ${fontSize.xl};
  `,
  xl: css`
    font-size: ${fontSize.xl};
    line-height: ${fontSize.xxl};
  `,
}

/* Global */
export const typography = css`
  html {
    font-family: ${fontFamily.sans};
    font-size: ${fontSize.m};
    line-height: 1.5;
  }

  /* Headings */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;

    & > .light {
      display: block;
      font-size: inherit;
      font-weight: normal;
      margin-top: ${size(1)};
    }
  }

  h1 {
    font-size: 28px;
    letter-spacing: -0.05em;
    margin-bottom: ${size(8)};
    line-height: 1.1;

    * + & {
      margin-top: ${size(6)};
    }

    @media ${screen.s.up} {
      font-size: ${fontSize.xxl};
    }
  }

  h2 {
    font-size: ${fontSize.xxl};
    letter-spacing: -0.04em;
    margin-bottom: ${size(4)};
    line-height: 1.1;

    * + & {
      margin-top: ${size(2)};
    }
  }

  h3 {
    font-size: ${fontSize.xl};
    letter-spacing: -0.04em;
    margin-bottom: ${size(6)};
    line-height: 1;

    * + & {
      margin-top: ${size(4)};
    }
  }

  h4 {
    font-size: ${fontSize.l};
    margin-bottom: ${size(6)};
    line-height: 1;

    * + & {
      margin-top: ${size(4)};
    }
  }

  h5 {
    font-size: ${fontSize.m};
    margin-bottom: ${size(6)};
    line-height: 1;

    * + & {
      margin-top: ${size(4)};
    }
  }

  h6 {
    font-size: ${fontSize.s};
    margin-bottom: ${size(6)};
    line-height: 1;

    * + & {
      margin-top: ${size(4)};
    }
  }

  p {
    margin: ${size(3)} 0 ${size(4)};

    &.grey {
      color: ${color.grey};
    }
  }

  .code {
    font-family: ${fontFamily.mono};
    font-size: ${fontSize.s};
    line-height: ${size(3)};
  }

  code {
    font-family: ${fontFamily.mono};
    font-size: ${fontSize.s};
    line-height: ${size(3)};
    background-color: ${color.offWhite};
    border-radius: 4px;
    color: ${color.grey};
    padding: 2px 4px;
    overflow: scroll;
  }

  .code {
    font-family: ${fontFamily.mono};
    font-size: ${fontSize.s};
    line-height: ${size(3)};
  }

  pre code {
    display: block;
    background-color: ${color.offWhite};
    border-radius: ${size(1)};
    padding: ${size(1.5)} ${size(2)};
  }

  hr {
    display: block;
    height: 0;
    border: none;
    border-top: 1px solid ${color.xxxLightGrey};
    margin: ${size(4)} 0;

    &.tight {
      margin: 0;
    }
  }

  article {
    &:first-of-type {
      margin-top: 0;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: ${size(3)} 0 ${size(2)};
    }

    ul,
    ol {
      ${body.m}
      margin: ${size(1)} 0 ${size(2)};
      padding-inline-start: ${size(3)};

      li {
        margin-bottom: ${size(2)};
        padding-left: ${size(1)};
      }

      & + p,
      & + ol,
      & + ul {
        margin-top: 0;
      }

      ol {
        list-style-type: lower-alpha;
        padding-inline-start: ${size(4)};
      }
    }
  }

  a {
    color: ${color.blue};
    text-decoration: underline;
    text-decoration-color: ${color.blue25};
    text-underline-position: under;
    cursor: pointer;
    transition: text-decoration-color 0.2s ease-out;

    &:hover,
    &:focus {
      text-decoration-color: ${color.blue};
    }
  }
`
