/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  BlurFormatInput,
  getErrors,
  StepSubmissionHelpers,
  useFlowFormContext,
  useFlowFormStep,
} from '@propps/client'
import { Input, ListItem, ListWrapper, Select } from '@propps/ui'
import { FormikErrors, yupToFormErrors } from 'formik'
import isMobile from 'is-mobile'
import React, { Fragment } from 'react'
import * as Yup from 'yup'

import { DateSchema, formatInputDate } from '../date-schema'
import {
  OFFER_FORM_DEFAULT_VALUES,
  OfferFormValues,
} from '../offer-form-values'
import { prune } from '../prune'

const isMobileDevice = isMobile()

export function FinanceStep({ id }: { id: string }) {
  const { formik } = useFlowFormStep<any>({
    id,
    validate,
    onSubmit: handleSubmit,
  })
  const ctx = useFlowFormContext()

  const skip = () => {
    ctx.next({ snapshot: true })
    formik.setFieldValue('finance', OFFER_FORM_DEFAULT_VALUES.finance)
  }

  return (
    <>
      <h2>Do you have finance organised to buy a property?</h2>
      <ListWrapper>
        <ListItem>
          <label>Finance status</label>
          <Select
            {...formik.getFieldProps('finance.status')}
            errors={getErrors(formik, 'finance.status')}
            autoFocus={!isMobileDevice}
          >
            <optgroup label="Finance status">
              <option disabled value="">
                ...
              </option>
              <option value="preapproved">
                Pre-approved or Approved in Principle
              </option>
              <option value="not-arranged">
                I don't have finance arranged yet
              </option>
              <option value="not-applicable">I don't need finance</option>
              <option value="other">Other</option>
            </optgroup>
          </Select>
        </ListItem>
        {['not-applicable', 'other'].includes(formik.values.finance.status) && (
          <ListItem>
            <label>Notes</label>
            <Input
              type="text"
              placeholder="..."
              {...formik.getFieldProps('finance.statusOther')}
              errors={getErrors(formik, 'finance.statusOther')}
            />
          </ListItem>
        )}
        {'preapproved' === formik.values.finance.status && (
          <Fragment>
            <ListItem>
              <label>Institution</label>
              <Select
                {...formik.getFieldProps('finance.institution')}
                errors={getErrors(formik, 'finance.institution')}
              >
                <optgroup label="Institution">
                  <option disabled value="">
                    ...
                  </option>
                  <option value="anz">ANZ</option>
                  <option value="nab">NAB</option>
                  <option value="cba">Commonwealth Bank</option>
                  <option value="westpac">Westpac Bank</option>
                  <option value="macquarie">Macquarie Bank</option>
                  <option value="bendigo">Bendigo Bank</option>
                  <option value="amp">AMP Bank</option>
                  <option value="suncorp">Suncorp Bank</option>
                  <option value="bankwest">Bankwest</option>
                  <option value="melbourne">Bank of Melbourne</option>
                  <option value="me">ME (Members Equity) Bank</option>
                  <option value="boq">Bank of Queensland</option>
                  <option value="other">Other</option>
                </optgroup>
              </Select>
            </ListItem>
            {formik.values.finance.institution === 'other' && (
              <ListItem>
                <label />
                <Input
                  type="text"
                  placeholder="Bank or institution name"
                  {...formik.getFieldProps('finance.institutionOther')}
                  errors={getErrors(formik, 'finance.institutionOther')}
                />
              </ListItem>
            )}
            <ListItem>
              <label>Date obtained</label>
              <BlurFormatInput<React.ComponentProps<typeof Input>>
                component={Input}
                type="text"
                placeholder="DD / MM / YYYY"
                {...formik.getFieldProps('finance.dateObtained')}
                errors={getErrors(formik, 'finance.dateObtained')}
                format={(value) => formatInputDate(value)}
                onBlur={(event) => {
                  formik.setFieldValue(
                    'finance.dateObtained',
                    formatInputDate(formik.values.finance.dateObtained)
                  )
                  formik.handleBlur(event)
                }}
              />
            </ListItem>
          </Fragment>
        )}
      </ListWrapper>
      <p className="grey">
        Confirming your finance is organised will make your offer appear
        stronger to the vendor. You can also{' '}
        <a onClick={skip}>skip this step</a>.
      </p>
    </>
  )
}

const validationSchema = Yup.object().shape({
  finance: Yup.object({
    status: Yup.string()
      .oneOf(
        ['preapproved', 'not-arranged', 'not-applicable', 'other'],
        'Please select a finance status option'
      )
      .required('Please select a finance status option'),
    statusOther: Yup.string(),
    institution: Yup.string().when(
      'status',
      (status: string, schema: Yup.StringSchema) =>
        'preapproved' === status
          ? schema.required('Please enter the financial institution.')
          : schema.notRequired()
    ),
    institutionOther: Yup.string()
      .label('Institution name')
      .when(['status', 'institution'], {
        is: (status: string, institution: string) =>
          'preapproved' === status && institution === 'other',
        then: (schema) => schema.required('Please enter the institution.'),
        otherwise: (schema) => schema.notRequired(),
      }),
    dateObtained: DateSchema.when(
      'status',
      (status: string, schema: Yup.StringSchema) =>
        'preapproved' === status
          ? schema.required('Please enter the date obtained.')
          : schema.notRequired()
    ),
  }),
})

function validate(values: OfferFormValues): FormikErrors<OfferFormValues> {
  try {
    validationSchema.validateSync(values)
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      // to avoid errors on the select input thrashing, validation
      // of finance.status is deferred to submission
      return prune(['finance', 'status'], yupToFormErrors(err))
    }
  }

  return {}
}

function handleSubmit(
  values: OfferFormValues,
  helpers: StepSubmissionHelpers<OfferFormValues>
) {
  // to avoid errors on the select input thrashing, validation
  // of finance.status is deferred to submission
  try {
    validationSchema.validateSync(values)
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      helpers.preventTransition()
      helpers.setErrors(yupToFormErrors(err))
      return
    }

    throw err
  }
}
