import { TypedDocumentNode, useQuery } from '@apollo/client'
import {
  BaseStep,
  BUYER_TERMS_LINE,
  DraftOffer,
  ErrorReporting,
  FlowFormProvider,
  formatPhoneNumber,
  Offer,
  PRIVACY_POLICY_LINE,
  RecursiveStep,
  SaveDraftOffer,
  SaveDraftOfferInput,
  SubmitOffer,
  SubmitOfferInput,
  SubmitOfferInputBuyer,
  useAPIClient,
  useAuth,
  useFlowForm,
  useSMSVerificationFlow,
} from '@propps/client'
import {
  DropdownMenuItem,
  DropdownMenuItemStatus,
  StackNav,
  StackMain,
  TextPlaceholder,
} from '@propps/ui'
import { Form } from 'formik'
import gql from 'graphql-tag'
import { omit } from 'ramda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { match as Match, useHistory, useRouteMatch } from 'react-router-dom'

import { useStore } from '../../store'
import { useAnalytics } from '../analytics'
import { FrameContentLayout } from '../frame-content-layout'
import { useFrameTransport } from '../FrameTransport'
import { AmplitudeEventStepNames } from './amplitude-event-step-names'
import {
  OfferFormValues,
  OFFER_FORM_DEFAULT_VALUES,
  SIGNATURE_DEFAULT_VALUES,
} from './offer-form-values'
import {
  AddBuyerStep,
  AuthSMSValidateStep,
  BuyersStep,
  ConditionsStep,
  ConveyancerStep,
  FinanceStep,
  IdentityStep,
  OfferAmountStep,
  SettlementStep,
  SigningStep,
  SummaryStep,
  ViewableDocument,
} from './steps'
import {
  GetTermsQuery,
  GetTermsQueryVariables,
} from './__generated__/GetTermsQuery'
import { ListingOfferForm_Buyer } from './__generated__/ListingOfferForm_Buyer'
import { ListingOfferForm_Listing } from './__generated__/ListingOfferForm_Listing'

type BaseOfferFormStep<T> = {
  buttonLabel?: string
  hideMenu?: boolean
  menuLabel?: string
  hideButton?: boolean
  disableBack?: boolean
  render: (props: { id: string }) => React.ReactElement
} & BaseStep<T>

type OfferFormStep<T> = RecursiveStep<BaseOfferFormStep<T>>

export function ListingOfferForm({
  listing,
  buyer,
  match: baseMatch,
}: {
  listing: ListingOfferForm_Listing | null
  buyer: ListingOfferForm_Buyer
  match: Match<{ appId: string; foreignListingId: string }>
}) {
  const store = useStore()
  const transport = useFrameTransport()
  const client = useAPIClient()
  const auth = useAuth()
  const analytics = useAnalytics()
  const history = useHistory<{
    currentOffer?: Offer | null
    draft?: DraftOffer | null
  }>()
  const smsVerification = useSMSVerificationFlow()

  const { data } = useQuery(GET_TERMS_QUERY, {
    variables: {
      termsLineName: BUYER_TERMS_LINE,
      privacyPolicyLineName: PRIVACY_POLICY_LINE,
    },
  })

  // capture the location state on first mount, as subsequent route changes
  // may overwrite the state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialLocationState = useMemo(() => history.location.state, [])
  const draft = initialLocationState?.draft
  const currentOffer = initialLocationState?.currentOffer

  const documents: ViewableDocument[] = useMemo(
    () => [
      ...(listing?.documents || []).map((document) => ({
        name: document.name,
        displayName: getDocumentDisplayName(document.name),
        url: document.url,
      })),
      {
        displayName: 'Propps Offer Terms and Conditions',
        name: 'tnc',
        url: data?.terms
          ? `${process.env.PUBLIC_URL}/terms/${BUYER_TERMS_LINE}/${data.terms.currentRevision.id}`
          : '#',
      },
    ],
    [data, listing]
  )

  const isUpdatingOffer = !!currentOffer
  const [buyers, setBuyers] = useState(1)

  const steps: OfferFormStep<any>[] = useMemo(
    () =>
      [
        {
          id: 'offer-amount',
          amplitudeEvent: { step: AmplitudeEventStepNames.OFFER_AMOUNT },
          render: ({ id }) => (
            <OfferAmountStep
              id={id}
              priceIndication={listing?.priceIndication ?? null}
            />
          ),
          menuLabel: 'Offer amount',
        },
        {
          id: 'settlement',
          amplitudeEvent: { step: AmplitudeEventStepNames.SETTLEMENT },
          render: ({ id }) => <SettlementStep id={id} />,
          menuLabel: 'Settlement',
        },
        {
          id: 'conditions',
          amplitudeEvent: { step: AmplitudeEventStepNames.CONDITIONS },
          render: ({ id }) => (
            <ConditionsStep
              id={id}
              conditions={
                listing?.conditions.map((line) => line.currentRevision) || null
              }
            />
          ),
          menuLabel: 'Conditions',
        },
        {
          id: 'finance',
          amplitudeEvent: { step: AmplitudeEventStepNames.FINANCE },
          render: ({ id }) => <FinanceStep id={id} />,
          menuLabel: 'Finance',
        },
        {
          id: 'conveyancer',
          amplitudeEvent: { step: AmplitudeEventStepNames.CONVEYANCER },
          render: ({ id }) => <ConveyancerStep id={id} />,
          menuLabel: 'Legal',
        },
        {
          id: 'buyers',
          amplitudeEvent: { step: AmplitudeEventStepNames.BUYERS },
          render: ({ id }) => <BuyersStep id={id} />,
          menuLabel: 'Buyers',
          children: [
            {
              id: 'add-buyer',
              amplitudeEvent: { step: AmplitudeEventStepNames.ADD_BUYER },
              render: ({ id }) => (
                <AddBuyerStep id={id} smsVerification={smsVerification} />
              ),
            },
            ...flatten<OfferFormStep<any>>(
              Array(buyers)
                .fill(null)
                .map((_, i): OfferFormStep<any>[] => [
                  {
                    id: i + '-auth-sms-validate',
                    amplitudeEvent: {
                      step: AmplitudeEventStepNames.ADDITIONAL_SMS_VALIDATE,
                    },
                    render: ({ id }) => (
                      <AuthSMSValidateStep
                        id={id}
                        buyerIndex={i}
                        smsVerification={smsVerification}
                      />
                    ),
                    previous: ['buyers', 'add-buyer'],
                  },
                  {
                    id: i + '-identity',
                    amplitudeEvent: {
                      step: AmplitudeEventStepNames.IDENTITY,
                      buyerIndex: i.toString(),
                    },
                    render: ({ id }) => <IdentityStep id={id} buyerIndex={i} />,
                    menuLabel: 'Identity',
                    next: 'buyers',
                    previous: 'buyers',
                    buttonLabel: 'Done',
                  },
                ])
            ),
          ],
        },
        {
          id: 'summary',
          amplitudeEvent: { step: AmplitudeEventStepNames.SUMMARY },
          render: ({ id }) => (
            <SummaryStep
              id={id}
              address={listing?.property.address.line1 ?? <TextPlaceholder />}
              documents={documents}
              isUpdatingOffer={isUpdatingOffer}
              legallyBinding={listing?.legallyBindingOffersAllowed ?? false}
            />
          ),
          menuLabel: 'Review and send',
          buttonLabel: 'Send offer',
          children: [
            ...flatten(
              Array(buyers)
                .fill(null)
                .map(
                  (_, i) =>
                    [
                      {
                        id: i + '-signing',
                        amplitudeEvent: {
                          step: AmplitudeEventStepNames.SIGNING,
                          buyerIndex: i.toString(),
                        },
                        render: ({ id }) => (
                          <SigningStep
                            id={id}
                            buyerIndex={i}
                            documents={documents}
                          />
                        ),
                        previous: 'summary',
                        next: 'summary',
                        buttonLabel: 'Done',
                      },
                    ] as OfferFormStep<any>[]
                )
            ),
          ],
        },
      ] as OfferFormStep<any>[],
    [buyers, listing, smsVerification, documents, isUpdatingOffer]
  )

  const initialValues: OfferFormValues = useMemo(() => {
    if (currentOffer) {
      return mapCurrentOfferToValues(currentOffer)
    }
    if (draft) {
      return unpackDraftOffer(draft)
    }

    return {
      ...OFFER_FORM_DEFAULT_VALUES,
      buyers: {
        ...OFFER_FORM_DEFAULT_VALUES.buyers,
        primaryBuyerId: buyer.id,
        signatories: [
          {
            ...OFFER_FORM_DEFAULT_VALUES.buyers.signatories[0],
            id: buyer.id,
            phone: buyer.phone
              ? formatPhoneNumber(buyer.phone, { defaultCountry: 'AU' })
              : '',
            firstName: buyer.firstName,
            lastName: buyer.lastName,
            middleName: '',
            email: buyer.email,
          },
        ],
      },
    }
  }, [buyer, draft, currentOffer])

  const initialStepPath = useMemo(() => {
    if (currentOffer) {
      return ['summary'] // Go directly to summary page for current offer.
    }
    if (draft) {
      return draft?.lastVisitedStepPath.slice(0, 1)
    }
    return undefined
  }, [draft, currentOffer])

  const form = useFlowForm<OfferFormValues, OfferFormStep<any>>({
    steps,
    initialStepPath,
    initialValues,
    onSnapshot: (path, snapshot) => {
      if (listing) {
        client.request(SaveDraftOffer, {
          input: packDraftOffer(snapshot, {
            listingId: listing.id,
            lastVisitedStepPath: path,
          }),
        })
      }
    },
    onSubmit: async (values, actions) => {
      if (!listing || !data?.terms || !data?.privacyPolicy) {
        actions.setSubmissionError(
          "Hmm, we've run into some trouble submitting your offer. It's best you reach out to us at help@propps.com"
        )
        actions.setSubmitting(false)
        return
      }

      analytics.logAmplitudeEvent({
        name: 'submit offer form',
        listingId: listing.id,
        appId: listing.source.appId,
        foreignListingId: listing.source.foreignId,
      })

      let offer
      try {
        offer = await client.request(SubmitOffer, {
          input: packSubmitOfferInput(values, {
            listingId: listing.id,
            termsAndConditionsRevisionId: data.terms.currentRevision.id,
            privacyPolicyRevisionId: data.privacyPolicy.currentRevision.id,
          }),
        })
      } catch (err) {
        console.error(err)
        ErrorReporting.report(err)

        actions.setSubmissionError(
          "Hmm, we've run into some trouble submitting your offer. It's best you reach out to us at help@propps.com"
        )
        return
      } finally {
        actions.setSubmitting(false)
      }

      analytics.logAmplitudeEvent({
        name: 'submitted offer',
        listingId: listing.id,
        appId: listing.source.appId,
        foreignListingId: listing.source.foreignId,
      })

      history.push(baseMatch.url + '/submitted', {
        currentOffer: offer,
      })
    },
  })
  const currentStepId = form.currentStep?.id || null

  useEffect(() => {
    setBuyers(form.formik.values.buyers.signatories.length)
  }, [form.formik.values.buyers.signatories.length])

  // Put the step id in the route.
  // Note that this is a one way binding, so any navigations will just be overwritten
  const match = useRouteMatch<{ stepId: string }>(baseMatch.path + '/:stepId')
  const matchParamsStepId = match?.params.stepId
  useEffect(() => {
    if (!currentStepId) return
    if (!match || matchParamsStepId !== currentStepId) {
      history.replace(baseMatch.url + '/' + currentStepId)
    }
  }, [baseMatch.url, match, history, matchParamsStepId, currentStepId])

  // Send the current step to amplitude
  useEffect(() => {
    if (listing && currentStepId && form.currentStep?.amplitudeEvent) {
      analytics.logAmplitudeEvent({
        name: 'view ' + form.currentStep?.amplitudeEvent.step,
        listingId: listing.id,
        appId: listing.source.appId,
        foreignListingId: listing.source.foreignId,
        ...omit(['step'])(form.currentStep!.amplitudeEvent),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, currentStepId, listing])

  const handleBack = useCallback(() => {
    // if updating an offer and we're on the summary page, the back button discards
    // any changes and goes back to the listing landing page
    if (currentOffer && form.currentStep && form.currentStep.id === 'summary') {
      history.replace(
        `/${baseMatch.params.appId}/listings/${baseMatch.params.foreignListingId}/offer/status`,
        {
          currentOffer: currentOffer,
        }
      )
    } else {
      form.previous()
    }
  }, [
    currentOffer,
    form,
    history,
    baseMatch.params.appId,
    baseMatch.params.foreignListingId,
  ])

  const close = useCallback(() => {
    transport.send({ type: 'close' })
  }, [transport])

  const logoutAndExit = useCallback(() => {
    if (listing) {
      client.request(SaveDraftOffer, {
        input: packDraftOffer(form.formik.values, {
          listingId: listing.id,
          lastVisitedStepPath: form.currentStepInfo.path,
        }),
      })
    }
    auth.signOut().then(() => close())
    analytics.logAmplitudeEvent({
      name: 'logout',
    })
  }, [
    analytics,
    auth,
    client,
    close,
    form.currentStepInfo.path,
    form.formik.values,
    listing,
  ])

  const menu: DropdownMenuItem[] = useMemo(() => {
    const getStatus = (id: string): DropdownMenuItemStatus => {
      const currentStepIndex = steps.findIndex(
        (step) => step.id === form.currentStepInfo.path[0]
      )
      const stepIndex = steps.findIndex((step) => step.id === id)

      return currentStepIndex > stepIndex
        ? 'done'
        : currentStepIndex === stepIndex
        ? 'active'
        : 'unvisited'
    }

    const logout = {
      label: 'Save and sign out',
      onClick: logoutAndExit,
    }
    return currentOffer
      ? [logout]
      : [
          ...steps.map((step) => {
            const status = getStatus(step.id)
            return {
              label: step.menuLabel,
              onClick: () => form.goToStep([step.id]),
              status: getStatus(step.id),
              disabled:
                status === 'unvisited' ||
                !!(form.currentStep && form.currentStep.id === 'summary'),
              hidden: step.hideMenu,
            }
          }),
          { sep: true },
          logout,
        ]
  }, [currentOffer, form, steps, logoutAndExit])

  const submitLabel = form.returnStepPath
    ? 'Done'
    : form.currentStep?.buttonLabel ?? 'Continue'

  return (
    <>
      <StackNav
        variant="frames"
        onBack={handleBack}
        showMenu={
          form.currentStep?.id !== 'auth-phone' &&
          form.currentStep?.id !== 'auth-sms-validate' &&
          !(form.currentStepInfo.path.length > 1) &&
          !form.currentStep?.hideMenu
        }
        showBack={
          !(!form.currentStepInfo.parentId && form.currentStepInfo.isFirst) &&
          !!form.currentStep &&
          !form.currentStep?.disableBack
        }
        menu={menu}
      />
      <StackMain variant="frames">
        <FlowFormProvider value={form}>
          <Form style={{ width: '100%' }}>
            <FrameContentLayout>
              {store.isVisible &&
                form.currentStep &&
                React.cloneElement(
                  form.currentStep.render({
                    id: form.currentStepInfo.path.join('/'),
                  }),
                  { key: form.currentStepInfo.path.join('/') }
                )}
              {form.currentStep && !form.currentStep.hideButton && (
                <FrameContentLayout.PrimaryAction
                  type="submit"
                  label={submitLabel}
                  pending={form.formik.isSubmitting}
                />
              )}
            </FrameContentLayout>
          </Form>
        </FlowFormProvider>
        <div
          ref={
            smsVerification.verifierContainerRef as React.RefObject<HTMLDivElement>
          }
        />
      </StackMain>
    </>
  )
}

ListingOfferForm.fragments = {
  Listing: gql`
    fragment ListingOfferForm_Listing on Listing {
      id
      legallyBindingOffersAllowed
      documents {
        name
        url
      }
      conditions {
        currentRevision {
          ...ConditionsStep_OfferConditionsRevision
        }
      }
      source {
        appId
        foreignId
      }
      priceIndication {
        type
        min
        max
        message
      }
      property {
        address {
          line1
        }
      }
    }
    ${ConditionsStep.fragments.OfferConditionsRevision}
  `,
  Buyer: gql`
    fragment ListingOfferForm_Buyer on Buyer {
      id
      firstName
      lastName
      phone
      email
    }
  `,
}

const GET_TERMS_QUERY: TypedDocumentNode<
  GetTermsQuery,
  GetTermsQueryVariables
> = gql`
  query GetTermsQuery(
    $termsLineName: String!
    $privacyPolicyLineName: String!
  ) {
    terms: termsLine(name: $termsLineName) {
      currentRevision {
        id
      }
    }
    privacyPolicy: termsLine(name: $privacyPolicyLineName) {
      currentRevision {
        id
      }
    }
  }
`

function getDocumentDisplayName(name: string) {
  switch (name) {
    case 'COS':
      return 'Contract of sale'
    default:
      return name
  }
}

function flatten<T>(arr: T[][]) {
  return arr.reduce((a, b) => a.concat(b), [] as T[])
}

export function packDraftOffer(
  values: OfferFormValues,
  params: {
    lastVisitedStepPath: string[]
    listingId: string
    draftId?: string
  }
): SaveDraftOfferInput {
  return {
    // NOTE not using a spread here to avoid passing additional unwanted properties
    buyers: {
      ...values.buyers,
      signatories: values.buyers.signatories.map((buyer) =>
        omit(['name', 'signature', 'smsVerificationCode'])(buyer)
      ),
    },
    settlement: values.settlement,
    conditions: values.conditions,
    legal: values.legal,
    finance: values.finance,
    offer: values.offer,
    agreeToUseOfDigitalSignatures: values.agreeToUseOfDigitalSignatures,
    id: params.draftId,
    listingId: params.listingId,
    lastVisitedStepPath: params.lastVisitedStepPath,
    legallyBinding: values.legallyBinding,
  }
}

function unpackDraftOffer(draft: DraftOffer): OfferFormValues {
  return {
    finance: draft.finance,
    offer: draft.offer,
    settlement: draft.settlement,
    conditions: draft.conditions,
    legal: draft.legal,
    agreeToUseOfDigitalSignatures: draft.agreeToUseOfDigitalSignatures,
    buyers: {
      ...draft.buyers,
      signatories: draft.buyers.signatories.map((buyer) => ({
        ...buyer,
        signature: SIGNATURE_DEFAULT_VALUES,
        smsVerificationCode: '',
      })),
    },
    addBuyerStep: OFFER_FORM_DEFAULT_VALUES.addBuyerStep,
    legallyBinding: draft.legallyBinding,
    agreeToNonBindingTnC: false,
  }
}

function packSubmitOfferInput(
  values: OfferFormValues,
  params: {
    listingId: string
    termsAndConditionsRevisionId: string
    privacyPolicyRevisionId: string
  }
): SubmitOfferInput {
  return {
    listing: params.listingId,
    agreeToUseOfDigitalSignatures: values.agreeToUseOfDigitalSignatures,
    termsAndConditionsRevisionId: params.termsAndConditionsRevisionId,
    legallyBinding: values.legallyBinding,
    privacyPolicyRevisionId: params.privacyPolicyRevisionId,
    offer: values.offer,
    settlement: values.settlement,
    conditions: values.conditions,
    finance: values.finance,
    legal: values.legal,
    buyers: {
      ...values.buyers,
      signatories: values.buyers.signatories.map((buyer) => ({
        ...omit(['smsVerificationCode'])(buyer),
        name: [buyer.firstName, buyer.middleName, buyer.lastName]
          .filter((name) => !!name)
          .join(' '),
        representing: {
          ...buyer.representing,
          // assert that buyer.representing is one of the allowed values
          type: buyer.representing
            .type as SubmitOfferInputBuyer['representing']['type'],
        },
      })),
    },
  }
}

export function mapCurrentOfferToValues(offer: Offer): OfferFormValues {
  return {
    offer: {
      amount: offer.offer.amount,
      expiry: offer.offer.expiry,
    },
    buyers: {
      ...offer.buyers,
      signatories: offer.buyers.signatories.map((signatory) => ({
        ...omit(['verificationStatus'])(signatory),
        // note that the signatures have to be re-completed every time
        signature: SIGNATURE_DEFAULT_VALUES,
        smsVerificationCode: '',
        idvDisclaimerAccepted: true,
      })),
    },
    settlement: offer.offer.settlement,
    conditions: offer.offer.conditions,
    finance: offer.offer.finance,
    legal: offer.offer.legal,
    agreeToUseOfDigitalSignatures: true,
    addBuyerStep: OFFER_FORM_DEFAULT_VALUES.addBuyerStep,
    legallyBinding: offer.legallyBinding,
    agreeToNonBindingTnC: false,
  }
}
