import { getSnapshot, Instance, onSnapshot, types } from 'mobx-state-tree'
import React, { createContext, useContext, useEffect, useState } from 'react'

export const Store = types
  .model({
    appId: types.maybeNull(types.string),
    isVisible: types.optional(types.boolean, false),
    recaptchaEnabled: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    setAppId: (value: string) => {
      self.appId = value
    },
    setVisible: (value: boolean) => {
      self.isVisible = value
    },
    setRecaptchaEnabled: (value: boolean) => {
      self.recaptchaEnabled = value
    },
  }))

export const StoreContext = createContext<Instance<typeof Store> | null>(null)

export function StoreProvider({
  children,
  store,
}: {
  store: Instance<typeof Store>
  children?: React.ReactNode
}) {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export function useStoreContext() {
  const store = useContext(StoreContext)

  if (process.env.NODE_ENV !== 'production') {
    if (!store) {
      throw new Error(
        'useStoreContext can only be called within a <StoreContext>'
      )
    }
  }

  return store!
}

export function useStore() {
  const store = useStoreContext()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [snapshot, setSnapshot] = useState(getSnapshot(store))

  useEffect(() => {
    setSnapshot(getSnapshot(store))

    onSnapshot(store, (snapshot) => {
      setSnapshot(snapshot)
    })
  }, [store])

  return store
}
