import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'

import { color } from '../styles/colors'
import { body, fontFamily } from '../styles/typography'
import { size } from '../styles/sizes'
import spinner1 from '../images/loading-hand1.png'
import spinner2 from '../images/loading-hand2.png'
import loadingLogo from '../images/loading-logo.gif'

type ButtonStyleProps = {
  primary?: boolean
  cta?: boolean
  danger?: boolean
  fixed?: boolean
  small?: boolean
  xsmall?: boolean
  pending?: boolean
  pending2?: boolean
}

const style = ({
  primary,
  cta,
  danger,
  fixed,
  small,
  xsmall,
  pending,
  pending2,
}: ButtonStyleProps) => css`
  ${body.m}
  user-select: none;
  display: inline-block;
  line-height: ${size(3)};
  padding: 10px ${size(2)};
  border-radius: ${size(1)};
  border: none;
  transition: 0.2s all ease-out, 0s color linear, 0s text-indent linear;
  cursor: pointer;
  font-weight: normal;
  background: ${color.xxxLightGrey};
  color: ${color.black};
  outline: none;
  pointer-events: all;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  &:hover,
  &:active,
  &:focus {
    background: ${color.xxLightGrey};
  }

  &:active,
  &:focus {
    background: ${color.white};
    box-shadow: 0 0 0 2px ${color.black};
  }

  ${primary &&
  css`
    background: ${color.black};
    color: ${color.white};

    &:hover,
    &:active,
    &:focus {
      background: ${color.xxxDarkGrey};
    }
  `}

  ${cta &&
  css`
    & {
      box-sizing: border-box;
      padding: ${size(2)} ${size(4)};
      border-radius: 28px;
      /* border: 3px solid ${color.black}; */
      ${fontFamily.sans}
      line-height: ${size(3)};
      font-weight: bold;
      color: ${color.white};
      letter-spacing: 0.01rem;
      text-align: center;
      min-width: 150px;
      background: ${color.black};
      /* background: linear-gradient(
          0deg,
          ${color.xDarkGrey} 0%,
          ${color.black} 100%
        ); */
    }

    &:hover,
    &:active,
    &:focus {
      /* border-color: ${color.black}; */
      background: ${color.black};

      /* background: linear-gradient(
          0deg,
          ${color.darkGrey} 0%,
          ${color.black} 100%
        ); */
    }

    &:active,
    &:focus {
      box-shadow: 0 0 0 2px ${color.white}, 0 0 0 4px ${color.black};
    }
  `}

  ${danger &&
  css`
    background-color: ${color.red};
    color: ${color.white};

    &:hover,
    &:active,
    &:focus {
      background: hsla(344, 61%, 44%, 0.8);
    }
  `}

  ${fixed &&
  css`
    min-width: 160px;
    text-align: center;
    position: fixed;
    bottom: ${size(4)};
    box-shadow: 0 ${size(2)} ${size(4)} 0 ${color.black20};
  `}

  ${small &&
  css`
    ${body.s}
    display: inline-flex;
    padding: ${size(1)} 12px;
    line-height: ${size(2)};
    border-radius: 4px;
    height: auto;
  `}

  ${xsmall &&
  css`
    ${body.xs}
    display: inline-flex;
    padding: 4px 12px;
    line-height: ${size(2)};
    border-radius: 12px;
    height: auto;
  `}

    ${pending &&
  css`
    color: rgba(0, 0, 0, 0);
    text-indent: -9999px;
    width: auto;
    text-align: center;
    transition: 0.2s all ease-out;
    transition-delay: 0.2s;

    & {
      opacity: 1;
      min-width: ${size(
        14
      )} !important; /* competing with a nested selector in FixedButtonWrapper */
      width: ${size(14)};
      max-width: ${size(14)};
      padding: ${size(2)} 0px;
      color: rgba(255, 255, 255, 0);
    }

    &::after {
      background: url(${loadingLogo});
      content: '';
      position: absolute;
      left: 35px;
      top: 20px;
      width: 45px;
      height: 15px;
      background-size: 45px;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0;
      animation: fadeInLoadingLogo 0.4s ease-out forwards;

      @keyframes fadeInLoadingLogo {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  `}

      ${pending2 &&
  css`
    color: rgba(0, 0, 0, 0);
    text-indent: -9999px;
    width: auto;

    & {
      opacity: 1;
      min-width: ${size(
        7
      )} !important; /* competing with a nested selector in FixedButtonWrapper */
      width: ${size(7)};
      max-width: ${size(7)};
      padding: ${size(2)} 0px;
      color: rgba(255, 255, 255, 0);
    }

    &::before {
      background: url(${spinner1});
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: ${size(6)};
      background-repeat: no-repeat;
      background-position: center;
      animation: ${Rotate} 12s infinite linear, ${FadeIn} 1s;
    }

    &::after {
      background: url(${spinner2});
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: ${size(6)};
      background-repeat: no-repeat;
      background-position: center;
      animation: ${Rotate} 1s infinite linear, ${FadeIn} 1s;
    }
  `}
`

export const StyledButton = styled.button<ButtonStyleProps>`
  ${(props) => style(props)}
`

export const StyledAnchorButton = styled.a<ButtonStyleProps>`
  ${(props) => style(props)}
`

export const FixedButtonWrapper = styled.div<{ position?: string }>`
  display: flex;
  position: ${({ position }) => position ?? 'fixed'};
  pointer-events: none;
  left: 0;
  bottom: 0;
  width: 100%;
  justify-content: center;
  margin-bottom: ${size(3)};
  z-index: 1;

  @media (max-width: 414px) and (min-height: 736px) {
    margin-bottom: ${size(4)}; /* Larger gap for iPhone X home bar */
  }

  & button {
    min-width: 160px;
  }
`

const Rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }
`
