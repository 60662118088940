import React from 'react'
import styled from '@emotion/styled'

import { size } from '../styles/sizes'
import { zIndex } from '../styles/helpers'

const NotificationsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  position: fixed;
  box-sizing: border-box;
  width: 100vw;
  max-width: 420px;
  max-height: calc(100vh - 5vw);
  overflow-y: auto;
  top: 0;
  right: 0;
  margin: 0;
  list-style: none;
  padding: 2vw 2vw ${size(3)} ${size(3)};
  pointer-events: none;
  overflow-x: hidden;
  z-index: ${zIndex.stackNotification};

  @media (max-width: 500px) {
    padding: 5vw;
  }

  &:empty {
    display: none;
  }

  > li {
    animation: fadeIn 0.3s ease-in-out forwards;
    opacity: 0;
    transform: translateY(${size(2)});

    &.fadeOut {
      animation: fadeOut 0.6s ease-in-out forwards;
      animation-delay: 0s !important;
    }

    @keyframes fadeOut {
      0% {
        opacity: 1;
        transform: translateX(0);
      }
      50% {
        opacity: 0;
        transform: translateX(420px);
      }
      51% {
        height: 100px;
        padding: ${size(2)};
        margin-bottom: ${size(2)};
      }
      100% {
        height: 0;
        padding: 0;
        margin-bottom: 0;
      }
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(${size(2)});
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
    }
    &:nth-child(5) {
      animation-delay: 0.8s;
    }
    &:nth-child(6) {
      animation-delay: 1s;
    }
    &:nth-child(7) {
      animation-delay: 1.2s;
    }
    &:nth-child(8) {
      animation-delay: 1.4s;
    }
    &:nth-child(9) {
      animation-delay: 1.6s;
    }
  }
`

const Notifications = ({ ...props }) => {
  return <NotificationsWrapper>{props.children}</NotificationsWrapper>
}

export default Notifications
