import { gql, TypedDocumentNode, useQuery } from '@apollo/client'
import {
  AuthGuardRedirect,
  DraftOffer,
  Offer,
  Role,
  RoleRecord,
} from '@propps/client'
import { complement } from 'ramda'
import React, { useEffect, useState } from 'react'
import {
  match as Match,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom'

import { BuyerAuthFlow } from '../../components/buyer-auth-flow'
import { WelcomePage } from '../../components/listing-landing'
import { ListingOfferForm } from '../../components/offer-form'
import { OfferStatusPage } from '../../components/offer-status'
import { Listing404 } from './Listing404'
import { Preroll } from './Preroll'
import { Submitted } from './Submitted'
import {
  ListingRouteQuery,
  ListingRouteQueryVariables,
} from './__generated__/ListingRouteQuery'

export function ListingRoute({
  match: base,
}: {
  match: Match<{ foreignListingId: string; appId: string }>
}) {
  const history = useHistory()
  const { appId, foreignListingId } = base.params

  const { data, error } = useQuery(LISTING_ROUTE_QUERY, {
    variables: {
      appId: appId,
      foreignId: foreignListingId,
    },
    fetchPolicy: 'cache-and-network',
  })

  // Change the timeout to adjust the amount of time the
  // route can be blank while loading
  const forceShow = useTimeout(750)

  const isRegistered = ({
    uid,
    role,
  }: {
    uid: string | null
    role: RoleRecord | null
  }) => {
    return role?.name === Role.BUYER
  }

  const listing = data?.developerApp?.listing

  return (
    <>
      {error ? (
        <Listing404 />
      ) : data || forceShow ? (
        <Switch>
          <Route path={base.path + '/preroll'} exact>
            {() => (
              <Preroll
                listing={listing ?? null}
                continueTo={base.url + '/auth'}
              />
            )}
          </Route>
          <Route path={base.path + '/offer/submitted'} exact>
            {() => <Submitted />}
          </Route>
          <Route path={base.path} exact>
            {({ match }) => (
              <AuthGuardRedirect
                allow={complement(isRegistered)}
                to={base.url + '/welcome'}
              >
                <Redirect to={base.url + '/preroll'} />
              </AuthGuardRedirect>
            )}
          </Route>
          <Route path={base.path + '/welcome'} exact>
            {() => (
              <AuthGuardRedirect allow={isRegistered} to={base.url}>
                <WelcomePage
                  listing={listing ?? null}
                  goToOfferForm={(draft: DraftOffer | null) => {
                    history.push(base.url + '/offer', {
                      draft: draft,
                      currentOffer: null,
                    })
                  }}
                  goToOfferStatus={(offer: Offer) => {
                    history.push(base.url + '/offer/status', {
                      currentOffer: offer,
                    })
                  }}
                />
              </AuthGuardRedirect>
            )}
          </Route>
          <Route path={base.path + '/offer/status'} exact>
            {({ match }) => (
              <AuthGuardRedirect allow={isRegistered} to={base.url}>
                <OfferStatusPage listing={listing ?? null} match={match!} />
              </AuthGuardRedirect>
            )}
          </Route>
          <Route path={base.path + '/auth'}>
            <AuthGuardRedirect allow={complement(isRegistered)} to={base.url}>
              <BuyerAuthFlow
                listing={listing ?? null}
                onComplete={(isNewBuyer) => {
                  history.push(base.url + (isNewBuyer ? '/offer' : '/welcome'))
                }}
                onBackToPreroll={() =>
                  history.push(
                    '/' + appId + '/listings/' + foreignListingId + '/preroll'
                  )
                }
              />
            </AuthGuardRedirect>
          </Route>
          <Route path={base.path + '/offer'}>
            {({ match }) => (
              <AuthGuardRedirect allow={isRegistered} to={base.url}>
                {data?.me?.buyer ? (
                  <ListingOfferForm
                    listing={data?.developerApp?.listing ?? null}
                    buyer={data.me.buyer}
                    match={match!}
                  />
                ) : null}
              </AuthGuardRedirect>
            )}
          </Route>
        </Switch>
      ) : (
        <Loading />
      )}
    </>
  )
}

function Loading() {
  return null
}

function useTimeout(ms: number) {
  const [elapsed, setElapsed] = useState(false)

  useEffect(() => {
    let cancelled = false
    setTimeout(() => {
      if (!cancelled) {
        setElapsed(true)
      }
    }, ms)

    return () => {
      cancelled = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return elapsed
}

const LISTING_ROUTE_QUERY: TypedDocumentNode<
  ListingRouteQuery,
  ListingRouteQueryVariables
> = gql`
  query ListingRouteQuery($appId: ID!, $foreignId: ID!) {
    developerApp(id: $appId) {
      listing(foreignId: $foreignId) {
        id
        ...ListingOfferForm_Listing
        ...Preroll_Listing
        ...OfferStatusPage_Listing
        ...BuyerAuthFlow_Listing
      }
    }
    me {
      buyer {
        ...ListingOfferForm_Buyer
      }
    }
  }

  ${ListingOfferForm.fragments.Listing}
  ${Preroll.fragments.Listing}
  ${OfferStatusPage.fragments.Listing}
  ${BuyerAuthFlow.fragments.Listing}
  ${ListingOfferForm.fragments.Buyer}
`
