import styled from '@emotion/styled'
import { Offer } from '@propps/client'
import { fontSize, HeroTick, Icon, size, StackMain, StackNav } from '@propps/ui'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { FrameContentLayout } from '../../components/frame-content-layout'

const Message = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  img {
    filter: grayscale(100%);
    margin-bottom: ${size(1)};
  }

  svg {
    width: ${size(12)};
    height: ${size(12)};
  }

  p {
    max-width: 290px;
    font-size: ${fontSize.s};
    color: #7f7f7f;
  }
`

const H2 = styled.h2`
  margin-bottom: ${size(2)};

  > div {
    font-weight: normal;
    font-size: ${fontSize.xxl};
  }
`

export function Submitted() {
  const history = useHistory<{
    currentOffer?: Offer | null
  }>()
  // capture the location state on first mount, as subsequent route changes
  // may overwrite the state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialLocationState = useMemo(() => history.location.state, [])
  const currentOffer = initialLocationState?.currentOffer

  const match = useRouteMatch<{ foreignListingId: string; appId: string }>()

  if (!match.params.foreignListingId) {
    throw new Error(
      '<Submitted> must be rendered in a route with a `foreignListingId` parameter'
    )
  }

  if (!match.params.appId) {
    throw new Error(
      '<Submitted> must be rendered in a route with an `appId` parameter'
    )
  }

  let [fingersCrossed, updateField] = useState('')

  useEffect(() => {
    /** State change is used to trigger a react DOM update so the component can
     * re-render in ios safari TODO: can solve with animations */
    async function loadData() {
      await delay(50)
      updateField('Fingers crossed!')
    }

    loadData()
  }, [])

  return (
    <>
      <StackNav variant="frames" />
      <StackMain variant="topCenter">
        <FrameContentLayout>
          <Message>
            <Icon size={128} svg={HeroTick} />
            <H2>
              Offer sent
              <div>{fingersCrossed}</div>
            </H2>
            <p className="small">
              We’ve sent your offer to the agent and a copy to your email for
              your records.
              <br />
              {fingersCrossed}
            </p>
          </Message>
          <FrameContentLayout.PrimaryAction
            label="View offer status"
            onClick={() => {
              history.push(
                '/' +
                  match.params.appId +
                  '/listings/' +
                  match.params.foreignListingId +
                  '/offer/status',
                {
                  currentOffer: currentOffer,
                }
              )
            }}
          />
        </FrameContentLayout>
      </StackMain>
    </>
  )
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
