/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import color from '../styles/colors'
import { size } from '../styles/sizes'
import { fontSize } from '../styles/typography'
import Flex from './Flex'
import FlexItem from './FlexItem'
import Icon, {
  CircleCross2,
  CircleInfo2,
  CircleTick2,
  CircleWarning2,
  Cross,
} from './icons'

const getColor = (variant?: AlertProps['variant']) => {
  if (variant === 'info') {
    return color.blue
  } else if (variant === 'success') {
    return color.green
  } else if (variant === 'warning') {
    return color.orange
  } else if (variant === 'danger') {
    return color.red
  }
}

const flex = css`
  margin-left: -6px;
  margin-right: -6px;
`

const flexItem = css`
  padding-left: 6px;
  padding-right: 6px;
`

const AlertStyled = styled.div<{ variant?: AlertProps['variant'] }>`
  padding: ${size(1.75)} ${size(1.5)};
  margin: ${size(3)} 0;
  border-radius: ${size(1)};
  color: ${color.white};
  font-size: ${fontSize.s};
  line-height: ${fontSize.l};

  ${({ variant }) =>
    css`
      background-color: ${getColor(variant)};
    `};

  a {
    color: inherit;
    text-decoration-color: currentColor;
  }
`

const icon = css`
  color: inherit;
`

const heading = css`
  font-size: ${fontSize.s};
  line-height: ${fontSize.l};
  margin-bottom: ${size(0.5)};
`

const copy = css`
  margin: 0;
`

const Button = styled.button<{ variant?: AlertProps['variant'] }>`
  padding: 0;
  border: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
  transition: color 0.2s ease-out;

  svg {
    border-radius: 50%;
    transition: background-color 0.2s ease-out;
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    ${({ variant }) => css`
      color: ${getColor(variant)};
    `};

    svg {
      background-color: ${color.white};
    }
  }
`

export interface AlertProps {
  isOpen?: boolean
  closable?: boolean
  variant?: 'info' | 'success' | 'warning' | 'danger'
  customIcon?: React.ReactNode
  title?: string
  children: React.ReactNode
}

const Alert = ({
  isOpen = true,
  closable,
  variant = 'info',
  customIcon,
  title,
  children,
}: AlertProps) => {
  const [open, setOpenState] = useState(isOpen)

  const getIcon = (variant: AlertProps['variant']) => {
    if (customIcon) {
      return customIcon
    } else if (variant === 'info') {
      return <Icon svg={CircleInfo2} css={icon} fill="currentColor" />
    } else if (variant === 'success') {
      return <Icon svg={CircleTick2} css={icon} fill="currentColor" />
    } else if (variant === 'warning') {
      return <Icon svg={CircleWarning2} css={icon} fill="currentColor" />
    } else if (variant === 'danger') {
      return <Icon svg={CircleCross2} css={icon} fill="currentColor" />
    }
  }

  return !open ? null : (
    <AlertStyled variant={variant}>
      <Flex xs={{ wrap: 'nowrap' }} css={flex}>
        <FlexItem css={flexItem}>{getIcon(variant)}</FlexItem>
        <FlexItem xs={{ grow: true, align: 'center' }} css={flexItem}>
          {title && <h3 css={heading}>{title}</h3>}
          {typeof children === 'string' ? (
            <p css={copy}>{children}</p>
          ) : (
            children
          )}
        </FlexItem>
        {closable && (
          <FlexItem css={flexItem}>
            <Button
              aria-label="Close"
              onClick={() => setOpenState(!open)}
              type="button"
              variant={variant}
            >
              <Icon svg={Cross} size={16} fill="currentColor" />
            </Button>
          </FlexItem>
        )}
      </Flex>
    </AlertStyled>
  )
}

export default Alert
