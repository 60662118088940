import {
  BlurFormatInput,
  getErrors,
  StepSubmissionHelpers,
  useFlowFormStep,
} from '@propps/client'
import { Input, ListItem, ListWrapper, Select } from '@propps/ui'
import { isFuture, isPast, subDays } from 'date-fns'
import { FormikErrors, yupToFormErrors } from 'formik'
import isMobile from 'is-mobile'
import React from 'react'
import * as Yup from 'yup'

import { DateSchema, formatInputDate, parseDateString } from '../date-schema'
import { OfferFormValues } from '../offer-form-values'
import { prune } from '../prune'

const isMobileDevice = isMobile()

const standardSettlementDays = ['30', '45', '60', '90', '120']

export function SettlementStep({ id }: { id: string }) {
  const { formik } = useFlowFormStep<OfferFormValues>({
    id,
    validate,
    onSubmit: handleSubmit,
  })

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectValue = e.target.value.trim()
    formik.handleChange(e)
    formik.setFieldValue('settlement.value', '')
    if (standardSettlementDays.includes(selectValue)) {
      formik.setFieldValue('settlement.value', selectValue)
    }
  }

  return (
    <>
      <h2>When would you prefer the settlement date to be?</h2>
      <ListWrapper>
        <ListItem>
          <label>Settlement</label>
          <Select
            autoFocus={!isMobileDevice}
            {...formik.getFieldProps('settlement.type')}
            errors={getErrors(formik, 'settlement.type')}
            onChange={onSelectChange}
          >
            <optgroup label="From signing">
              <option value="" disabled>
                ...
              </option>
              {standardSettlementDays.map((value) => {
                return (
                  <option value={value} key={value}>
                    {value} days from signing
                  </option>
                )
              })}
              <option value="customNoOfDays">Specific no. of days</option>
              <option value="customDate">By date</option>
            </optgroup>
          </Select>
        </ListItem>

        {formik.values.settlement.type === 'customNoOfDays' && (
          <ListItem>
            <label>Duration</label>
            <BlurFormatInput<React.ComponentProps<typeof Input>>
              component={Input}
              type="number"
              placeholder="..."
              {...formik.getFieldProps('settlement.value')}
              errors={getErrors(formik, 'settlement.value')}
              format={formatDays}
            />
          </ListItem>
        )}
        {formik.values.settlement.type === 'customDate' && (
          <ListItem>
            <label>Settlement date</label>
            <BlurFormatInput<React.ComponentProps<typeof Input>>
              component={Input}
              type="text"
              placeholder="DD / MM / YYYY"
              {...formik.getFieldProps('settlement.value')}
              errors={getErrors(formik, 'settlement.value')}
              format={(value) => formatInputDate(value)}
              onBlur={(event) => {
                formik.setFieldValue(
                  'settlement.value',
                  formatInputDate(formik.values.settlement.value)
                )
                formik.handleBlur(event)
              }}
            />
          </ListItem>
        )}
      </ListWrapper>
      <p className="grey">
        This is when the transaction will occur, funds are transferred and you
        can take possession of the property.
      </p>
    </>
  )
}

const validationSchema = Yup.object().shape({
  settlement: Yup.object({
    type: Yup.string().oneOf(
      [...standardSettlementDays, 'customNoOfDays', 'customDate'],
      'Please enter a settlement option'
    ),
    value: Yup.string().when(
      'type',
      (type: string, schema: Yup.StringSchema) => {
        switch (type) {
          case 'customNoOfDays':
            return NumberRangeSchema.required(
              'Please enter the days less than 999'
            )
          case 'customDate':
            return FutureDateSchema.required('Please enter the settlement date')
          default:
            return schema.notRequired()
        }
      }
    ),
  }),
})

const NumberRangeSchema = Yup.string().test(
  'number-range',
  'The value must be between 0 and 999.',
  (value: string | undefined): value is string => {
    if (typeof value === 'undefined') return false
    let number = parseInt(value)
    return !isNaN(number) && number <= 999 && number > 0
  }
)

const FutureDateSchema = DateSchema.test(
  'date-future',
  'The date must be in the future.',
  (value: string | undefined): value is string =>
    !value || isFuture(parseDateString(value))
).test(
  'date-too-far',
  'The date must be within 999 days.',
  (value: string | undefined): value is string =>
    !value || isPast(subDays(parseDateString(value), 999))
)

const formatDays = (value: string) => {
  const number = parseInt(value)
  if (!isNaN(number)) {
    return `${number} days`
  }
  return value
}

function validate(values: OfferFormValues): FormikErrors<OfferFormValues> {
  try {
    validationSchema.validateSync(values)
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      // to avoid errors on the select input thrashing, validation
      // of settlement.type is deferred to submission
      return prune(['settlement', 'type'], yupToFormErrors(err))
    }
  }

  return {}
}

function handleSubmit(
  values: OfferFormValues,
  helpers: StepSubmissionHelpers<OfferFormValues>
) {
  // to avoid errors on the select input thrashing, validation
  // of settlement.type is deferred to submission
  try {
    validationSchema.validateSync(values)
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      helpers.preventTransition()
      helpers.setErrors(yupToFormErrors(err))
      return
    }

    throw err
  }
}
