/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Card,
  Clock,
  Flex,
  FlexItem,
  fontSize,
  Icon,
  size,
  StackMain,
  StackNav,
  TextPlaceholder,
  TooltipCard,
  UserPassport,
} from '@propps/ui'
import gql from 'graphql-tag'
import { Fragment, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { useAnalytics } from '../../components/analytics'
import { FrameContentLayout } from '../../components/frame-content-layout'
import { NonLegallyBindingWarningCard } from '../../components/NonLegallyBindingWarning'
import { AmplitudeEventStepNames } from '../../components/offer-form/amplitude-event-step-names'
import { ReactComponent as PerollBanner } from '../../images/preroll-banner.svg'
import { Preroll_Listing } from './__generated__/Preroll_Listing'

const BannerContainer = styled.div`
  height: ${size(12)};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;

  svg {
    height: auto;
    max-width: 100%;
    transform: translateY(5%);
    z-index: 1;
    margin-left: 15%;
  }
`

const Gradient = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 256px;
  background-image: linear-gradient(to bottom, #f7f7f7 0%, #fff 100%);
`

const H2 = styled.h2`
  margin-bottom: ${size(2)};

  > div {
    font-weight: normal;
    font-size: ${fontSize.xxl};
  }
`

const H3 = styled.h3`
  font-size: ${fontSize.m};
  margin-bottom: ${size(2)};
  letter-spacing: 0;
`

const styledCard = css`
  height: 100%;
  text-align: center;
`

const CardTitle = styled.span`
  display: block;
  margin-top: ${size(1)};
`

const TooltipCardContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: ${size(1)};
  }
`

export const Preroll = ({
  listing,
  continueTo,
}: {
  listing: Preroll_Listing | null
  continueTo: string
}) => {
  const history = useHistory()
  const match = useRouteMatch<{ foreignListingId: string; appId: string }>()
  const analytics = useAnalytics()
  useEffect(() => {
    if (match) {
      analytics.logAmplitudeEvent({
        name: AmplitudeEventStepNames.WELCOME,
        listingId: match.params.foreignListingId ?? '',
        appId: match.params.appId,
        foreignListingId: match.params.foreignListingId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!match.params.foreignListingId) {
    throw new Error(
      '<Preroll> must be rendered in a route with a `foreignListingId` parameter'
    )
  }

  if (!match.params.appId) {
    throw new Error(
      '<Preroll> must be rendered in a route with an `appId` parameter'
    )
  }

  const tooltipCards = [
    {
      label: 'Offer amount and settlement',
      tooltip: '',
    },
    {
      label: 'Conditions with your offer',
      tooltip: '',
    },
    {
      label: 'Finance',
      tooltip: '',
    },
    {
      label: 'Legal',
      tooltip: '',
    },
    {
      label: 'People you’re buying with and/or for',
      tooltip:
        'We’ll help you have multiple signatories with your offer, make an offer on behalf of someone or allow you to nominate people later.',
    },
    {
      label: 'Reviewing documents',
      tooltip: '',
    },
    {
      label: 'Digital signatures',
      tooltip: '',
    },
  ]

  return (
    <Fragment>
      <StackNav
        variant="frames"
        showSignIn
        onSignIn={() =>
          history.push(
            '/' +
              match.params.appId +
              '/listings/' +
              match.params.foreignListingId +
              '/auth'
          )
        }
      />
      <BannerContainer>
        <PerollBanner />
        <Gradient />
      </BannerContainer>

      <StackMain variant="frames">
        <FrameContentLayout>
          <H2>
            {listing?.legallyBindingOffersAllowed ? 'Make' : 'Prepare'} an offer
            for <br />
            <div>{listing?.property.address.line1 || <TextPlaceholder />}</div>
          </H2>

          <p>
            Whether it’s your first time or you’re an expert, making an offer
            online is easy. You’ll just need:
          </p>

          <Flex
            css={css`
              margin-bottom: ${size(6)};
            `}
          >
            <FlexItem xs={{ size: 3 }}>
              <Card size="lg" css={styledCard}>
                <Icon svg={Clock} size={48} />
                <CardTitle>About 10 minutes</CardTitle>
              </Card>
            </FlexItem>
            <FlexItem xs={{ size: 3 }}>
              <Card size="lg" css={styledCard}>
                <Icon svg={UserPassport} size={48} />
                <CardTitle>Your license or passport</CardTitle>
              </Card>
            </FlexItem>
          </Flex>

          <H3>We’ll help you prepare your offer, including:</H3>

          {tooltipCards.map((tooltipCard) => (
            <TooltipCardContainer key={tooltipCard.label}>
              <TooltipCard label={tooltipCard.label}>
                {tooltipCard.tooltip}
              </TooltipCard>
            </TooltipCardContainer>
          ))}

          {!listing?.legallyBindingOffersAllowed ? (
            <NonLegallyBindingWarningCard />
          ) : null}

          <H3>Not sure if you’re ready to make an offer yet?</H3>

          <p>
            The best way to learn is by doing. Simply start creating an offer
            and we’ll guide you along the way.
          </p>

          <FrameContentLayout.PrimaryAction
            label="Continue"
            onClick={() => {
              history.push(continueTo)
            }}
          />
        </FrameContentLayout>
      </StackMain>
    </Fragment>
  )
}

Preroll.fragments = {
  Listing: gql`
    fragment Preroll_Listing on Listing {
      legallyBindingOffersAllowed
      property {
        address {
          line1
        }
      }
    }
  `,
}
