import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { size } from '../styles/sizes'

export const SectionWrapper = styled.section<{ size?: 'l' | 's' }>`
  margin: ${size(6)} 0 ${size(8)};

  ${(props) =>
    props.size === 's' &&
    css`
      margin: ${size(3)} 0 ${size(4)};
    `}

  ${(props) =>
    props.size === 'l' &&
    css`
      margin: ${size(8)} 0 ${size(12)};
    `}
`

type SectionProps = {
  size?: 'l' | 's'
} & Omit<React.HTMLProps<HTMLDivElement>, 'size' | 'as'>

const Section = React.forwardRef<HTMLDivElement, SectionProps>((props, ref) => {
  return (
    <SectionWrapper {...props} ref={ref}>
      {props.children}
    </SectionWrapper>
  )
})

export default Section
