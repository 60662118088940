import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import {
  Dialog as BaseDialog,
  DialogBackdrop as BaseDialogBackdrop,
  DialogDisclosure,
  DialogInitialState,
  useDialogState,
} from 'reakit'

import { screen } from '../styles/breakpoints'
import { color } from '../styles/colors.jsx'
import { zIndex } from '../styles/helpers.jsx'
import { size } from '../styles/sizes'
import { fontSize } from '../styles/typography'
import Icon, { Cross } from '../elements/icons'

const Dialog = styled(BaseDialog)<{
  centered?: boolean
  size?: 's' | 'l'
}>`
  display: block;
  position: fixed;
  left: 50%;
  bottom: 0;
  outline: none;
  width: 512px;
  max-width: 100vw;
  border-radius: ${size(3)} ${size(3)} 0 0;
  box-shadow: 0 ${size(4)} ${size(8)} 0 ${color.black20};
  height: 480px;
  max-height: 80vh;
  overflow-y: hidden;
  z-index: ${zIndex.stackModal};
  background-color: ${color.white};
  transform: translate(-50%, ${size(16)});
  animation: 0.2s fadeInModal forwards;
  transition: opacity 0.2s ease-out, transform 0.4s ease-out;
  opacity: 0;

  &[data-enter] {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}

  ${({ size }) =>
    size === 's' &&
    css`
      width: 400px;
      height: 360px;
    `}

  h2,
  h3 {
    margin-bottom: ${size(3)};
  }

  p {
    margin-top: ${size(2)};
    margin-bottom: ${size(3)};
  }
`

const Title = styled.h2`
  margin-bottom: ${size(4)};
`

const Container = styled.div<{ cta?: boolean }>`
  height: 100%;
  padding: ${size(8)} ${size(8)} ${size(4)};
  overflow-y: auto;

  @media (max-width: 400px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  ${({ cta }) =>
    cta &&
    css`
      padding-bottom: ${size(14)};
    `}
`

const DialogBackdrop = styled(BaseDialogBackdrop)`
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: ${zIndex.stackModal - 1};

  &::before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: ${color.black20};
    opacity: 0;
    transition: opacity 0.6s ease;
  }

  &[data-enter]::before {
    opacity: 1;
  }
`

const Links = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  right: 20px;
  top: 20px;
  font-weight: bold;

  label {
    display: block;
    height: ${size(3)};
    overflow-y: hidden;
    line-height: 26px;
    text-transform: uppercase;
    font-size: ${fontSize.xs};
    letter-spacing: 0.1rem;
    font-weight: bold;
    color: ${color.black};

    @media ${screen.xs} {
      display: none;
    }
  }
`

const Close = styled.button`
  border-radius: ${size(1)};
  padding: ${size(1)};
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  border: none;
  background: none;
  transition: 0.2s all ease-out;
  appearance: none;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: ${size(1)};
    transition: 0.1s all ease-out;
  }

  &:hover {
    background: ${color.black10};
    z-index: 1;
  }

  &:focus,
  &:active {
    outline: none;

    &:before {
      box-shadow: 0 0 0 2px ${color.black}, inset 0 0 0 0 transparent;
    }
  }
`

export function useStackModalState(options?: DialogInitialState) {
  const state = useDialogState({ ...options, modal: true, animated: true })

  return state
}

type StackModalStateReturn = ReturnType<typeof useStackModalState>

type StackModalProps = {
  size?: 's' | 'l'
  centered?: boolean
  title?: React.ReactNode
  cta?: boolean
  children: React.ReactNode
  state: StackModalStateReturn
  noDismiss?: boolean
} & Omit<React.ComponentProps<typeof Dialog>, keyof StackModalStateReturn>

const StackModal = ({
  size,
  centered,
  title,
  cta,
  children,
  state,
  noDismiss,
  hideOnClickOutside,
  ...props
}: StackModalProps) => {
  return (
    <DialogBackdrop {...state}>
      <Dialog
        size={size}
        centered={centered}
        hideOnClickOutside={hideOnClickOutside}
        {...state}
        {...props}
      >
        <Container cta={cta}>
          {title && <Title>{title}</Title>}
          {children}
        </Container>
        {!noDismiss && (
          <Links>
            <Close onClick={() => state.hide()} aria-label="Close">
              <Icon svg={Cross} size={24} />
            </Close>
          </Links>
        )}
      </Dialog>
    </DialogBackdrop>
  )
}

StackModal.Disclosure = DialogDisclosure

export default StackModal
