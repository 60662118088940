import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { color } from '../styles/colors'

export const Breadcrumb = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Level = styled.li`
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  color: ${color.black};

  a {
    color: inherit;
    text-decoration: none;

    &:focus {
      outline: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`

export const Separator = styled.span`
  margin: 0 6px;
`

const StackBreadcrumb = ({
  levels,
}: {
  levels: (React.ReactElement<typeof Link> | string | null)[]
}) => {
  return (
    <Breadcrumb>
      {levels.map(
        (level, index) =>
          level && (
            <Level key={index}>
              {index !== 0 && <Separator>&#8226;</Separator>}
              {level}
            </Level>
          )
      )}
    </Breadcrumb>
  )
}

export default StackBreadcrumb
