import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { fontSize } from '../styles/typography'
import { size } from '../styles/sizes'

const EmojiWrapper = styled.div`
  font-size: ${fontSize.m};
  line-height: ${size(3)};

  ${(props) =>
    props.size === 'l' &&
    css`
      font-size: ${fontSize.l};
    `}
`

const Emoji = ({ character, ...props }) => {
  return (
    <EmojiWrapper size={props.size} {...props}>
      {character || '😀'}
    </EmojiWrapper>
  )
}

export default Emoji
