import { formatPhoneNumber, Offer, OfferBuyer, useAuth } from '@propps/client'
import {
  Button,
  FixedButtonWrapper,
  OfferCard,
  Section,
  SectionHeader,
  size,
  StackModal,
  StackNav,
  StackMain,
  UserCard,
  useStackModalState,
} from '@propps/ui'
import gql from 'graphql-tag'
import React, { Fragment, useCallback, useMemo } from 'react'
import { match as Match, useHistory } from 'react-router-dom'

import { FrameContentLayout } from '../frame-content-layout'
import { useFrameTransport } from '../FrameTransport'
import { OfferStatusPage_Listing } from './__generated__/OfferStatusPage_Listing'
import { NonLegallyBindingWarningCard } from '../NonLegallyBindingWarning'

export function OfferStatusPage({
  listing,
  match,
}: {
  listing: OfferStatusPage_Listing | null
  match: Match<{ appId: string; foreignListingId: string }>
}) {
  const modal = useStackModalState()
  const auth = useAuth()
  const transport = useFrameTransport()

  const history = useHistory<{
    currentOffer?: Offer | null
  }>()

  // capture the location state on first mount, as subsequent route changes
  // may overwrite the state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialLocationState = useMemo(() => history.location.state, [])
  const currentOffer = initialLocationState?.currentOffer

  const showWithdrawModal = () => {
    modal.show()
  }

  const goToStepWorkflow = useCallback(
    (currentOffer: Offer | null) => {
      history.push(match.url + '/offer', {
        currentOffer: currentOffer,
      })
    },
    [history, match.url]
  )

  return (
    <Fragment>
      <StackNav
        variant="frames"
        menu={[
          {
            label: 'Sign out',
            onClick: () => {
              auth.signOut()
              transport.send({ type: 'close' })
            },
          },
        ]}
      />
      <StackMain variant="frames">
        {currentOffer && (
          <FrameContentLayout>
            <h2 style={{ marginBottom: size(6) }}>
              Your offer status for
              <span className="light">{listing?.property.address.line1}</span>
            </h2>
            <Section>
              <SectionHeader hr2 h3="Current offer">
                <Button
                  xsmall
                  label="Withdraw"
                  onClick={() => showWithdrawModal()}
                />
              </SectionHeader>
              <OfferCard
                hoverless
                fullName={findPrimaryBuyer(currentOffer)?.name || ''}
                amount={currentOffer.offer.amount}
                timestamp={currentOffer.created}
                multipleBuyers={currentOffer.buyers.signatories.length > 1}
                leadingOffer={false}
                conditional={isConditionalOffer(currentOffer)}
                expiry={currentOffer.offer.expiry}
                onClick={() => console.log('do nothing for now')}
              />
            </Section>
            {!currentOffer.legallyBinding ? (
              <Section>
                <NonLegallyBindingWarningCard />
              </Section>
            ) : null}
            <FrameContentLayout.PrimaryAction
              label="Update offer"
              onClick={() => goToStepWorkflow(currentOffer)}
            />
          </FrameContentLayout>
        )}
      </StackMain>
      <StackModal cta size="s" centered state={modal} title="Withdraw offer">
        <p>Please contact the agent to withdraw your offer.</p>
        {listing && (
          <UserCard
            primaryText={listing.agents[0].name}
            secondaryText={
              listing.agents[0].phone
                ? formatPhoneNumber(listing.agents[0].phone)
                : undefined
            }
          />
        )}
        <FixedButtonWrapper>
          <Button
            cta
            onClick={() =>
              (document.location.href = `tel:${listing?.agents[0].phone}`)
            }
          >
            Call agent
          </Button>
        </FixedButtonWrapper>
      </StackModal>
    </Fragment>
  )
}

OfferStatusPage.fragments = {
  Listing: gql`
    fragment OfferStatusPage_Listing on Listing {
      property {
        address {
          line1
        }
      }
      agents {
        name
        email
        phone
      }
    }
  `,
}

// Functions to support offerCard, can look to abstract it into the component/elsewhere
function findPrimaryBuyer(offer: Offer): OfferBuyer | undefined {
  return offer.buyers.signatories.find(
    (signatory) => signatory.id === offer.buyers.primaryBuyerId
  )
}

function isConditionalOffer(offer: Offer): boolean {
  return !!(
    offer.offer.conditions.conditions.length ||
    (offer.offer.conditions.customCondition &&
      offer.offer.conditions.customCondition.trim().length === 0)
  )
}
