import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import arrow from '../images/select-arrow.svg'
import Icon from './icons'

import { screen } from '../styles/breakpoints'
import { color } from '../styles/colors'
import { size } from '../styles/sizes'

import { ErrorItem, ErrorList } from './Input'

const StyledIcon = styled(Icon)<{
  errors?: string[]
  active?: boolean
}>`
  position: absolute;
  left: 12px;
  top: 10px;

  @media ${screen.xs} {
    left: 0;

    ${(props) =>
      (props.active || props.errors) &&
      css`
        left: 12px;
      `}
  }
`

export const InputWrapper = styled.div<{
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
  errors?: string[]
  active?: boolean
}>`
  position: relative;
  border-left: 1px solid ${color.xxxLightGrey};

  background-color: #fff;
  background-image: url(${arrow});
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top
      ${(props) => (props.errors && props.errors.length ? '25%' : '50%')},
    0 0;
  background-size: 0.65em auto, 100%;

  @media ${screen.xs} {
    border-left: none;
  }

  select {
    box-sizing: border-box;
    width: 100%;
    text-overflow: ellipsis;
    border-radius: 0px;
    padding: 10px ${size(4)} 10px 12px;

    background: ${(props) =>
      props.errors && props.errors.length && !props.active
        ? color.red10
        : 'none'};

    &:disabled,
    &[disabled='disabled'] {
      color: ${color.grey};
    }

    &:focus,
    &:active,
    &:hover {
      background: ${color.blue10};
    }

    @media ${screen.xs} {
      border-radius: 4px 4px 0 0;
      padding: ${(props) =>
        props.errors ? `10px ${size(4)} 10px 12px` : `10px ${size(4)} 10px 0`};

      &:focus,
      &:active,
      &:hover {
        padding: 10px ${size(4)} 10px 12px;
        background: ${color.blue10};
      }
    }

    ${(props) =>
      props.icon &&
      css`
        text-indent: ${size(4)};
      `}
  }

  .underline {
    display: block;
    position: absolute;
    left: -1px;
    top: 43px;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    border: none;
    border-bottom: 2px solid ${color.blue};
    animation: drawIn 0.3s ease-in-out forwards;

    @keyframes drawIn {
      from {
        width: 0;
      }
      to {
        width: calc(100% + 1px);
      }
    }

    @media ${screen.xs} {
      left: 0;

      @keyframes drawIn {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }
    }
  }

  svg#field-indicator {
    position: absolute;
    right: -${size(4)};
    top: 5px;

    ${(props) =>
      props.active &&
      css`
        animation: wingardium 4s ease-in-out infinite;
      `}

    ${(props) =>
      props.errors &&
      !props.active &&
      css`
        transform: translateX(${size(1)});
      `}


    @keyframes wingardium {
      0% {
        transform: rotate(0deg) translateX(${size(1)}) rotate(0deg);
      }
      50% {
        transform: rotate(360deg) translateX(${size(1)}) rotate(-360deg);
      }
      100% {
        transform: rotate(360deg) translateX(${size(1)}) rotate(-360deg);
      }
    }
  }
`

type SelectProps = {
  errors?: string[]
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
} & React.DetailedHTMLProps<JSX.IntrinsicElements['select'], HTMLSelectElement>

const Select = ({
  errors,
  onFocus,
  onBlur,
  onChange,
  icon,
  ...props
}: SelectProps) => {
  const [active, setActive] = useState(false)

  return (
    <InputWrapper active={active} errors={errors} icon={icon}>
      {icon && <StyledIcon svg={icon} errors={errors} active={active} />}
      <select
        onFocus={(e) => {
          setActive(true)
          onFocus && onFocus(e)
        }}
        onBlur={(e) => {
          setActive(false)
          onBlur && onBlur(e)
        }}
        onChange={(e) => {
          onChange && onChange(e)
        }}
        {...props}
      >
        {props.children}
      </select>
      {active && <hr className="underline" />}
      {errors && errors.length && (
        <ErrorList>
          {errors.map((error, i) => (
            <ErrorItem key={i}>{error}</ErrorItem>
          ))}
        </ErrorList>
      )}
      {active && (
        <svg
          id="field-indicator"
          width={`${size(6)}`}
          height={`${size(4)}`}
          viewBox="0 0 48 32"
        >
          <path
            d="M18.3137085,0 L44,0 C46.209139,-4.05812251e-16 48,1.790861 48,4 L48,28 C48,30.209139 46.209139,32 44,32 L18.3137085,32 C16.1919766,32 14.1571453,31.1571453 12.6568542,29.6568542 L1.82842712,18.8284271 C0.266329958,17.26633 0.266329958,14.73367 1.82842712,13.1715729 L12.6568542,2.34314575 C14.1571453,0.842854723 16.1919766,3.8975583e-16 18.3137085,0 Z"
            fill="#4367AB"
          ></path>
        </svg>
      )}
    </InputWrapper>
  )
}

export default Select
