import React from 'react'
import styled from '@emotion/styled'

import { color } from '../styles/colors'
import { screen } from '../styles/breakpoints'
import { size } from '../styles/sizes'
import { fontSize } from '../styles/typography'

export interface ActionGroupOwnProps {
  centered?: boolean
}

export interface ActionGroupProps
  extends ActionGroupOwnProps,
    React.HTMLAttributes<HTMLDivElement> {}

export const ActionGroupWrapper = styled.div<ActionGroupOwnProps>`
  display: flex;
  margin: ${size(2)} 0 ${size(3)};
  justify-content: left;
  align-items: center;

  @media ${screen.xs} {
    flex-direction: ${(props) => (props.centered ? 'column' : 'row')};
  }

  .cta + a {
    color: ${color.black};
    border-color: ${color.black20};
    margin: 11px ${size(4)} 9px;
    line-height: ${fontSize.xl};

    &:hover,
    &:active,
    &:focus {
      border-color: ${color.black};
    }

    @media ${screen.s.up} {
      & + a {
        margin-left: 0;
      }
    }

    @media ${screen.xs} {
      margin: ${size(4)} 0;

      & + a {
        margin-top: 0;
      }
    }
  }
`

const ActionGroup = (props: ActionGroupProps) => {
  return <ActionGroupWrapper {...props} />
}

export default ActionGroup
