import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { screen } from '../styles/breakpoints'
import { color } from '../styles/colors'
import { fontSize } from '../styles/typography'
import { size } from '../styles/sizes'

const LabelWrapper = styled.label<{
  size?: 'small'
  color?: 'black' | 'grey'
  icon?: boolean
  cursor?: string
}>`
  font-size: ${fontSize.m};
  line-height: ${size(3)};
  margin-right: 4px;

  @media ${screen.xs} {
    font-size: ${fontSize.s};
  }

  ${(props) =>
    props.color === 'black' &&
    css`
      color: ${color.black};
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${fontSize.s};
    `}

  ${(props) =>
    props.icon &&
    css`
      margin-left: ${size(1)};
    `}

  ${(props) =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
`

export type LabelProps = {
  size?: 'small'
  color?: 'black' | 'grey'
  icon?: boolean
  cursor?: string
} & Omit<React.HTMLProps<HTMLLabelElement>, 'size' | 'color' | 'as'>

const Label = ({ size, color, icon, children, ...props }: LabelProps) => {
  return (
    <LabelWrapper size={size} color={color} icon={icon} {...props}>
      {children}
    </LabelWrapper>
  )
}

export default Label
