import { dissoc } from 'ramda'

/**
 * Returns a deep copy of an object with the property at the provided
 * path deleted. If deleting the property results in an empty object,
 * then that empty object will be deleted, cascading up to the root.
 */
export function prune<T extends {}>(path: string[], obj: any): T {
  if (path.length === 1) {
    return dissoc(path[0], obj) as T
  }

  let result = prune(path.slice(1), obj[path[0]])

  if (Object.keys(result).length) {
    return {
      ...obj,
      [path[0]]: result,
    }
  }

  return dissoc(path[0], obj) as T
}
