export const color = {
  // Accents
  red: `hsla(344, 61%, 44%, 1)`,
  red50: `hsla(344, 61%, 44%, 0.5)`,
  red25: `hsla(344, 61%, 44%, 0.25)`,
  red10: `hsla(344, 61%, 44%, 0.1)`,

  green: `hsla(160, 69%, 43%, 1)`,
  green50: `hsla(160, 69%, 43%, 0.5)`,
  green25: `hsla(160, 69%, 43%, 0.25)`,
  green10: `hsla(160, 69%, 43%, 0.1)`,

  orange: `hsla(19, 92%, 67%, 1)`,
  orange50: `hsla(19, 92%, 67%, 0.5)`,
  orange25: `hsla(19, 92%, 67%, 0.25)`,
  orange10: `hsla(19, 92%, 67%, 0.1)`,

  yellow: `hsla(27, 100%, 69%, 1)`,
  yellow50: `hsla(27, 100%, 69%, 0.5)`,
  yellow25: `hsla(27, 100%, 69%, 0.25)`,
  yellow10: `hsla(27, 100%, 69%, 0.1)`,

  blue: `hsla(219, 44%, 47%, 1)`,
  blue50: `hsla(219, 44%, 47%, 0.5)`,
  blue25: `hsla(219, 44%, 47%, 0.25)`,
  blue10: `hsla(219, 44%, 47%, 0.1)`,

  // White
  offWhite: `hsla(0, 0%, 97%, 1)`,
  white: `hsla(0, 0%, 100%, 1)`,

  // Grey
  xxxxLightGrey: `hsla(0, 0%, 95%, 1)`, // f2f2f2
  xxxLightGrey: `hsla(0, 0%, 90%, 1)`, // e6e6e6
  xxLightGrey: `hsla(0, 0%, 80%, 1)`, // cccccc
  xLightGrey: `hsla(0, 0%, 70%, 1)`, // b2b2b2
  lightGrey: `hsla(0, 0%, 60%, 1)`, // 999999
  grey: `hsla(0, 0%, 50%, 1)`, // 808080
  darkGrey: `hsla(0, 0%, 40%, 1)`, // 666666
  xDarkGrey: `hsla(0, 0%, 30%, 1)`, // 4d4d4d
  xxDarkGrey: `hsla(0, 0%, 20%, 1)`, // 333333
  xxxDarkGrey: `hsla(0, 0%, 10%, 1)`, // 1a1a1a

  // Black
  black: `hsla(0, 0%, 0%, 1)`,
  black50: `hsla(0, 0%, 0%, 0.5)`,
  black20: `hsla(0, 0%, 0%, 0.2)`,
  black10: `hsla(0, 0%, 0%, 0.1)`,
  black5: `hsla(0, 0%, 0%, 0.05)`,
}

export default color
