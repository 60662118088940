import React from 'react'
import styled from '@emotion/styled'

import { color } from '../styles/colors.js'
import { size } from '../styles/sizes'
import { fontSize } from '../styles/typography'

const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${color.xxxLightGrey};
  margin-bottom: ${size(6)};
`

const TabButton = styled.button<{
  active?: boolean
}>`
  color: ${({ active }) => (active ? color.black : color.xxLightGrey)};
  -webkit-appearance: none;
  background: none;
  border: none;
  border-bottom: ${({ active }) =>
    active ? `3px solid ${color.black}` : '3px solid transparent'};
  outline: none;
  cursor: pointer;
  transition: 0.2s all ease-out;
  font-size: ${fontSize.l};
  letter-spacing: -0.03rem;
  padding: 0 0 12px;
  font-weight: bold;
  margin: 0 ${size(2)} -1px 0;

  :hover {
    color: ${color.black};
  }
`

type TabsProps = {
  tabs: {
    label: string
    active: boolean
    onClick: (arg?: unknown) => void
  }[]
}

const Tabs = ({ tabs }: TabsProps) => {
  return (
    <TabsWrapper>
      {tabs.map((tab, i) => {
        return (
          <TabButton key={i} active={tab.active} onClick={() => tab.onClick()}>
            {tab.label}
          </TabButton>
        )
      })}
    </TabsWrapper>
  )
}

export default Tabs
