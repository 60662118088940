import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { color } from '../styles/colors'
import { size } from '../styles/sizes'
import { fontSize } from '../styles/typography'

const SectionHeaderWrapper = styled.div<{
  h1?: boolean
  hr?: boolean
  hr2?: boolean
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  margin: ${size(2)} 0 ${size(3)};

  ${({ hr }) =>
    hr &&
    css`
      padding-bottom: 12px;
      border-bottom: 2px solid ${color.black};
      margin: ${size(2)} 0 0;
    `}

  ${({ hr2 }) =>
    hr2 &&
    css`
      padding-bottom: 12px;
      border-bottom: 2px solid ${color.black};
      margin: ${size(2)} 0 ${size(3)};
    `}

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  h3 {
    font-size: ${fontSize.l} !important;
    line-height: ${size(3)} !important;
  }

  > div {
    margin-bottom: ${({ h1 }) => h1 && '4px'};
  }
`

export type SectionHeaderProps = {
  h1?: React.ReactNode
  h2?: React.ReactNode
  h3?: React.ReactNode
  h4?: React.ReactNode
  h5?: React.ReactNode
  hr?: boolean
  hr2?: boolean
} & React.HTMLProps<HTMLDivElement>

const SectionHeader = ({
  h1,
  h2,
  h3,
  h4,
  h5,
  hr,
  hr2,
  ...props
}: SectionHeaderProps) => {
  return (
    <SectionHeaderWrapper hr={hr} hr2={hr2}>
      {h1 && <h1>{h1}</h1>}
      {h2 && <h2>{h2}</h2>}
      {h3 && <h3>{h3}</h3>}
      {h4 && <h4>{h4}</h4>}
      {h5 && <h5>{h5}</h5>}
      <div>{props.children}</div>
    </SectionHeaderWrapper>
  )
}

export default SectionHeader
