import React, { useState, forwardRef } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { screen } from '../styles/breakpoints'
import { color } from '../styles/colors'
import { fontSize } from '../styles/typography'
import { size } from '../styles/sizes'

export const InputWrapper = styled.div<{
  errors?: string[]
  active?: boolean
  type?: string
  large?: boolean
  xlarge?: boolean
  centered?: boolean
}>`
  position: relative;
  flex-shrink: 0;
  border-left: 1px solid ${color.xxxLightGrey};

  @media ${screen.xs} {
    border-left: none;
  }

  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='url'],
  input[type='tel'],
  input[type='password'],
  textarea {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: 10px 12px;
    border-radius: 0px;
    background: ${(props) =>
      props.errors && props.errors.length && !props.active
        ? color.red10
        : 'none'};

    @media ${screen.xs} {
      border-left: none;
      padding: ${(props) => (props.errors ? '10px 12px' : '10px 0 10px')};
      border-radius: 4px 4px 0 0;
      transition: padding-left 100ms ease, padding-right 100ms ease;
    }

    &:hover {
      background: ${color.offWhite};
    }

    &:focus,
    &:active {
      outline: none;
      background: ${color.blue10};

      @media ${screen.xs} {
        padding: 10px 12px;
      }
    }

    &::placeholder {
      line-height: revert;
    }

    ${(props) =>
      props.active &&
      css`
        background: ${color.blue10};

        &::placeholder {
          opacity: 0;
        }
      `}

    ${(props) =>
      props.centered &&
      css`
        @media ${screen.xs} {
          text-align: center;
        }
      `}
  }

  textarea {
    display: flex;
  }

  .underline {
    display: block;
    position: absolute;
    left: -1px;
    bottom: -1px;
    width: 0;
    height: 0;
    margin: 0;
    border: none;
    border-bottom: 2px solid ${color.blue};
    animation: drawIn 0.3s ease-in-out forwards;

    @keyframes drawIn {
      from {
        width: 0;
      }
      to {
        width: calc(100% + 1px);
      }
    }

    @media ${screen.xs} {
      left: 0;

      @keyframes drawIn {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }
    }
  }

  .svg-wrapper {
    position: absolute;
    right: -${size(4)};
    top: 50%;
    transform: translateY(-50%);
  }

  svg {
    animation: wingardium 4s ease-in-out infinite;

    ${(props) =>
      props.active &&
      css`
        animation: wingardium 4s ease-in-out infinite;
      `}

    @keyframes wingardium {
      0% {
        transform: rotate(0deg) translateX(${size(1)}) rotate(0deg);
      }
      50% {
        transform: rotate(360deg) translateX(${size(1)}) rotate(-360deg);
      }
      100% {
        transform: rotate(360deg) translateX(${size(1)}) rotate(-360deg);
      }
    }
  }

  ${(props) =>
    props.type === 'textarea' &&
    css`
      margin-bottom: -${size(1)};
    `}

  ${(props) =>
    props.large &&
    css`
      input[type='text'],
      input[type='number'],
      input[type='email'],
      input[type='url'],
      input[type='tel'],
      input[type='password'] {
        font-size: ${fontSize.l};
        line-height: ${fontSize.xl};
        min-height: ${size(7)};
        font-weight: 300;
        padding-top: ${size(2)};
        padding-bottom: ${size(2)};

        @media ${screen.xs} {
          padding: ${props.errors ? `${size(2)} 12px` : '14px 0 18px'};
        }

        &:focus,
        &:active,
        &:hover {
          @media ${screen.xs} {
            padding: ${size(2)} 12px;
          }
        }

        &::placeholder {
          line-height: normal;
        }
      }
    `}

  ${(props) =>
    props.xlarge &&
    css`
      input[type='text'],
      input[type='number'],
      input[type='email'],
      input[type='url'],
      input[type='tel'],
      input[type='password'] {
        font-size: ${fontSize.xxl};
        line-height: ${fontSize.xxl};
        min-height: ${size(10)};
        font-weight: 300;
        padding-top: 21px;
        padding-bottom: 21px;

        @media ${screen.xs} {
          padding: ${props.errors ? '21px 12px' : '21px 0'};
        }

        &:focus,
        &:active,
        &:hover {
          @media ${screen.xs} {
            padding: 21px 12px;
          }
        }

        &::placeholder {
          line-height: normal;
        }
      }
    `}
`

const InputInnerWrapper = styled.div`
  position: relative;
`

export const ErrorList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const ErrorItem = styled.li`
  position: relative;
  margin: 0 0 0 10px;
  padding: 0 10px ${size(1)} 12px;
  font-size: ${fontSize.s};
  line-height: ${fontSize.m};
  color: ${color.red};

  &:first-child {
    margin-top: 12px;
  }

  &:last-child {
    margin-bottom: 4px;
  }

  &:before {
    display: block;
    position: absolute;
    left: 0;
    top: 1px;
    content: '•';
  }
`

type InputProps = {
  errors?: string[]
  large?: boolean
  xlarge?: boolean
  centered?: boolean
} & React.DetailedHTMLProps<JSX.IntrinsicElements['input'], HTMLInputElement>

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      errors,
      type,
      large,
      xlarge,
      centered,
      onFocus,
      onBlur,
      onChange,
      readOnly,
      ...props
    },
    ref
  ) => {
    const [active, setActive] = useState(false)

    const handleFocus = (
      e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (readOnly) e.preventDefault()
      setActive(true)
      if (onFocus) onFocus(e as React.FocusEvent<HTMLInputElement>)
    }

    const handleBlur = (
      e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (readOnly) e.preventDefault()
      setActive(false)
      if (onBlur) onBlur(e as React.FocusEvent<HTMLInputElement>)
    }

    return (
      <InputWrapper
        active={active}
        large={large}
        xlarge={xlarge}
        centered={centered}
        errors={errors}
        type={type}
      >
        <InputInnerWrapper>
          {type === 'textarea' ? (
            <textarea
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(e) => {
                onChange &&
                  onChange(
                    (e as unknown) as React.ChangeEvent<HTMLInputElement>
                  )
              }}
              {...(props as React.DetailedHTMLProps<
                JSX.IntrinsicElements['textarea'],
                HTMLTextAreaElement
              >)}
              ref={ref as React.Ref<HTMLTextAreaElement>}
            />
          ) : (
            <input
              type={type}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(e) => {
                onChange && onChange(e)
              }}
              {...props}
              ref={ref}
            />
          )}
          {active && <hr className="underline" />}
          {active && (
            <span className="svg-wrapper">
              <svg
                width={`${size(6)}`}
                height={`${size(4)}`}
                viewBox="0 0 48 32"
              >
                <path
                  d="M18.3137085,0 L44,0 C46.209139,-4.05812251e-16 48,1.790861 48,4 L48,28 C48,30.209139 46.209139,32 44,32 L18.3137085,32 C16.1919766,32 14.1571453,31.1571453 12.6568542,29.6568542 L1.82842712,18.8284271 C0.266329958,17.26633 0.266329958,14.73367 1.82842712,13.1715729 L12.6568542,2.34314575 C14.1571453,0.842854723 16.1919766,3.8975583e-16 18.3137085,0 Z"
                  fill="#4367AB"
                ></path>
              </svg>
            </span>
          )}
          {(!active && errors && errors.length && (
            <span className="svg-wrapper">
              <svg
                width={`${size(6)}`}
                height={`${size(4)}`}
                viewBox="0 0 48 32"
              >
                <g stroke="none" fill="none" fillRule="evenodd">
                  <g
                    transform="translate(-1182.000000, -1372.000000)"
                    fill="#B42B4F"
                  >
                    <path d="M1226,1372 C1228.20914,1372 1230,1373.79086 1230,1376 L1230,1400 C1230,1402.20914 1228.20914,1404 1226,1404 L1200.31371,1404 C1198.19198,1404 1196.15715,1403.15715 1194.65685,1401.65685 L1183.82843,1390.82843 C1182.26633,1389.26633 1182.26633,1386.73367 1183.82843,1385.17157 L1194.65685,1374.34315 C1196.15715,1372.84285 1198.19198,1372 1200.31371,1372 L1226,1372 Z M1217.8033,1383.13604 L1214,1386.93934 L1210.1967,1383.13604 L1209.13604,1384.1967 L1212.93934,1388 L1209.13604,1391.8033 L1210.1967,1392.86396 L1214,1389.06066 L1217.8033,1392.86396 L1218.86396,1391.8033 L1215.06066,1388 L1218.86396,1384.1967 L1217.8033,1383.13604 Z"></path>
                  </g>
                </g>
              </svg>
            </span>
          )) ||
            null}
        </InputInnerWrapper>

        {errors && errors.length && (
          <ErrorList>
            {errors.map((error, i) => (
              <ErrorItem key={i}>{error}</ErrorItem>
            ))}
          </ErrorList>
        )}
      </InputWrapper>
    )
  }
)

export default Input
