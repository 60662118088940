import { gql, TypedDocumentNode, useQuery } from '@apollo/client'
import {
  AGENT_TERMS_LINE,
  BUYER_TERMS_LINE,
  PRIVACY_POLICY_LINE,
} from '@propps/client'
import {
  Button,
  FixedButtonWrapper,
  Section,
  StackNav,
  StackMain,
  TextPlaceholder,
} from '@propps/ui'
import { format as formatDate, parseISO } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { match as Match, useHistory } from 'react-router-dom'

import {
  CurrentTermsQuery,
  CurrentTermsQueryVariables,
} from './__generated__/CurrentTermsQuery'
import {
  TermsRevisionQuery,
  TermsRevisionQueryVariables,
} from './__generated__/TermsRevisionQuery'
import { TermsRoute_TermsRevision } from './__generated__/TermsRoute_TermsRevision'

export function TermsRoute({
  match,
}: {
  match: Match<{
    lineName: string
    revisionId?: string
  }>
}) {
  const history = useHistory()
  const { lineName, revisionId } = match.params

  const { data: data1, error: error1 } = useQuery(CURRENT_TERMS_QUERY, {
    variables: { lineName },
    skip: !!revisionId,
  })

  const { data: data2, error: error2 } = useQuery(TERMS_REVISION_QUERY, {
    variables: { lineName, revisionId },
    skip: !revisionId,
  })

  const revision = data1?.line?.revision || data2?.line?.revision
  const error = error1 || error2 || null

  // Scroll to hash fragment if present
  const [isLoaded, setLoaded] = useState(false)
  useEffect(() => {
    if (revision && !isLoaded) {
      if (history.location.hash) {
        let el = document.getElementById(history.location.hash.substr(1))

        if (el) {
          el.scrollIntoView()
        }
      }

      setLoaded(true)
    }
  }, [revision, isLoaded, history.location])

  return (
    <>
      <StackNav logo variant="app" />
      {revision ? (
        <>
          <StackMain variant="app">
            <h1>{formatDocumentName(revision.line.name)}</h1>
            <article>
              <p>
                <b>Version {revision.versionName}</b>
                <br />
                Last updated{' '}
                {revision ? formatRevisionDate(revision) : <TextPlaceholder />}
              </p>
              <hr />
              <div
                dangerouslySetInnerHTML={{
                  __html: revision.contentHTML || '',
                }}
              />
            </article>
          </StackMain>
          {window.opener !== null && (
            <FixedButtonWrapper>
              <Button cta label="Close" onClick={() => window.close()} />
            </FixedButtonWrapper>
          )}
        </>
      ) : (
        error && (
          <Section>
            Error fetching the Terms and Conditions. Please try again later.
          </Section>
        )
      )}
    </>
  )
}

TermsRoute.fragments = {
  TermsRevision: gql`
    fragment TermsRoute_TermsRevision on TermsRevision {
      id
      line {
        name
      }
      contentHTML
      versionName
      createdAt
    }
  `,
}

const CURRENT_TERMS_QUERY: TypedDocumentNode<
  CurrentTermsQuery,
  CurrentTermsQueryVariables
> = gql`
  query CurrentTermsQuery($lineName: String!) {
    line: termsLine(name: $lineName) {
      revision: currentRevision {
        ...TermsRoute_TermsRevision
      }
    }
  }
  ${TermsRoute.fragments.TermsRevision}
`

const TERMS_REVISION_QUERY: TypedDocumentNode<
  TermsRevisionQuery,
  TermsRevisionQueryVariables
> = gql`
  query TermsRevisionQuery($lineName: String!, $revisionId: ID!) {
    line: termsLine(name: $lineName) {
      revision(id: $revisionId) {
        ...TermsRoute_TermsRevision
      }
    }
  }
  ${TermsRoute.fragments.TermsRevision}
`

function formatRevisionDate(revision: TermsRoute_TermsRevision) {
  return formatDate(parseISO(revision.createdAt), 'dd MMM yyy')
}

function formatDocumentName(line: string) {
  switch (line) {
    case BUYER_TERMS_LINE:
    case AGENT_TERMS_LINE:
      return 'Terms & Conditions'
    case PRIVACY_POLICY_LINE:
      return 'Privacy Policy'
    default:
      return null
  }
}
