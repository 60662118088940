/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  getErrors,
  useFlowFormStep,
  useSMSVerificationFlow,
} from '@propps/client'
import { Input, ListItem, ListWrapper } from '@propps/ui'
import isMobile from 'is-mobile'
import React, { useCallback } from 'react'
import * as Yup from 'yup'

import { OfferFormValues } from '../offer-form-values'
import { SubmissionError } from '../submission-error'
import { FormikErrors, yupToFormErrors } from 'formik'

const isMobileDevice = isMobile()

export function AuthSMSValidateStep({
  id,
  smsVerification,
  buyerIndex,
}: {
  id: string
  smsVerification: ReturnType<typeof useSMSVerificationFlow>
  buyerIndex: number
}) {
  if (buyerIndex === 0) {
    throw new Error('This step can only be used for  additional buyers')
  }

  const validate = useCallback(
    (values: OfferFormValues) => {
      try {
        Yup.object({
          smsVerificationCode: Yup.string()
            .required('Please enter the verification code.')
            .label('Verification code'),
        }).validateSync(values.buyers.signatories[buyerIndex])
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          return {
            buyers: {
              signatories: createSingleErrorArray(
                values.buyers.signatories.length,
                buyerIndex,
                yupToFormErrors(err)
              ),
            },
          } as FormikErrors<OfferFormValues>
        }

        throw err
      }

      return {}
    },
    [buyerIndex]
  )

  const { formik, setSubmissionError } = useFlowFormStep<OfferFormValues>({
    id,
    validate,
    onBack: (helpers) => {
      smsVerification.reset()
      helpers.setFieldValue(
        'buyers.signatories',
        formik.values.buyers.signatories.filter(
          (value, index) => index !== buyerIndex
        ),
        false
      )
    },
    onSubmit: async (values, helpers) => {
      try {
        await smsVerification.complete(
          values.buyers.signatories[buyerIndex].smsVerificationCode
        )
        helpers.setFieldValue(
          `buyers.signatories[${buyerIndex}].smsVerificationCode`,
          ''
        )
      } catch (err) {
        helpers.preventTransition()

        switch (err.code) {
          case 'auth/invalid-verification-id':
            helpers.setSubmissionError(
              'The verification code is invalid or expired. Please press resend to get a new verification code or contact hey@propps.com for support.'
            )
            break
          case 'auth/invalid-verification-code':
            helpers.setSubmissionError(
              'The verification code appears to be incorrect. Try retyping the code or press resend to get a new one. You can contact hey@propps.com for support.'
            )
            break
          default:
            console.error(err)
            helpers.setSubmissionError(
              'We were unable to complete phone verification. Try again later or contact hey@propps.com for support.' +
                (err.code ? ' Error code: ' + err.code : '')
            )
        }
      }
    },
  })

  return (
    <>
      <h2>
        Let's just confirm your number before we continue
        <span className="light">
          {formik.values.buyers.signatories[buyerIndex]?.phone || '&nbsp;'}
        </span>
      </h2>
      <ListWrapper>
        <ListItem>
          <label>Verification code</label>
          <Input
            autoFocus={!isMobileDevice}
            type="text"
            placeholder="..."
            {...formik.getFieldProps(
              `buyers.signatories[${buyerIndex}].smsVerificationCode`
            )}
            errors={getErrors(
              formik,
              `buyers.signatories[${buyerIndex}].smsVerificationCode`
            )}
            inputMode="numeric"
            {...{ autoComplete: 'one-time-code' }}
          />
        </ListItem>
      </ListWrapper>
      <p className="grey">
        We’ve sent a verification code to make sure we don’t send your
        information to the wrong person. If you haven’t received anything, we
        can&nbsp;
        <a
          href="#"
          onClick={() => {
            setSubmissionError(null)
            smsVerification.resend()
          }}
        >
          resend the code
        </a>
        .
      </p>
      <SubmissionError />
    </>
  )
}

function createSingleErrorArray(
  length: number,
  index: number,
  errors: FormikErrors<any>
) {
  return Array(length)
    .fill(null)
    .map((value, i) => (i === index ? errors : undefined))
}
