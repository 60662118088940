import React, { useRef, useEffect, Fragment, useState } from 'react'
import { useStableCallback } from '@propps/client'
import { Button, size as sizeUtility } from '@propps/ui'
import { fabric } from 'fabric'

type SignatureCanvasProps = {
  onChange?: (data: string) => void
  value?: string // data uri
} & Omit<React.PropsWithoutRef<JSX.IntrinsicElements['canvas']>, 'onChange'>

export function SignatureCanvas({
  onChange,
  style,
  value,
  ...rest
}: SignatureCanvasProps) {
  const canvasElement = useRef<HTMLCanvasElement>(null!)
  const containerElement = useRef<HTMLDivElement>(null!)
  const canvas = useRef<fabric.Canvas>()
  const [activated, setActivated] = useState(false)
  const size = useRef<{ width: number; height: number }>({
    width: -1,
    height: -1,
  })

  const handleChange = useStableCallback(
    (value: string) => {
      onChange && onChange(value)
    },
    [onChange]
  )

  useEffect(() => {
    if (activated) {
      canvasElement.current.width = size.current.width
      canvasElement.current.height = size.current.height
      canvas.current = new fabric.Canvas(canvasElement.current!, {
        isDrawingMode: true,
      })
      canvas.current.freeDrawingBrush.width = 2

      let raf: number
      const handler = function (e: fabric.IEvent) {
        // For some reason calling toDataUrl from within the event handler
        // will erase the new path from the canvas, so we defer it
        cancelAnimationFrame(raf)
        raf = requestAnimationFrame(() => {
          handleChange(canvas.current!.toDataURL({ format: 'png' }))
        })
      }
      canvas.current!.on('path:created', handler)
      return () => {
        cancelAnimationFrame(raf)
        canvas.current!.off('path:created', handler)
        canvas.current!.dispose()
      }
    }
  }, [activated, handleChange])

  const start = () => {
    size.current = {
      width: containerElement.current.clientWidth,
      height: containerElement.current.clientHeight,
    }
    setActivated(true)
  }

  const clear = () => {
    canvas.current?.clear()
    handleChange('')
  }

  return (
    <div
      style={{
        width: '100%',
        height: style?.height || '100%',
        position: 'relative',
      }}
      ref={containerElement}
    >
      <hr
        style={{
          display: 'block',
          position: 'absolute',
          bottom: `${sizeUtility(8)}`,
          width: '100%',
          border: 'none',
          borderBottom: '1px dashed rgba(0,0,0,0.1)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          pointerEvents: 'none',
          width: '100%',
          height: `calc(100% - ${sizeUtility(8)})`,
          zIndex: 0,
        }}
      />
      {!activated && (
        <div
          style={{
            width: '100%',
            height: '100%',
            paddingBottom: `${sizeUtility(8)}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#ccc',
            backgroundImage: value ? `url(${value})` : undefined,
          }}
          onClick={start}
        >
          {!value ? (
            <Button
              xsmall
              label="Tap to sign"
              style={{
                position: 'absolute',
                marginTop: `-${sizeUtility(3)}`,
                zIndex: 1,
              }}
              onClick={clear}
            />
          ) : (
            <Button
              xsmall
              label="Clear"
              style={{
                position: 'absolute',
                marginTop: '12px',
                right: 0,
                top: 0,
                zIndex: 1,
              }}
              onClick={clear}
            />
          )}
        </div>
      )}
      {activated && (
        <Fragment>
          <Button
            xsmall
            label="Clear"
            style={{
              position: 'absolute',
              marginTop: '12px',
              right: 0,
              zIndex: 1,
              display: value ? 'block' : 'none',
            }}
            onClick={clear}
          />
          <canvas {...rest} style={style} ref={canvasElement} />
        </Fragment>
      )}
    </div>
  )
}
