import {
  DIGITAL_SIGNATURES_CONDITION_LINE,
  getError,
  useFlowFormStep,
} from '@propps/client'
import {
  Accordion,
  AccordionList,
  Checkbox,
  Clock,
  CommonError,
  DatePicker,
  ListItem,
  ListWrapper,
  Section,
  SectionHeader,
  Select,
  size,
  TextPlaceholder,
} from '@propps/ui'
import {
  addDays,
  format as formatDate,
  getDayOfYear,
  getHours,
  getSeconds,
  getYear,
  isAfter,
  isValid,
  parse as parseDate,
  setDayOfYear,
  setHours,
  setSeconds,
  setYear,
} from 'date-fns'
import { setMinutes } from 'date-fns/esm'
import gql from 'graphql-tag'
import { equals } from 'ramda'
import React, { Fragment, useState } from 'react'
import * as Yup from 'yup'

import { OfferFormValues } from '../offer-form-values'
import { ConditionsStep_OfferConditionsRevision } from './__generated__/ConditionsStep_OfferConditionsRevision'

const EXPIRY_TIME_VALUES = ['9:00', '12:00', '17:00']
const EXPIRY_TIME_FORMAT = 'H:mm'

type ExpiryType = 'no-expiry' | 'expiry-date'

export function ConditionsStep({
  id,
  conditions,
}: {
  id: string
  conditions: ConditionsStep_OfferConditionsRevision[] | null
}) {
  const { formik } = useFlowFormStep<OfferFormValues>({
    id,
    validationSchema,
  })
  const [customConditionChecked, setCustomConditionChecked] = useState(
    !!(
      formik.values.conditions.customCondition &&
      formik.values.conditions.customCondition.trim().length > 0
    )
  )

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const digitalSignaturesCondition =
    conditions?.find(
      (condition) => condition.line.name === DIGITAL_SIGNATURES_CONDITION_LINE
    ) || null
  conditions =
    conditions?.filter(
      (condition) => condition.line.name !== DIGITAL_SIGNATURES_CONDITION_LINE
    ) || []

  const isConditionActive = (
    condition: ConditionsStep_OfferConditionsRevision
  ) =>
    !!formik.values.conditions.conditions.find((item) =>
      equals(item, {
        region: condition.line.region,
        line: condition.line.name,
        id: condition.id,
      })
    )
  const setConditionActive = (
    condition: ConditionsStep_OfferConditionsRevision,
    active: boolean
  ) => {
    const value = active
      ? [
          ...formik.values.conditions.conditions,
          {
            region: condition.line.region,
            line: condition.line.name,
            id: condition.id,
          },
        ]
      : formik.values.conditions.conditions.filter(
          (item) =>
            !equals(item, {
              region: condition.line.region,
              line: condition.line.name,
              id: condition.id,
            })
        )
    formik.setFieldValue('conditions.conditions', value)
  }

  const setExpiryType = (type: ExpiryType) => {
    switch (type) {
      case 'expiry-date':
        formik.setFieldValue(
          'offer.expiry',
          parseDate(EXPIRY_TIME_VALUES[0], EXPIRY_TIME_FORMAT, new Date())
        )
        break
      case 'no-expiry':
        formik.setFieldValue('offer.expiry', null)
        break
    }
  }

  const setExpiryDate = (date: Date) => {
    // patch the day/month/year into the date
    let value = formik.values.offer.expiry!
    value = setYear(value, getYear(date))
    value = setDayOfYear(value, getDayOfYear(date))

    formik.setFieldValue('offer.expiry', value)
    formik.setFieldTouched('offer.expiry')
  }

  const setExpiryTime = (time: string) => {
    // patch the time into the existing date
    const reference = parseDate(time, EXPIRY_TIME_FORMAT, new Date())
    let value = formik.values.offer.expiry!
    value = setSeconds(value, getSeconds(reference))
    value = setMinutes(value, getSeconds(reference))
    value = setHours(value, getHours(reference))

    formik.setFieldValue('offer.expiry', value)
    formik.setFieldTouched('offer.expiry')
  }

  const expiryTime =
    formik.values.offer.expiry &&
    formatDate(formik.values.offer.expiry, EXPIRY_TIME_FORMAT)

  return (
    <>
      <h2 style={{ marginBottom: size(6) }}>Offer conditions</h2>
      <Section>
        <SectionHeader hr h3="Optional conditions" />
        <AccordionList>
          {conditions?.map((condition) => (
            <Accordion
              title={
                <Checkbox
                  trailingAction
                  label={condition.title}
                  onClick={stopPropagation}
                  checked={isConditionActive(condition)}
                  onChange={(e) =>
                    setConditionActive(condition, e.target.checked)
                  }
                />
              }
            >
              <div
                dangerouslySetInnerHTML={{ __html: condition.contentHTML }}
              />
            </Accordion>
          ))}
          <Accordion
            title={
              <Checkbox
                trailingAction
                label="Custom conditions"
                checked={customConditionChecked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCustomConditionChecked(e.target.checked)
                  if (!e.target.checked) {
                    formik.setFieldValue('conditions.customCondition', '')
                  }
                }}
                onClick={stopPropagation}
              />
            }
            visible={customConditionChecked}
          >
            <p>
              It's a good idea to have any custom conditions looked over by your
              legal professional.
            </p>
            <textarea
              placeholder="Type here..."
              {...formik.getFieldProps('conditions.customCondition')}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setCustomConditionChecked(true)
                }
                formik.handleChange(e)
              }}
            />
          </Accordion>
        </AccordionList>
      </Section>
      <Section>
        <SectionHeader hr h3="Mandatory digital signatures clause" />
        <AccordionList>
          <Accordion
            title={
              <Checkbox
                trailingAction
                label={digitalSignaturesCondition?.title || <TextPlaceholder />}
                checked={formik.values.agreeToUseOfDigitalSignatures}
                onChange={(e) => formik.handleChange(e)}
                onBlur={(e) => formik.handleBlur(e)}
                onClick={stopPropagation}
                name="agreeToUseOfDigitalSignatures"
              />
            }
          >
            {digitalSignaturesCondition ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: digitalSignaturesCondition.contentHTML,
                }}
              />
            ) : (
              <TextPlaceholder />
            )}
          </Accordion>
        </AccordionList>
        {getError(formik, 'agreeToUseOfDigitalSignatures') && (
          <CommonError>
            {getError(formik, 'agreeToUseOfDigitalSignatures')}
          </CommonError>
        )}
      </Section>
      <Section>
        <SectionHeader hr h3="Optional offer expiry" />
        <ListWrapper>
          <ListItem>
            <label>Valid until</label>
            <Select
              onChange={(e) => setExpiryType(e.target.value as ExpiryType)}
              value={formik.values.offer.expiry ? 'expiry-date' : 'no-expiry'}
            >
              <option value="no-expiry">Until withdrawn</option>
              <option value="expiry-date">Specific date or time</option>
            </Select>
          </ListItem>
          {formik.values.offer.expiry && (
            <Fragment>
              <ListItem>
                <label>Date</label>
                <DatePicker
                  value={formik.values.offer.expiry}
                  onChange={(value) => {
                    setExpiryDate(value)
                  }}
                  format={(date) => formatDate(date, 'dd MMM yyyy')}
                  minDate={new Date()}
                />
              </ListItem>
              <ListItem>
                <label>Time</label>
                <Select
                  icon={Clock}
                  value={expiryTime!}
                  onChange={(e) => setExpiryTime(e.target.value)}
                >
                  {!EXPIRY_TIME_VALUES.includes(expiryTime!) && (
                    <option disabled value={expiryTime!}>
                      {formatDate(
                        parseDate(expiryTime!, EXPIRY_TIME_FORMAT, new Date()),
                        'h:mma'
                      )}
                    </option>
                  )}
                  {EXPIRY_TIME_VALUES.map((value) => (
                    <option key={value} value={value}>
                      {formatDate(
                        parseDate(value, EXPIRY_TIME_FORMAT, new Date()),
                        'h:mma'
                      )}
                    </option>
                  ))}
                </Select>
              </ListItem>
            </Fragment>
          )}
        </ListWrapper>
        {getError(formik, 'offer.expiry') && (
          <CommonError>{getError(formik, 'offer.expiry')}</CommonError>
        )}
        <p className="grey">
          You can withdraw your offer at any time prior to vendor acceptance by
          notifying the agent.
        </p>
      </Section>
    </>
  )
}

ConditionsStep.fragments = {
  OfferConditionsRevision: gql`
    fragment ConditionsStep_OfferConditionsRevision on OfferConditionsRevision {
      line {
        region
        name
      }
      id
      title
      contentHTML
    }
  `,
}

const validationSchema = Yup.object().shape({
  conditions: Yup.object({
    subjectToFinance: Yup.boolean(),
    subjectToBuildingInspection: Yup.boolean(),
    subjectToPestInspection: Yup.boolean(),
    customCondition: Yup.string(),
  }),
  offer: Yup.object({
    expiry: Yup.date()
      .nullable()
      .test(
        'is-future',
        'Please choose a date at least 24 hours in the future.',
        (value?: Date | null): value is Date | null =>
          !value || (isValid(value) && isAfter(value, addDays(new Date(), 1)))
      )
      .label('Expiry date'),
  }),
  agreeToUseOfDigitalSignatures: Yup.boolean().oneOf(
    [true],
    'This condition is needed to submit your offer online.'
  ),
})
