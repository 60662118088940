import React from 'react'

import {
  StyledButton,
  StyledAnchorButton,
  FixedButtonWrapper,
} from './Button.styles'

type ButtonProps = {
  label?: React.ReactNode
} & Omit<React.ComponentProps<typeof StyledButton>, 'label'>

const Button_ = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ label, type = 'button', children, ...rest }: ButtonProps, ref) => {
    return (
      <StyledButton type={type} {...rest} ref={ref}>
        {label || children || <span>&nbsp;</span>}
      </StyledButton>
    )
  }
)

type AnchorButtonProps = { label?: React.ReactNode } & Omit<
  React.ComponentProps<typeof StyledAnchorButton>,
  'label'
>

const AnchorButton = React.forwardRef<HTMLAnchorElement, AnchorButtonProps>(
  ({ label, href, onClick, children, ...rest }, ref) => {
    return (
      <StyledAnchorButton
        ref={ref}
        href={href || '#'}
        onClick={(e) => {
          if (!href) {
            e.preventDefault()
          }

          onClick && onClick(e)
        }}
        {...rest}
      >
        {label || children || <span>&nbsp;</span>}
      </StyledAnchorButton>
    )
  }
)

const Button: typeof Button_ & {
  Anchor: typeof AnchorButton
} = Object.assign(Button_, { Anchor: AnchorButton })

export { FixedButtonWrapper }

export default Button
