import React, { useEffect, useState } from 'react'
import { useDisclosureState, Disclosure, DisclosureContent } from 'reakit'

import Icon, { ChevronDown } from '../elements/icons'
import styled from '@emotion/styled'
import { color } from '../styles/colors'
import { screen } from '../styles/breakpoints'
import { fontSize } from '../styles/typography'
import { size } from '../styles/sizes'

export const AccordionList = styled.div`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  margin-top: ${size(2)};
  margin-bottom: ${size(3)};

  & > div + div {
    margin-top: ${size(1)};
  }

  > p:first-of-type {
    margin-top: ${size(3)};
  }

  > p:last-of-type {
    margin-bottom: 0;
  }

  @media ${screen.l.up} {
    margin: ${size(4)} 0 ${size(8)};
  }
`

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: ${size(3)};
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;

  &:focus-within {
    // text-decoration: underline;
  }

  :hover {
    // text-decoration: underline;
  }
`
const Content = styled.section`
  margin: ${size(2)} 47px ${size(4)};
  will-change: height;

  @media ${screen.xs} {
    margin: ${size(2)} 10px ${size(4)};
  }

  p {
    font-size: ${fontSize.s};
    color: ${color.grey};
    margin: ${size(1)} 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & + p,
    & + ol,
    & + ul {
      margin-top: 0;
    }
  }

  ul,
  ol {
    font-size: ${fontSize.s};
    color: ${color.grey};
    margin: ${size(1)} 0;
    padding-inline-start: ${size(3)};

    li {
      margin-bottom: ${size(1)};
      padding-left: ${size(1)};
    }

    & + p,
    & + ol,
    & + ul {
      margin-top: 0;
    }

    ol {
      list-style-type: lower-alpha;
      padding-inline-start: ${size(4)};
    }
  }

  ul {
    list-style-type: disc;
  }

  textarea {
    font-size: ${fontSize.s};
    margin-bottom: -${size(1)};
    border-bottom: 1px solid ${color.xxxLightGrey};
    min-height: 188px;
    padding: 0;
    border-radius: ${size(1)} ${size(1)} 0 0;

    @media ${screen.xs} {
      margin: 0 -10px -${size(4)};
      width: calc(100% + 20px);
    }

    @media ${screen.s.up} {
      margin: ${size(2)} 0 0;
      width: 100%;
    }

    &:hover,
    &:active,
    &:focus {
      padding: ${size(1)} 12px;
    }

    &:focus,
    &:active {
      border-bottom: 2px solid ${color.blue};
    }
  }
`

const DisclosureButton = styled(Disclosure)`
  display: flex;
  min-height: 100%;
  padding: 10px;
  background: none;
  border: 1px solid ${color.xxxLightGrey};
  border-left: none;
  border-radius: 0 ${size(1)} ${size(1)} 0;
  align-items: center;
  transition: background 0.2s ease-out;

  &:hover {
    background: ${color.offWhite};
  }

  &:focus,
  &:active {
    border: 1px solid ${color.black};
    box-shadow: inset 0 0 0 1px ${color.black};
    margin-left: -1px;
    padding-left: 11px;
    outline: none;
  }

  svg {
    flex-shrink: 0;
    transform: ${(props) => (props.visible ? 'scaleY(-1)' : 'scaleY(1)')};
    width: ${size(3)};
    height: ${size(3)};
  }
`

type AccordionProps = {
  title?: React.ReactNode
  visible?: boolean
} & Omit<React.HTMLProps<HTMLDivElement>, 'title'>

export default function Accordion({
  children,
  title,
  visible,
}: AccordionProps) {
  const state = useDisclosureState({ visible })

  const [isMounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted && typeof visible !== undefined) {
      visible ? state.show() : state.hide()
    }
    // isMounted has been excluded deliberately, we only want to rerun
    // when `visible` changes
  }, [visible])

  return (
    <AccordionContainer>
      <Header>
        {title}
        <DisclosureButton {...state} onClick={(e) => e.stopPropagation()}>
          <Icon svg={ChevronDown} />
        </DisclosureButton>
      </Header>
      <DisclosureContent {...state}>
        {(props) => <Content {...props}>{children}</Content>}
      </DisclosureContent>
    </AccordionContainer>
  )
}
