import {
  FormattedNumberInput,
  formatDollarAmount,
  formatDollarAmountForInput,
  getErrors,
  useFlowFormStep,
} from '@propps/client'
import { Input, ListItem, ListWrapper, TextPlaceholder } from '@propps/ui'
import isMobile from 'is-mobile'
import React from 'react'
import * as Yup from 'yup'
import gql from 'graphql-tag'
import { OfferAmountStep_PriceIndication } from './__generated__/OfferAmountStep_PriceIndication'

const isMobileDevice = isMobile()

export function OfferAmountStep({
  id,
  priceIndication,
}: {
  id: string
  priceIndication: OfferAmountStep_PriceIndication | null
}) {
  const { formik } = useFlowFormStep({
    id,
    validationSchema,
  })

  return (
    <>
      <h2>How much would you like to offer for this property?</h2>
      <ListWrapper>
        <ListItem>
          <label>Offer amount</label>
          <FormattedNumberInput<React.ComponentProps<typeof Input>>
            autoFocus={!isMobileDevice}
            component={Input}
            format={formatDollarAmountForInput}
            xlarge
            centered
            type="text"
            placeholder="$"
            {...formik.getFieldProps('offer.amount')}
            onChange={(value) => formik.setFieldValue('offer.amount', value)}
            errors={getErrors(formik, 'offer.amount')}
            inputMode="numeric"
          />
        </ListItem>
      </ListWrapper>
      <p className="grey">
        {priceIndication ? (
          formatPriceIndication(priceIndication as PriceIndication)
        ) : (
          <TextPlaceholder />
        )}
      </p>
    </>
  )
}

OfferAmountStep.fragments = {
  PriceIndication: gql`
    fragment OfferAmountStep_PriceIndication on ListingPriceIndication {
      type
      min
      max
      message
    }
  `,
}

type PriceIndication =
  | { type: 'min'; min: number; message: string | null }
  | { type: 'range'; min: number; max: number; message: string | null }
  | { type: 'custom'; message: string }

const validationSchema = Yup.object({
  offer: Yup.object({
    amount: Yup.number()
      // eslint-disable-next-line no-template-curly-in-string
      .typeError('${label} must be a number.')
      .integer()
      .positive("Nothing's free, pal!")
      .label('Amount'),
  }),
})

function formatPriceIndication(priceIndication: PriceIndication) {
  if (priceIndication.message) {
    return priceIndication.message
  }

  switch (priceIndication.type) {
    case 'min':
      return 'Offers above ' + formatDollarAmount(priceIndication.min) + '.'
    case 'range':
      return (
        'Offers in the range ' +
        formatDollarAmount(priceIndication.min) +
        ' - ' +
        formatDollarAmount(priceIndication.max) +
        '.'
      )
  }
}
