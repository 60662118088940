import styled from '@emotion/styled'
import React from 'react'
import { Checkbox as BaseCheckbox } from 'reakit'

import { color } from '../styles/colors'
import { fontSize } from '../styles/typography'
import { size as sizeUtility } from '../styles/sizes'

import CheckboxEmpty from './icons/svg/checkbox/empty.svg'
import CheckboxSelected from './icons/svg/checkbox/tick.svg'

const Label = styled.label<{ size?: 'lg'; trailingAction?: boolean }>`
  padding: ${({ size }) => (size === 'lg' ? `${sizeUtility(2)}` : '10px')};
  display: flex;
  width: 100%;
  color: ${color.black};
  cursor: pointer;
  font-size: ${fontSize.m};
  line-height: ${sizeUtility(3)};
  user-select: none;
  border: 1px solid ${color.xxxLightGrey};
  border-radius: ${({ trailingAction }) =>
    trailingAction
      ? `${sizeUtility(1)} 0 0 ${sizeUtility(1)}`
      : `${sizeUtility(1)}`};
  transition: background 0.2s ease-out;

  &:hover {
    background: ${color.offWhite};
  }

  &:focus,
  &:active {
    border: 1px solid ${color.black};
    box-shadow: inset 0 0 0 1px ${color.black};
  }

  p {
    margin: ${sizeUtility(1)} 0 ${sizeUtility(2)};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const CheckboxControl = styled(BaseCheckbox)`
  appearance: none;
  outline: none;
  cursor: pointer;
  width: ${sizeUtility(3)};
  height: ${sizeUtility(3)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  border: none;

  &::after {
    content: '';
    background-image: url(${CheckboxEmpty});
    background-size: 100%;
    color: white;
    font-size: 70%;
    width: ${sizeUtility(3)};
    height: ${sizeUtility(3)};
    display: block;
    border-radius: 4px;
  }

  &:focus::after {
    box-shadow: 0 0 0 1px ${color.white}, 0 0 0 3px ${color.black};
  }

  &:checked::after {
    background-image: url(${CheckboxSelected});
  }
`

export const CheckboxGroup = styled.div`
  display: block;
  margin: ${sizeUtility(2)} 0 ${sizeUtility(4)};

  label + label {
    margin-top: ${sizeUtility(1)};
  }
`

type CheckboxProps = {
  size?: 'lg'
  label?: React.ReactNode
  trailingAction?: boolean
  value?: string | number | undefined
} & Omit<
  React.PropsWithoutRef<JSX.IntrinsicElements['input']>,
  'label' | 'as' | 'size' | 'type'
>

const Checkbox = ({
  id,
  label,
  size,
  trailingAction,
  checked,
  ...props
}: CheckboxProps) => {
  return (
    <Label htmlFor={id} size={size} trailingAction={trailingAction}>
      <CheckboxControl checked={checked} id={id} {...props} />
      <span>{label}</span>
    </Label>
  )
}

export default Checkbox
