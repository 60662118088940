/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import logo from '../images/logo__black--horizontal.svg'

const Logo = ({ height, width }: { height?: string; width?: string }) => {
  return (
    <img
      src={logo}
      alt="Propps logo"
      css={css`
        max-width: ${width};
        height: ${height};
      `}
    />
  )
}

export default Logo
